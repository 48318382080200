.badge-wrapper {
    border-style: solid;
    border-width: 2px;
    border-radius: 355px;
    padding: 2px;

    .badge-container {
        border-radius: 355px;
        padding-inline: 10px;
        padding-block: 5px;

        .badge-icon {
            svg {
                width: 1.3rem;
                height: 1.3rem;
            }
        }

        .badge-text {
            font-size: 0.7rem;
            @extend .bold-font;
        }
    }
}