.view-event-details-panel{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-inline-start: 1.5em;
    color: #C4FFF9;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.9em;
    letter-spacing: 0.18px;
    .event-details-bullet{
        width: 0.7em;
        height: 0.7em;
        border-radius: 0.35em;
        margin-inline-end: 0.5em;
    }
}


.details-panel-content-container{
    margin-inline: 5%;
    padding-top: 45%;
    position: relative;
    height:100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 13%;
    .info-container{
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: 75%;

        .users-grid-container{
            display: grid;
            grid-template-columns: repeat(3,35%);
            grid-row-gap: 1em;
            padding-block: 2%;
            a{
                color: #73E4CC !important;
                
            }
            a:hover{
                text-decoration: underline !important;
            }
            .user-container{
                display: flex;
                flex-direction: row;
                align-items: center;
                .user-avatar{
                    width:1.5em;
                    height: 1.5em;
                    border-radius: 0.75em;
                    margin-inline-end: 1%;
                }
                .username{
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    max-width:73%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-inline-start: 0.3em;
                }
                
            }
           
        }
    }
    .delete-button{
        color:#F48585;
        width: 40%;
        height:8%;
        align-self: center;
        margin-top: auto;
        background: #065A53 0% 0% no-repeat padding-box;
        box-shadow: $dark-button-box-shadow;
        text-transform: uppercase;
        border-radius: 5px;
        outline: none;
        border: none
    }
    .key{
        font-size: 0.9em;
        font-weight: 500;
        letter-spacing: 0.18px;
        margin-bottom: 0; 
        color: #C4FFF9; 
        text-transform: uppercase;
    }
    .value{
        text-transform: capitalize;
    }
    .details-title{
        color: #C4FFF9;
        text-transform: uppercase;
        font-size: 0.9em;
        font-weight: 500;
        letter-spacing: 0.18px;
    }
    .details-grid-container{
        display: grid;
        grid-template-columns: repeat(2,50%);
        padding-top: 5%;
      
    }

    .calendar-map-view{
        position: absolute;
        top: 3%;
        bottom: 0;
        left: 0;
        right: 0;
        height: 25%;
        border-radius: 5px;
        // overflow: hidden;
    }
    .table-Container .table-title {
        background-color: #065A53;
    }
}
[dir='rtl'] {
    .view-event-details-panel {
        font-size: calc(12px + (18 - 12) * ((100vw - 320px) / (2800 - 320)));
        font-weight: 600;
    } 
    .details-panel-content-container {
        .info-container{
            font-size: calc(12px + (18 - 12) * ((100vw - 320px) / (2800 - 320)));
            .key{
                font-weight: 400;
            }
        }
    }
}