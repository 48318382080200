@import'./elements/index';
@import'./abstracts/index';
@import './components/index';
@import './layout/index';
@import './pages/index';
@import './cards/index';

.Toastify__toast-theme--colored.Toastify__toast {
  font-family: nunito_semibold;
  font-size: 13px !important;

  .Toastify__toast-icon {
    margin-inline-end: 10px;
    width: 20px !important;
    flex-shrink: 0;
    display: flex;
  }

  &--error {
    color: #ffc6c6 !important;
    background: #a83736ed !important;

    // .Toastify__toast-icon {
    //     margin-top: -12%;
    // }
    .Toastify__close-button {
      color: #e58c8c !important;
      background-color: #823333 !important;
      width: 14.5% !important;
      height: 14.5% !important;
      border-radius: 50% !important;
      opacity: 1 !important;
    }
  }

  &--warning {
    color: #9d651b !important;
    background: #f0bd6feb !important;

    // .Toastify__toast-icon {
    //     margin-top: -8%;
    // }
    .Toastify__close-button {
      color: #fdd291 !important;
      background-color: #462a0440 !important;
      width: 17% !important;
      height: 17% !important;
      border-radius: 50% !important;
      opacity: 1 !important;
    }
  }

  &--success {
    color: #165f59 !important;
    background: #73e4ccdb !important;

    // .Toastify__toast-icon {
    //     margin-top: -3%;
    // }
    .Toastify__close-button {
      color: #c8f5f1 !important;
      background-color: #03403a4a !important;
      width: 10% !important;
      height: 10% !important;
      border-radius: 50% !important;
      opacity: 1 !important;
    }

    .Toastify__progress-bar {
      background-color: #93fff4 !important;
    }
  }

  .Toastify__close-button {
    svg {
      height: 14px !important;
      width: 12px !important;
      //margin: 0 25% !important;
      margin-top: -3px;
      margin-left: 1.5px;
    }
  }

  .Toastify__toast-body>div:last-child {
    margin-block-start: 0.5em;
  }
}

#card1 {
  height: 400px
}

#cardx {
  display: flex;
  height: 200px;
  border: 5;
}

#Body {
  position: relative;
  flex: 1;
}

#CTA button,
.cta-mob button {
  display: flex;
  align-items: center;
  width: 230px;
  height: 43px;
  border: none;
  border-radius: 30px;
  background-color: $primary;
  outline: none;
}

#CTA label,
.cta-mob label {
  // font-family: nunito_extraBold;
  // font-size: 20px;
  @extend .button;
  letter-spacing: 0.24px;
  color: $sidebar;
  // text-transform: uppercase;
  width: 83%;
}

#CTA button::after,
.cta-mob button::after {
  content: " ";
  width: 28px;
  height: 28px;
  border-radius: 30px;
  background: #A0FFEB url("../assets/icons/svg/constants/CTA_Arrow.svg") no-repeat center;
}

.CTA-AR button::after {
  background: #A0FFEB url("../assets/icons/svg/constants/CTA_Arrow-ar.svg") no-repeat center !important;
}

.CTA :hover,
.cta-mob :hover {
  background-color: #23b596;
  cursor: pointer;
}

#CTA :hover label,
.cta-mob :hover label {
  color: $primary-txt;
}

#CTA button:disabled,
.cta-mob button:disabled {
  background-color: #257569;
  pointer-events: none;
}

#CTA button:disabled label,
.cta-mob button:disabled label {
  color: #0E655D;
}

#CTA button:disabled:after,
.cta-mob button:disabled:after {
  background: url(../assets/icons/svg/constants/arrowDisabled.svg) no-repeat center;
}

.CTA-AR button:disabled:after {
  background: url(../assets/icons/svg/constants/arrowDisabled-ar.svg) no-repeat center !important;
}

.cta-mob button {
  width: 220px;
}

//hba
.dropdown-menu::before {
  content: ' ';
  display: block;
  position: absolute;
  bottom: 10%;
  left: 60%;
  width: 100%;
  height: 100%;
  background: url('../assets/icons/svg/drop down list/Polygon.svg') no-repeat;
  z-index: -1;
  /* padding-bottom: 15%; */
}