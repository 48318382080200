#forecast-wrapper-card,
.notifications-table-card {
    .card-header {
        display: flex;

        .sorting-and-filtering-container {
            width: inherit;
            margin-inline-start: auto;
        }
    }
}

.issues-table {
    .table-cell {
        .buttons-group {
            display: flex;
            justify-content: space-evenly;

            .view-reminder-button {
                border-radius: 5px !important;
                padding: 0.15rem 0.25rem !important;
                background: #003A35 0% 0% no-repeat padding-box;
                box-shadow: -3px -3px 6px 0px #7afaed33, 3px 3px 7px 0px #000000a6;
                border-radius: 5px;
                border: 0;
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;
                margin-inline: 0.5rem;
                height: 1.8em;
                width: 1.9em;
                justify-content: center;
                align-items: center;
            }

            .view-reminder-button:hover {
                background: #73E4CC 0% 0% no-repeat padding-box;

                svg {
                    path {
                        fill: #0A4A45;
                    }
                }
            }
        }
    }
}

.issues-graphs-card {
    .empty-data-state-container {
        margin-inline: 2%;
        padding: 0 !important;

        .icon {
            svg {
                width: 10em;
                height: 10em;
            }
        }
    }

    .card-header {
        display: flex;
        flex-direction: row
    }

    .card-body {
        padding: 0;

        .empty-state-graph {
            width: 100%;
            height: 55%;
            margin-top: 10px;
            margin-inline: auto;
            padding-bottom: 20px;

            .text-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: -15px;

                .title {
                    font-size: 12px;
                    font-weight: bold;
                    color: #C4FFF9;
                }

                .subtitle {
                    font-size: 14px;
                    color: #46AAA0
                }
            }

            img {
                width: 200px;
                height: 200px;
            }
        }
    }

    .current-issues {
        display: flex;
        flex: 2
    }

    .resolved-issues {
        display: flex;
        flex: 1;
        flex-direction: column;
        position: absolute;
        background-color: #0D4641B3;
        width: 110%;
        height: 160px;
        right: -2%;
        bottom: -10%;
        border-radius: 3px;

        @media (max-width:1000px) {
            height: 170px !important;
        }

        .reading-date-container {
            margin-inline-start: 0.9em;
            margin-block-start: 0;
            justify-content: flex-start;
        }

        .resolved-issues-title {
            color: #C4FFF9;
            margin-inline-start: 0.7em;
            margin-top: 0.2em;
            text-transform: uppercase;
            letter-spacing: 0px;
            font-weight: 600;
            // font-family: nunito_semibold;
            font-size: 0.8em;
        }
    }

    .card-with-icon-container {
        // width: 9em;
        width: -webkit-fill-available;
        margin-inline: 3%;
        padding-bottom: 0em;
        border-width: 2px;
        border-left-width: 5px;

        .icon-container {
            width: 2.7em;
            height: 2.7em;
            left: -1.4em;
            top: 1.3em
        }
    }

    .content-container {
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            font-weight: 800;
            font-size: 2.5em;
        }
    }

    .pie-charts-container {
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: space-between;
        margin-top: 1em
    }

    .issues-small-cards-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 0.7em;
        padding-bottom: 2%;
        height: 27%;

        .warning-danger-cards-container {
            display: flex;
            flex: 2;
            justify-content: space-evenly;
            margin-inline: 2%;

        }

        .resolved-card-container {
            display: flex;
            flex: 1;
            margin-inline-end: 2%;
            position: relative;
        }

        .Seperator {
            display: flex;
            flex: .1;
            opacity: 0.3;
            border-left: 2px dashed;
            display: flex;
            margin-inline-end: 2%;
        }
    }
}

.Issues-Container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.Issues-Map-Container {
    margin-inline-end: 6px;
    margin-inline-start: 6px;
    margin-top: 10px;
    
    .map {
       height: 200px;
       position: static;
    }
}

.Issues-Panel-Container {
    display: flex;
    flex-direction: column;
    flex: 2;
    margin-inline-start: 30px;
    margin-inline-end: 30px;
    margin-top: 25px;

    .users-selector {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 50%;
        -webkit-appearance: none !important;
        box-shadow: $dark-button-box-shadow;
        -webkit-box-shadow: $dark-button-box-shadow !important;
        border-radius: 5px;
        height: 2.5em;
        position: relative;
        margin-bottom: 2em;

        .triangle {
            position: absolute;
            width: 0;
            height: 0;
            top: 2.7em;
            left: 5.8em;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-bottom: 12px solid $light-container-background;
            z-index: 3000 !important;
        }

        .users-list-container {
            position: absolute;
            width: 13em;
            height: 6em;
            top: 3.5em;
            margin-bottom: 1em;
            z-index: 6000;

            .Checkbox-Container {
                .Checkbox-View {
                    background-color: #196A63;
                    box-shadow: 3px 3px 6px #02312C64;

                    &:hover {
                        background-color: #196A63 !important;
                    }
                }
            }

            ul {
                border-radius: 5px;
                overflow-y: scroll;
                width: 100%;
                height: 100%;
                list-style: none;
                background-color: #196A63;
                padding-inline-start: 1em;
                padding-block: 1em;

                li {
                    margin-bottom: 0.6em;
                }
            }
        }

        button {
            display: flex;
            outline: none;
            border-color: transparent;
            background-color: #0D4944;
            align-items: center;
            color: #73E4CC;
        }

        .users-button {
            display: flex;
            flex: 4;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            height: 100%;

            span {
                margin-inline-start: 0.7em;
            }
        }

        .clear-button {
            height: 100%;
            display: flex;
            flex: 1;
            justify-content: center;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            position: relative;
            border-left: 1px solid #46AAA02C;
        }
    }

    .Issues-Panel-Scroll-Content {
        overflow-y: scroll;
        height: 400px;
        font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
    }

    .Issues-Reminder-Panel-Scroll-Content {
        overflow-y: scroll;
        height: 500px;
        font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
    }

    .Issue-Panel-Sensor-Content {
        display: flex;
        width: 100%;

        .Issues-Panel-Sensor-Content-Wrapper {
            //font-size: 0.7rem;
            flex: 1;
            //margin-inline-end: 6rem;
            margin-inline-end: 1rem;

            .Issues-Panel-Sensor-Content-Wrapper-Title {
                color: $primary-txt;
                font-weight: 600;
            }
        }
    }
}

.Issues-Reminder-Message-Wrapper {
    .input-group-prepend {
        white-space: nowrap;
        vertical-align: middle;
        margin-top: 1.1rem;
    }

}

.Issues-Panel-Title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary-txt;
}

.Issues-Panel-Sensor-Status {
    display: flex;
    width: fit-content;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    height: 1.2rem;
    border: 1px solid;
    margin-inline-start: 10px;

    p {
        margin-top: 0.9rem;
        font-size: 0.7rem;
        padding-inline-start: 0.5rem;
        padding-inline-end: 0.5rem;
    }
}

.Issues-Panel-Description {
    margin-top: 10px;

    .Issues-Panel-Description-Title {
        color: $primary-txt;
        font-weight: 600;
    }

    //font-size: 0.7rem;
}

.Issues-Panel-GraphView {
    background-color: #044640;
    margin-top: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    padding-inline-start: 0.5rem;
    padding-inline-end: 0.5rem;

    .empty-state-graph svg {
        width: 7.8em;
        height: 12em;
    }

}

.Issue-Panel-SubTitle-Panel {
    display: flex;
    color: #46AAA0;
    text-transform: capitalize !important;
    margin-block-start: 0.5em;

    p {
        margin-inline-start: 0.5rem;
    }
}

.Issues-Panel-Suggestions-Wrapper {
    margin-top: 15px;
    // width:inherit;
    // height: 8rem;
    background: #03403A 0% 0% no-repeat padding-box;
    border: 1px solid #C4FFF933;
    border-radius: 5px;
    opacity: 1;
    display: flex;
    padding-inline-start: 12px;
    padding-inline-end: 12px;

    .Issues-Panel-Suggestions-Content {
        font-size: 0.7rem;
        margin-top: 1rem;

        .Issues-Panel-Suggestions-Title {
            color: $primary-txt;
            font-weight: 600;
        }
    }

    .Issues-Reminder-Panel-UserSelector {
        margin-top: 1rem;
        ;
    }
}

.crops-issues {
    img {
        width: 50%;
        height: 80%;
    }

    .text-container {
        .title {
            font-size: 1em;
        }
    }
}

[dir='rtl'] {
    .issues-graphs-card {
        .resolved-issues {
            width: 105% !important;
        }

        .card-with-icon-container .icon-container {
            left: inherit !important;
            right: -1.4em !important;
        }
    }

    .Issues-Panel-Container .Issue-Panel-Sensor-Content .Issues-Panel-Sensor-Content-Wrapper .Issues-Panel-Sensor-Content-Wrapper-Title,
    .Issues-Panel-Description .Issues-Panel-Description-Title {
        font-weight: 400;
    }
}

@media (min-width: 1000px) and (max-width: 1150px) {

    .resolved-issues-title,
    .reading-date-container {
        font-size: 0.7em !important;
    }

    // .card-with-icon-container .icon-container{
    //     left:inherit !important;
    //     right: -1.4em !important;
    // }
}

@media (max-width: 1000px) {

    .resolved-issues-title,
    .reading-date-container {
        font-size: 0.6em !important;
    }
}

@media (min-width:310px) and (max-width:480px) {}


@media (min-width:481px) and (max-width:768px) {}

@media (min-width:769px) and (max-width:1024px) {}


@media (min-width:1025px) and (max-width:1200px) {}


@media (min-width:1600px) {}