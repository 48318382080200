.checbox-menu {
    #action-btn.active {
        background: #73e4cc !important;
        border: 1px solid #c4fff9;
        color: #0A645C !important;

        svg {
            path {
                fill: #0A645C;
            }
        }
    }
  .checbox-menu-holder {
    position: relative;
    .triangle {
      position: absolute;
      width: 0;
      height: 0;
      top: 0;
      left: 15px;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 12px solid $light-container-background;
      z-index: 1000 !important;

      [dir="rtl"] & {
        right: 15px;
      }
    }

    .menu-container {
      position: absolute;
      top: 10px;
      z-index: 100 !important;
      background: #196a63;
      max-height: 250px;
      overflow-y: scroll;
      border-radius: 5px;
      width: max-content;

      .select-all-checkbox {
        padding: 20px 30px 15px;
        border-bottom: 1px solid #c4fff926;
      }

      .checkbox-list {
        padding-inline: 30px;

        .Checkbox-Container {
          padding-block: 15px;
        }
      }
    }
  }
}
