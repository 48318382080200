.add-measurements-container {
    .Panel {
        padding-inline: 30px;
        padding-block: 15px;

        .add-measurements-title {
            color: #C4FFF9;
            letter-spacing: 0.18px;
            text-transform: uppercase;
            //font-size: 1em
            font-size:calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
        }

        .add-measurements-body {
            height: 75vh;
            overflow-y: scroll;

            .accordion-list {
                margin-top: 30px;
            }

            .accordion-tab-body {
                padding-block: 10px !important;

                .tab-content-container {
                    padding-inline: 30px;

                    .input-wrapper {
                        position: relative;

                        .input-suffix {
                            position: absolute;
                            right: 0;
                            top: 15px;
                            color: #46AAA0;
                            font-size: 0.9em
                        }

                      
                    }
                }
            }

            .selection-buttons-container {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                .Timer-Container .calendar-button {
                    display: flex;
                    align-items: center;
                }

                .Timer-Container .calendar-button span {
                    padding-inline-end: 0 !important;
                }

                .Timer-Container .calendar-button::before {
                    width: 2em !important;
                    height: 1.5em !important;
                }

                .label {
                    letter-spacing: 0px;
                    color: #C4FFF9;
                    text-transform: uppercase;
                    //font-size: 0.9em;
                    font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
                    margin-bottom: 8px;
                    margin-top: 20px
                }

                .date-picker-button,
                .calendar-button {
                    width: 150px !important;
                    height: 42px !important;
                    background-color: #165F59;
                    outline: none !important;
                    color: #73E4CC;
                    box-shadow: -4px -4px 5px #12756c, 4px 4px 10px #02282491 !important;
                    -webkit-box-shadow: -4px -4px 5px #12756c, 4px 4px 10px #02282491 !important;
                }

                // .date-picker-button {
                //     position: relative;
                // }

                .date-picker-button::before {
                    content: url('../../assets/icons/svg/MainMap/calendar.svg');
                    width: 2em;
                    height: 1.5em;
                    z-index: 1000 !important;
                    position: absolute;
                    left: 0.3em;
                    top: 0.75em;
                }

                .triangle {
                    width: 0;
                    height: 0;
                    position: absolute;
                    top: 42px;
                    left: 81px;
                    border-left: 12px solid transparent;
                    border-right: 12px solid transparent;
                    border-bottom: 12px solid $light-container-background;
                    z-index: 1000 !important;
                }

                .calendar {
                    z-index: 100 !important;
                    position: absolute;
                    top: 54px;
                    left: 0;

                    .date-picker-container {
                        width: 15em;
                        background-color: $light-container-background  !important;
                    }

                    .year-input-container,
                    .month-input-container {
                        span {
                            display: flex;
                            justify-content: flex-start;
                        }
                    }
                }
            }

            .date-wrapper {
                margin-inline-end: 60px;

                .date-container {
                    position: relative;
                }
            }

            // .comments-wrapper {
            //     margin-top: 13px;
            //     position: relative;

            //     .input-group-prepend {
            //         top: inherit !important;
            //         bottom: 5px !important;
            //     }

            //     .input-suffix {
            //         position: absolute;
            //         right: 0;
            //         top:40px;
            //         color: #46AAA0;
            //         font-size: 0.9em
            //     }
            //     form .input-group {
            //         margin-top: 50px !important;
            //     }
            // }
            .error {
                color: #F48585;
                margin-top: -20px;
                font-size: 0.9em;
            }
        }

        .proceed-button {
            width: 200px;
            height: 30px;
            margin-top: auto;
            margin-inline: auto;
            margin-bottom: 20px;
        }
    }
    .c-icon{
        fill: #73e4cc;
        stroke: #73e4cc;
        //background-color: #1b7c73;
        border-radius: 35px;
        padding: 3px;
        width: 1.2rem !important;
        height: 1.2rem !important;
    }
}

[dir='rtl'] .add-measurements-container {
    .accordion-tab-body .tab-content-container .input-wrapper .input-suffix {
        left: 0;
        right: inherit !important;
    }

    .comments-wrapper .input-suffix {
        left: 0;
        right: inherit !important;
    }

    .selection-buttons-container .date-picker-button::before {
        right: 0.3em;
        left: inherit !important
    }

    .Panel .selection-buttons-container .triangle {
        right: 81px;
        left: inherit !important
    }

    .selection-buttons-container .calendar {
        right: 0;
        left: inherit !important
    }
}