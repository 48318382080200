.crop-logs-card{
    min-height:70em;
    .fertigation-table-container{
        position:relative;
        padding-bottom: 4em;
        .table-Container {
            padding-bottom: 0.8em;
        }
    }
    .irrigation-table-container{
        position:relative;
        padding-bottom: 4em;
        .table-Container {
            padding-bottom: 0.8em;
        }
    } 
}

