.submitted-card {
    background: #024842 0% 0% no-repeat padding-box;
    border: 2px solid #46AAA0;
    border-radius: 5px;
    font-size: 0.8rem;
    padding-inline: 1rem;
    padding-block: 0.5rem;
    margin-block-end: 10px;

    .plot-id {
        text-transform: uppercase;
        color: #C4FFF9;
    }

    .info-row {
        .key {
            text-transform: capitalize;
            color: #C4FFF9;
        }

        .value {
            color: #46AAA0;
        }
    }

    .buttons-container {
        button {
            outline: none;
            border: none;
            background: #01443E 0% 0% no-repeat padding-box;
            // @extend .neuo;
            box-shadow: -3px -3px 6px lighten(#01443E, 5%), 3px 4px 8px darken(#01443E, 4.3%) !important;
            border-radius: 5px;
            margin-inline-end: 10px;
            margin-block-start: 5px;
            width: 1.6rem;
            height: 1.6rem;

            &:disabled {
                opacity: 0.3
            }

            &:not(:disabled):hover {
                background: #73E4CC 0% 0% no-repeat padding-box;
                border: 1px solid #C4FFF9;
            }
        }

        .delete-button:not(:disabled):hover {
            svg {
                path {
                    fill: #003a35;
                }

                rect {
                    fill: #73e4cc;
                }
            }
        }

        .edit-button:not(:disabled):hover {
            svg {
                path {
                    fill: #0A4A45;
                }
            }
        }
    }
}