.drop-down-selector-container {
    background: $main_layer 0% 0% no-repeat padding-box;
    border-radius: 5px;
    min-width: 2.5em;
    height: 2em !important;
    position: relative;
    @extend .regular-font;

    .triangle {
        position: absolute;
        width: 0;
        height: 0;
        top: 105%;
        left: 1em;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $sidebar;
        z-index: 1000 !important;
    }

    .current-choice {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .choices-list {

        position: absolute;
        top: 2.5em;
        left: 0;
        width: 6em;
        background-color: $sidebar;
        border-radius: 5px;
        list-style-type: none;
        padding: 0;
        z-index: 2;

        li {
            padding-inline: 1em;
            padding-block: 0.5em;
            position: relative;

            .border {
                display: none;
            }

        }

        .choice-on-focus {
            color: $primary-txt;

            .border {
                display: block;
                position: absolute;
                left: 0;
                width: 0.2em;
                height: 1.7em;
                background: $primary 0% 0% no-repeat padding-box;
                border-radius: 0px 2px 2px 0px;
            }

        }
    }

    .is-cell {
        position: fixed;
    }
}


.table-drop-down-view-container {
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 3%;
    // bottom: 3%;
    bottom: 1%;

    .drop-down-selector-container {
        background-color: $container-background !important;
        // box-shadow: -2px -2px 5px #0B837780 !important ;
        $lower-shadow: 4px 4px 8px darken($container-background, 3%);
        $upper-shadow: -3px -3px 6px lighten($container-background, 4%);
        box-shadow: $upper-shadow, $lower-shadow !important;
    }
}

[dir='rtl'] .table-drop-down-view-container {
    right: inherit !important;
    left: 3%;
}

.top-mode {
    .choices-list {
        top: -8.5em !important;
        left: -1em;
    }

    .triangle {
        top: -1em;
        border-top: 10px solid $sidebar !important;
        border-bottom: none !important;
        left: 0.7em !important;
    }
}

[dir='rtl'] .border {
    right: 0 !important;
    left: inherit !important;
}