.selection-chip {
  background: #02332f 0% 0% no-repeat padding-box;
  box-shadow: 3px 4px 8px #032925;
  border-radius: 19px;
  padding: 6px 14px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: $primary;

  @media (max-width: 600px) {
    padding-inline: 10px;
  }

  .delete-btn {
    margin-inline-start: 12px;
    padding: 0;
    font-size: 16px;
    color: $primary !important;
  }

  &:hover {
    background: $primary 0% 0% no-repeat padding-box;
    border: 2px solid $primary-txt;
    color: #0a645c;
    .delete-btn {
      color: #0a645c !important;
    }
  }
}
