.reading-date-container{
    display: flex;
    //font-size: 0.7em;
    font-size: calc(10px + (12 - 10) * ((100vw - 320px) / (2800 - 320)));
    justify-content: center;
    align-items: center;
    //width:10em;
    //height:1.8em;
    color: #46AAA0;
    margin-inline:auto;
    margin-block-start: 1.2em;
    &::before{
        display: flex;
        justify-content: center;
        // align-items: center;
        flex-wrap: wrap;
        content: url('../../assets/icons/svg/constants/clock.svg');
        width:1em;
        // height:1.2em;
        padding-top: 2px;
        margin-inline-end: 0.5em;
    }
}

.past-date{
    background-color: #97373759;
    border: 0.1px solid #973737;
    border-radius: 3px;
    padding: 0.2em 0.5em;
    width: 11em;
}
[dir='rtl']{
    .reading-date-container {
        font-size: calc(10px + (14 - 10) * ((100vw - 320px) / (2800 - 320)));
        font-weight: 600;
    }
}