// .danger {
//     padding:2% ;
//     border-radius: 5px;
//     @include validation-alert;
// }
.alert-danger {
    display: flex;
    align-items: center;
    color: $validation-txt !important;
    background-color: $error-helper !important;
    padding-left: 3% !important;
}
.alert-danger::before {
    content: url('../../assets/icons/svg/constants/error.svg');
    padding: 0 10px;
    padding-top: 1%;
}
.alert{
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    padding-inline: 5%;
    height: 35px;
    font-size: 0.8em;
}  
