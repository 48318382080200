.quarter-picker-wrapper {
    background-color: #165F59;
    width: 200px;
    border-radius: 3px;
    z-index: 100;

    .navigation-bar {
        position: relative;
        color: #C4FFF9;
        display: flex;
        justify-content: center;
        text-align: center;
        width: 100%;

        .current-year {
            padding-top: 0.5em;
        }

        .previous {
            background-image: url(../../assets/icons/svg/calendar/left.svg);
            background-repeat: no-repeat !important;
            background-color: transparent;
            background-size: 0.7em;
            border: none;
            outline: none;
            width: 1.5em;
            height: 1.5em;
            top: 1em;
            position: absolute;
            inset-inline-start: 30%;
        }

        .next {
            background-image: url(../../assets/icons/svg/calendar/right.svg);
            background-repeat: no-repeat !important;
            background-color: transparent;
            background-size: 0.7em;
            border: none;
            outline: none;
            width: 1.5em;
            height: 1.5em;
            top: 1em;
            position: absolute;
            inset-inline-end: 25%;
        }
    }

    .content {
        display: table;
        width: 100%;
        padding-block: 10px;
        padding-inline: 20px;
    }

    .quarter-row {
        display: table-row;
        margin-block-end: 5px;
        border-radius: 5px;
        padding-inline: 5px;
        border: none;
        background: transparent;
        outline: none;

        .cell {
            display: table-cell;
            color: #73E4CC;
            padding-inline-end: 20px;
            white-space: nowrap;
            padding-block: 3px;
            text-align: start;
        }

        .last-cell {
            padding-inline-end: 0 !important;
        }
    }


    .quarter-row:hover,
    .selected {
        background-color: #73E4CC;

        .cell {
            color: #205750;
        }
    }

    .line-separator {
        width: 80%;
        border-bottom: 1px solid #46AAA080;
        opacity: 0.5;
        margin-inline: auto;
    }

    .proceed-container {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;

        .proceed-button {
            margin-block: 15px;
            width: 60%;
        }
    }
}

[dir='rtl'] .quarter-picker-wrapper {
        width: 245px;
    .content{
        font-family: tajawal_medium;
        font-size: calc(12px + (12 - 12) * ((100vw - 320px) / (2800 - 320)));
    }
    .previous {
        background-image: url(../../assets/icons/svg/calendar/right.svg);
    }

    .next {
        background-image: url(../../assets/icons/svg/calendar/left.svg);
    }
}