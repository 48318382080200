
// .BottomPanelPosition-irriwatch{
//     position: absolute;
//     inset-inline-start: 3rem;
//     z-index: 10;
//     @media(max-width: 1200px ){
//         inset-inline-start: 0.5rem;
//     }
// }
// .BottomPanelPosition-irriwatch{
//   margin: 30px 100px 0 ;
// }
// .panel-container-plot-view{
//   background-color: #03403A;
// }
.bottom-panel-irriwatch {
  // border-radius: 10px;
  width: 23rem;
  height: 59px;
  background: #02312C;
  padding: 1.9rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #FFFFFF;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  opacity: 1;
    .panel-item {
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1 -1;
      }

    .panel-text {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-wrap-mode: nowrap;
        // padding-left: 5px;
      }
      
      .icon {
        width: 30px;
        height: 60px;
        margin-bottom: 4px;
        margin-right: 5px;
        // margin-left: 20px;
      }
      .icon-date{
        width: 20px;
        height: 40px;
        margin-bottom: 4px;
        margin-right: 5px;
      }
      
      .value {
        font-size: 16px;
        font-weight: bold;
      }
      
      .label {
        color: #46AAA0;
        // font-size: 14px;
      }
  }
  .bottom-panel-irriwatch.plot-view{
    width: 720px;
    overflow-y: hidden;
    gap: 15px;
    overflow-x: scroll;
  }
  /* For screens larger than 768px */

  [dir=rtl] .bottom-panel-irriwatch .icon {
    margin-right: unset;
    margin-left: 5px;
  }
  [dir=rtl] .bottom-panel-irriwatch .icon-date{
    margin-right: unset;
    margin-left: 5px;
  }

[dir=rtl] .bottom-panel-irriwatch{
  left: unset;
  right: 2%;
}
[dir=rtl] .bottom-panel-irriwatch.plot-view{
  left: unset;
  right: -12%;
}


@media(max-width: 768px) {
  // .bottom-panel-container{
  //   width: 100%;
  //   height: 59px;
  //   z-index: 10000;
  //   border-radius: 5px;
  //   background: #02312c;
  //   display: flex;
  //   padding: unset;
  //   top: 395px;
  //   position: absolute;
  //   bottom: 0px;
  //   transform: translateY(123%);
  // }
  .bottom-panel-irriwatch {

    width:22rem;
    inset-inline-end: -2px;
    margin-top: 0;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .bottom-panel-irriwatch.plot-view{
    position: absolute;
    left: 0%;
    transform: translateY(124%);
    width: -webkit-fill-available;
    overflow-x: scroll;
    background: #02312c;
    overflow-y: hidden;
    gap: 25px;
  }
  [dir=rtl] .bottom-panel-irriwatch.plot-view{
    left: unset;
    right: unset;
  }
}