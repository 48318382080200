.dosage-container{
    display:flex;
    flex-direction:column;
    padding-inline-end:1.2em;
    padding-inline-start:1.2em;
    padding-bottom: 1em;
    .error{
        display: inline-block;
        position: absolute;
        bottom: 0 !important;
        margin-inline-start:0;
        color:#F48585 ;
        font-size: .86em;
        left: inherit;
    }
    .dosage-volume-container{
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        .dosage-volume-container-start{
            padding-inline-end: 1em;
        }
        .dosage-volume-container-end{
            padding-inline-start: 1em;
        }
    }
    .delete-fertilizer{
        color: #73E4CC;
        margin-bottom: 0em;
        display: flex;
        flex-direction: row-reverse;
        flex:30%
    }
}
.add-fertilizer{
    color: #73E4CC;
    margin-bottom: 1.5em;
}