.switch-lang {
  @extend .neuo;
  width: 4.5em;
  height: 2.5em;
  margin-inline-end: 1.4em;
  margin-top: 1.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary);
  background-color: transparent;
  position: relative;
  &__image {
    width: 16px;
    height: 16px;
    margin-inline-end: 8px;
  }
  .drop-down-selector-container {
    background: inherit !important;
    box-shadow: none !important;
    border: none !important;
    .choices-list li:first-child {
      @extend .choice-on-focus;
    }
  }

  .current-choice {
    span {
      font-family: tajawal_medium !important;
    }
  }
  .choices-list li:nth-child(2){
    font-family: tajawal_medium !important;
  }

}

[dir="rtl"] .switch-lang {
  width: 5.5em;
  .current-choice {
    span {
      font-family: nunito_semibold !important;
    }
  }

  .choices-list li:first-child {
    color: inherit;
    font-family: nunito_semibold !important;
    .border {
      display: none;
    }
    &:hover {
      color: $primary-txt;
      .border {
        display: block;
      }
    }
  }
  .choices-list li:nth-child(2) {
    @extend .choice-on-focus;
  }
}
