.alarm-settings-panel {
  .Panel {
    height: 100vh;
    width: 45vw !important;
    @media (max-width: 1200px) {
     // width: 45vw !important;
    }
    @media (max-width: 850px) {
      width: 100vw !important;
    }
  }

  .Panel-Header {
    margin-top: 30px !important;
  }

  .thresholds-panel-title {
    .sensor-details-buttons-container {
      padding-inline-start: 24px;
    }
  }

  .panel-content-container {
    padding: 0 30px;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    .alert-note {
      margin-inline-start: 10px;
      margin-bottom: 0;
      @extend .regular-font;
    }

    .slider-container {
      text-align: center;
    }

    .semibold-label {
      @extend .semibold-font;
      font-size: 16px;
      color: $primary-txt;
    }

    .forecast-label {
      margin-bottom: 14px;
    }

    .scrollable-container {
      overflow-y: scroll;
      padding-bottom: 50px;
      flex: 0.8;
    }

    .sensor-threshold-cards-conrainer {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .sensor-threshold-card {
      .sensor-name {
        line-height: 30px;
        font-size: 16px;
        margin-bottom: 0;
      }

      .card-container {
        border: 1px solid #46aaa080;
        border-radius: 5px;
        background-color: #043d394d;
        padding: 24px 10px;
        row-gap: 30px;
        margin-inline: 0;

        .input-group {
          margin-block: 0 !important;

          .form-control + span {
            inset-inline-start: 0 !important;
          }
        }

        .input-error-message {
          text-transform: lowercase;
        }
      }
    }

    .pagination-container {
      position: static;
    }

    .warning-status {
      margin-block: 54px 20px;
      font-size: 16px;

      .description {
        font-style: italic;
      }
    }

    .status-types-checkboc-container {
      .col-sm-3 {
        width: auto;
      }
      .Checkbox-Title {
        margin-inline-start: 12px;
        font-size: 13px;
      }
    }

    .btn-container {
      position: absolute;
      bottom: 60px;
      right: 0;
      left: 0;
      text-align: center;
      padding: 20px 0;
      width: 100%;
      z-index: 100;
      background-color: #165f59;

      .proceed-button {
        margin-top: 0;
        padding: 10px 20px;
        height: auto;
      }
    }

    .weather-alarming-step {
      overflow-y: scroll;
      padding-top: 40px;
      padding-bottom: 90px;
      flex: 0.8 1;

      .notification-medium-menu {
        .menu-button {
          display: flex;
          align-items: center;
          background: #0a4a45 0% 0% no-repeat padding-box;
          box-shadow: -4px -2px 6px #46aaa047 !important;
          border-radius: 5px;
          color: $primary !important;
          padding: 8px;

          &:hover:enabled {
            background: #73e4cc 0% 0% no-repeat padding-box !important;
            color: $cta-txt !important;
            border: 2px solid #c4fff9 !important;
          }

          &.disabled {
            opacity: 20%;
          }

          img {
            height: 16px;
            width: 16px;
            margin-inline-end: 5px;
          }

          .menu-button-label {
            line-height: 20px;
            font-size: 13px;
            margin-top: 4px;
          }
        }

        .dropdown-menu {
          background-color: #165f59;
          box-shadow: #03332d80 0px 0px 8px;

          &::before {
            content: unset;
          }

          .dropdown-item:hover, .dropdown-item:focus {
            background-color: transparent;
          }
        }
      }

      .notification-types-container {
        display: flex;
        gap: 20px;
      }

      .table-view-container {
        .table-Container {
          padding-bottom: 0;
          .table-title {
            box-shadow: 0px -1px 6px #00000029;
          }

          .scrollable td {
            padding-inline: 12px;
          }
        }


        div:has(.phone-input-container) {
          flex-grow: 1;
        }
        
        .phone-input-container {
          [dir="rtl"] & { 
            flex-direction: row-reverse;
            input {
              text-align: left;
            }
          }

          .PhoneInput {
            width: 100%;
  
            input {
              width: 80% !important;
            }
          }
        }
        

        .mobile-message-error {
          color: $secondary-txt;
        }

        form  {
          flex-grow: 1;
          .input-group {
            margin-block: 15px 0 !important;
          }
        }

        .Checkbox-View {
          background-color: #114a45;
          box-shadow: -3px -3px 6px #19726b, 3px 4px 8px #082d29 !important;
        }

        .delete-button {
          padding-inline: 6px;
          padding-block: 2px 4px;
          box-shadow: -2px -2px 5px #0b837780 !important;
          border-radius: 3px;
          &:hover {
            background: #73e4cc 0% 0% no-repeat padding-box;
            border: 1px solid $primary-txt;
          }
          svg {
            height: 14px;
            width: 11px;
          }
          /* path {
            fill: #ff5b33;
            &:hover {
              fill: blue;
            }
              
          }*/
        }

        .add-new-record-btn {
          width: 100%;
          background: $dark-button-background 0% 0% no-repeat padding-box;
          border-radius: 0px 0px 5px 5px;
          color: $primary !important;
          font-size: 14px;
          text-align: start;
          padding: 15px 20px;
        }
      }

      .share-with-container {
        color: $primary;
        margin-bottom: 15px;
        font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (2800 - 320)));
      }
    }
  }
  .error {
    color: $error-message;
    text-align: start;
  }
}

#alarm-settings-button {
  position: fixed;
  top: 150px;
  border-radius: 5px 5px 0px 0px !important;
  color: $primary !important;
  padding: 5px 10px !important;
  background: #147e74 0% 0% no-repeat padding-box !important;
  border-color: $main_layer !important;
  box-shadow: -3px -3px 6px rgba(13, 13, 13, 0.1607843137), 3px 4px 8px rgba(0, 0, 0, 0.1607843137) !important;

  [dir="rtl"] & {
    transform: rotate(90deg);
    transform-origin: 31px 0px;
    left: 0;
  }
  [dir="ltr"] & {
    transform: rotate(-90deg);
    right: 0;
    transform-origin: 110px 33px;
  }
}

#alarm-settings-button:hover {
  background: $primary 0% 0% no-repeat padding-box !important;
  border: 2px solid $primary-txt !important;
  color: #165c55 !important;
}

.alarm-settings-body {
  margin-inline-end: 2vw !important;

  @media (max-width: 1200px) {
    margin-inline-end: 4vw !important;
  }

  @media (max-width: 600px) {
    margin-inline-start: 2vw !important;
    margin-inline-end: 9vw !important;
  }
}

.alarm-settings-header {
  padding-inline-end: 3vw !important;

  @media (max-width: 1200px) {
    padding-inline-end: 4vw !important;
  }

  @media (max-width: 600px) {
    padding-inline-end: 9vw !important;
  }
}
