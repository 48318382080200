.weather-sensors-graphs-container{
    display:flex;
    flex-direction: row;
    height:100%;
   
}

.weather-sensor-graph-container{
    flex:1;
    height:100%
}

// .weather-sensor-graph-container canvas{
//     height: 340px !important;
// }

.wind-sensor-graph-container{
    flex:1;
    margin-inline-start: 20px;
    height:100%
}




@media  (max-width: 600px){
    .weather-sensors-graphs-container{
        display:flex;
        flex-direction: column;
    }
    .dashboard-card-label{
        font-size: .75em !important;
        @media(min-height: 1000px) { font-size: .9em !important; }
    }

    .wind-sensor-graph-container{
        margin-top: 1.25em;
    }
   
}