.Error-Container {
   height: 100vh;
   width: 100vw;
   display: flex;
   flex: 1;
   flex-direction: column;

   // .Logo-View{
   //    display: flex;
   //    flex:1 ;
   //    margin-inline-start: 10px;
   //    margin-top: 10px;
   // }
   .Content-View {
      display: flex;
      flex: 6;
      flex-direction: row;

      .namaa-logo {
         display: flex;
         flex-direction: column;
         align-items: center;
         margin-top: 2em;
         margin-inline-start: 2em;

         svg {
            width: 10em !important;
            height: 10em !important;
         }
      }

      .Error-Text {
         display: flex;
         flex: 1;
         flex-direction: column;
         align-items: center;
         text-align: start;
         justify-content: center;

         //   margin-bottom: 5%;
         h1 {
            font-size: 2em;
            color: $primary-txt;
            width: 50%;
            font-weight: bold;
         }

         p {
            width: 50%
         }

         .Button-Container {
            margin-top: 5%;
            // margin-right: 17%;
            margin-inline-end: 26%;
         }
      }

      .Error-Background {
         display: flex;
         flex: 1;
         background: url('../../assets/icons/svg/ErrorHandler/failureImg.svg') no-repeat;
         background-position: center;
         background-size: contain;

         [dir="rtl"] & {
            margin-left: 15%;
         }

         .Page-Logo {
            width: 500px;
         }

         // .Dashboard-Logo{
         //    //width: 400px;
         //    width: 100%;
         //    [dir="rtl"] &{
         //       margin-inline: -20%;
         //    }
         // }
      }
   }

   @media (min-width:310px) and (max-width:480px) {
      .Content-View {
         display: flex;
         flex: 6;
         flex-direction: row;

         .Error-Text {
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: center;
            text-align: start;
            justify-content: center;
            margin-bottom: 5%;

            h1 {
               font-size: 1.2em;
               color: $primary-txt;
               width: 50%;
               font-weight: bold;
            }

            p {
               width: 50%;
               font-size: 8px;
            }

            .Button-Container {
               margin-top: 5%;
               display: flex;
               margin-left: 5%;
               align-self: center;

            }
         }

         .Error-Background {
            display: none;

         }
      }
   }

   @media (min-width:481px) and (max-width:599px) {
      .Content-View {
         display: flex;
         flex: 6;
         flex-direction: row;

         .Error-Text {
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: center;
            text-align: start;
            justify-content: center;
            margin-bottom: 5%;

            h1 {
               font-size: 0.8em;
               color: $primary-txt;
               width: 50%;
               font-weight: bold;
            }

            p {
               width: 50%;
               font-size: 6px;
            }

            .Button-Container {
               margin-top: 5%;
               margin-right: 17%;



            }
         }

         .Error-Background {
            display: flex;
            flex: 1;

            .Page-Logo {
               width: 200px;
            }

            .Dashboard-Logo {
               width: 200px;
            }
         }
      }

   }

   @media (min-width:600px) and (max-width:768px) {
      .Content-View {
         display: flex;
         flex: 6;
         flex-direction: row;

         .Error-Text {
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: center;
            text-align: start;
            justify-content: center;
            margin-bottom: 5%;

            h1 {
               font-size: 1.1em;
               color: $primary-txt;
               width: 50%;
               font-weight: bold;
            }

            p {
               width: 50%;
               font-size: 8px;
            }

            .Button-Container {
               margin-top: 5%;
               margin-right: 17%;

            }
         }

         .Error-Background {
            display: flex;
            flex: 1;

            .Page-Logo {
               width: 300px;
            }

            .Dashboard-Logo {
               width: 200px;
            }
         }
      }

   }

   @media (min-width:769px) and (max-width:1024px) {
      .Content-View {
         display: flex;
         flex: 6;
         flex-direction: row;

         .Error-Text {
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: center;
            text-align: start;
            justify-content: center;
            margin-bottom: 5%;

            h1 {
               font-size: 1.3em;
               color: $primary-txt;
               width: 50%;
               font-weight: bold;
            }

            p {
               width: 50%;
               font-size: 10px;
            }

            .Button-Container {
               margin-top: 5%;
               margin-right: 17%;
            }
         }

         .Error-Background {
            display: flex;
            flex: 1;

            .Page-Logo {
               width: 380px;
            }

            .Dashboard-Logo {
               width: 250px;
            }
         }
      }
   }


   @media (min-width:1025px) and (max-width:1200px) {
      .Content-View {
         display: flex;
         flex: 6;
         flex-direction: row;

         .Error-Text {
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: center;
            text-align: start;
            justify-content: center;
            margin-bottom: 5%;

            h1 {
               font-size: 1.5em;
               color: $primary-txt;
               width: 50%;
               font-weight: bold;
            }

            p {
               width: 50%;
               font-size: 12px;
            }

            .Button-Container {
               margin-top: 5%;
               margin-right: 17%;

            }
         }

         .Error-Background {
            display: flex;
            flex: 1;

            .Page-Logo {
               width: 450px;
            }

            .Dashboard-Logo {
               width: 290px;
            }
         }
      }

   }




}