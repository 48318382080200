.calendar-dashboard{
    .col-3, .col-9{
        padding-right: 6px !important;
        padding-left: 6px !important;
    }   
}

.deleteReoccurringTasks {
    .options {
      margin-top: 15px;
    }

    .Checkbox-View {
      border-radius: 50%;
      background-color: #024842;
      box-shadow: 4px 3px 6px #021816cc;
      margin-block: 15px;
    }
    .Checkbox-checked {
      border-radius: 50%;
    }
}

@media (max-width: 878px){
    .calendar-dashboard{
        // .col-12{
        //     padding-right: 7px !important;
        //     padding-left: 7px !important;
        // }   
    }
}