// Browsers

// Small screen esolution
@media (min-width: 990px) and (max-width:1024px){
    .c-wrapper:not(.c-wrapper-fluid){
        .c-body{
          padding-left: 0.2%;
        }
      }
}
// Large screen resolution
@media (min-width:1680px){
}

//Breakpoints

// Mobile devices
@media only screen and (max-device-width:480px)
{
    #CTA{
        button{
            width:210px;
            height: 42px;
        }
    }
}
// Ipads and tablets
@media only screen and (max-device-width:768px)
{
   
    
}
// Small screen laptops
@media only screen and (max-device-width:1024px)
{
   
}
// Large desktop screens
@media only screen and (max-device-width:1200px)
{
   
}
// Monitors and TV
@media only screen and (min-device-width: 1201px)
{
   
}


//wearables
@media only screen and (max-device-width: 218px) 
{ 

}

//orientation portraint/landscape ?!