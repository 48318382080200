#map-card-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // padding: 0  auto;
    // margin: 0 auto;
    border-radius: 1% 0 0 1%;
    overflow: hidden;
}



::-webkit-scrollbar {
    display: none;
    width: 3px;
}

#issues-translucent-container {
    // height:7em !important;
    background: rgba(2, 49, 44, 0.7) 0% 0% no-repeat padding-box !important;
    border-radius: 4px !important;
    //overflow-y: hidden;

    // height: 7.8em !important;
    z-index: 1;

}

#issues-container-view {
    display: flex;
    flex-direction: row !important;
    // margin-top: -0.4em !important;
    height: 100%;
    align-items: center;
    z-index: -3000;
}

#issues-list {
    margin: 0;
    margin-inline-start: 1em;
    display: flex;
    flex: 4;
    padding: 0;
    align-items: center;
    list-style-type: none;
    overflow: scroll;
    scrollbar-width: none !important;

    ::-webkit-scrollbar-thumb {
        background: #256F68;
        border-radius: 10px;
        width: 2px !important;
        cursor: pointer !important;
    }
}

.issue {
    border-radius: 10px;
    margin-inline-end: 1em;
    //margin-top:1em;
    //margin-top: 1.3em;
    opacity: 1;
    height: 5.5em !important;
    flex: 1;
    max-width: 23em;
    word-wrap: 'break-word';
    z-index: 1;
    // font-size: .9em;
}

#issue-time {
    text-align: end;
    margin: 0;
    padding-top: 1%;
    margin-inline-end: 3%;
    font-size: .75em;

    @media(min-height: 1000px) {
        font-size: .9em !important;
    }
}

#issue-content {
    display: flex;
    flex-direction: row;
    //margin-top: -0.4em;
    margin-top: 0;
}

.issue-status-icon {
    margin-top: -.8em;
    flex: .8
}

#issue-title {
    font-size: .9em;

    @media(min-height: 1000px) {
        font-size: 1.05em !important;
    }

    font-weight:600;
    margin:0;
    text-transform: uppercase
}

#issue-subtitle {
    max-width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: .75em;

    @media(min-height: 1000px) {
        font-size: .9em !important;
    }
}

#see-more-link {
    flex: 0.8;
    margin: 0;
    align-self: center;
    justify-self: center;
    text-align: center;
    color: $primary;
    text-transform: uppercase;
    font-size: 0.9em;
    font-family: 'nunito_semibold';

    @media(min-height: 1000px) {
        font-size: 1.2em !important;
    }

    // font-weight:500;
    margin-inline-end:1em
}

.expandable-container {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
}
#issue-toggle-button {
    padding: 0 !important;
    margin: 0 45% !important;
    background-color: $cta-txt !important;
    width: 5.5em !important;
    height: 1.7em !important;
    background: url('../../assets/icons/svg/Issues/collapse.svg') no-repeat;
    background-position: center;
    box-shadow: inset 0px 10px 15px -20px #042926;
    position: absolute !important;
    border-radius: 0px 0px 5px 5px !important;
    border-color: transparent !important;
    outline: none;
    opacity: 1 !important;
}
#issue-toggle-button.not-issues {
   position: static !important;
   margin: 0 !important;
}

.btnMin[style*="top: 1em;"], .btnMin.not-issues.closed {
    background: url('../../assets/icons/svg/Issues/expand.svg') no-repeat !important;
    background-position: center !important;
}

#issue-text {
    flex: 4;
    margin-inline-start: 5px;
    margin-inline-end: 5px;
    margin-top: -6px;
}


@media (min-width:600px) and (max-width: 1100px) {
    #issue-title {
        display: none;
    }

    #issue-subtitle {
        max-width: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    #see-more-link {
        font-size: 0.9rem;

        @media(min-height: 1000px) {
            font-size: 1.05em !important;
        }
    }
}

@media (max-width: 600px) {

    #see-more-link {
        flex: 1;
        font-size: 0.9rem;

        @media(min-height: 1000px) {
            font-size: 1.05em !important;
        }
    }
}

@media (min-width:1920px) {
    .issue {
        height: 6.1em !important;
    }
}