.VegetationIndex_Container {
  .map-loader {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #02312ca6 0% 0% no-repeat padding-box;
    z-index: 1000;

    .Loading {
      height: 100% !important;
    }

    .loading-text {
      margin-inline-start: 8px;
      font-size: 1em;
      color: #73e4cc;
    }
  }

  ::-webkit-scrollbar {
    height: 7px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #256f68;
    border-radius: 10px;
    width: 2px !important;
    cursor: pointer !important;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #348c83;
  }

  ::-webkit-scrollbar {
    display: inline-block !important;
  }

  .temp-wrapper {
    display: flex;
    flex-direction: row-reverse;
    height: 100px;
    align-items: center;
    width: 100%;
    overflow-x: scroll;
    background-color: black;

    .item {
      flex: 0 0 auto;
      background-color: white;
      margin-inline: 5px;
      width: 120px;
      height: 80px;
    }
  }

  #Cardbody_VegetationMap {
    height: 100%;
    width: 100%;
    position: relative;
    padding: 0px;

    .satellite-weather-forecast-list.map-weather-cards-list {
      position: absolute;
      bottom: 10px;
      max-width: 52%;
      overflow-x: scroll;
      inset-inline-start: 18%;
    }
    .no-weather-data-disclaimer {
      position: absolute;
      display: flex;
      align-items: center;
      bottom: 15px;
      inset-inline-start: 25%;
      min-height: 35px;
      padding-inline: 15px;
    }
    #issues-container-view {
      .quarter-selection {
        min-width: 100px;
        height: 60px !important;
        border-radius: 5px;
        background-color: #03403a;
        border: none;
        outline: none !important;
        margin-bottom: 1em;
        color: #73e4cc;
        box-shadow: 3px 3px 6px #001a17a2 !important;

        .selected-quarter {
          font-size: calc(10px + (12 - 10) * ((100vw - 320px) / (2800 - 320)));
          text-wrap: nowrap;
        }

        .quarter-icon {
          svg {
            width: 1.8em;
            height: 1.4em;
            margin-block: 5px;
          }
        }
        &:hover {
          background-color: #73e4cc;
          border: 2px solid #c4fff9;
          .selected-quarter {
            color: #165f59 !important;
          }
        }
      }

      .quarter-selector {
        .triangle {
          position: absolute;
          width: 0;
          height: 0;
          top: 60px;
          inset-inline-start: 50%;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid #165f59;
          z-index: 1000 !important;
        }

        .quarter-picker-wrapper {
          position: absolute;
          top: 95%;
          inset-inline-start: 0;
        }
        margin-inline: 10px;
        position: relative;
      }
      .quarter-selector.selected {
        .quarter-selection {
          background-color: #73e4cc;
          border: 2px solid #c4fff9;
          .selected-quarter {
            color: #165f59 !important;
          }
        }
      }
    }

    .plot-info-wrapper {
      .info-icon {
        border: none !important;
        outline: none !important;
        position: absolute;
        inset-inline-end: 25px;
        bottom: 2.5rem;
        background-color: #03403a;
        border-radius: 5px;
        width: 42px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 25px;
        }

        &:hover {
          background-color: #73e4cc;

          &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            top: 1.2em;
            border-bottom: 7px solid transparent;
            border-top: 7px solid transparent;
            border-left: 7px solid #003a35cc;
            inset-inline-start: -10px;
          }

          .info-tooltip {
            background-color: #003a35cc;
            border-radius: 3px;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 5px;
            height: 30px;
            width: 100px;
            inset-inline-start: -108px;
            color: #73e4cc;
          }
        }

        .tooltip-wrapper {
          position: absolute;
        }
      }
    }

    .color-map-wrapper {
      .info-icon {
        inset-inline-start: 11px;
        bottom: 55px;
        width: 42px;
        height: 42px;
        border: none !important;
        outline: none !important;
        position: absolute;
        background-color: #03403a;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 25px;
        }

        &:hover {
          background-color: #73e4cc;

          svg {
            path {
              stroke: #03403a;
              stroke-width: 1;
            }
          }

          &::after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            top: 1.2em;
            border-bottom: 7px solid transparent;
            border-top: 7px solid transparent;
            border-right: 7px solid #003a35cc;
            inset-inline-start: 51px;
          }

          .info-tooltip {
            top: 5px;
            inset-inline-start: 57px;
            background-color: #003a35cc;
            border-radius: 3px;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            height: 30px;
            width: 100px;
            color: #73e4cc;
          }
        }

        .tooltip-wrapper {
          position: absolute;
        }
      }

      .color-map-container {
        position: absolute;
        min-width: 8.5rem;
        background-color: #03403a;
        border-radius: 5px;
        color: #c4fff9;
        bottom: 3.5rem;
        inset-inline-start: 0.8rem;
        padding-inline-start: 1rem;
        padding-block-start: 8px;

        .color-map-title {
          text-transform: uppercase;
          padding-block: 5px;
          font-size: 0.9em;

          .minimize-button {
            position: absolute;
            top: 6px;
            inset-inline-end: 7px;

            svg {
              width: 1.7em;
              height: 1.7em;
            }
          }
        }

        ul {
          padding-inline-start: 0 !important;
          padding-block-start: 8px;
          padding-inline-end: 20px;
          height: 12rem;
          overflow: scroll;

          .color-row {
            margin-block-end: 5px;
            display: flex;
            align-items: center;

            .color-value {
              min-width: 2.1em;
            }

            .seperator {
              color: #46aaa0;
              margin-inline-start: 5px;
              margin-inline-end: 8px;
              font-family: nunito_extraBold;
            }

            .color {
              display: inline-block;
              width: 14px;
              height: 13px;
              border: 1px solid #03403a;
              border-radius: 3px;
              margin-inline-end: 8px;
            }
          }
        }
      }
    }

    .filter-buttons-container {
      display: flex;
      flex-direction: row;
      gap: 10px;
      margin-bottom: 10px;

      .help-button-wrapper {
        margin-inline-start: 5px;
      }

      .index-selector:not(.selected):hover {
        &::before {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          top: 100%;
          border-right: 7px solid transparent;
          border-left: 7px solid transparent;
          border-bottom: 7px solid #003a35cc;
          inset-inline-start: 30%;
        }

        .index-tooltip {
          background-color: #003a35cc;
          border-radius: 3px;
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 115%;
          height: 30px;
          min-width: 100px;
          white-space: nowrap;
          padding-inline: 5px;
          inset-inline-start: 10% !important;
          color: #73e4cc;
        }
      }

      .drop-down-selector-container {
        width: 7.9em;
        height: 3em !important;
        border-radius: 5px;
        background-color: #03403a;
        border: none;
        outline: none !important;
        color: #73e4cc;
        box-shadow: 3px 3px 6px #001a17a2 !important;
      }

      .drop-down-selector-container:hover {
        background-color: #73e4cc;
        border: 2px solid #c4fff9;
        .current-choice {
          color: #165f59 !important;
        }

        .current-choice {
          &:hover::before {
            content: url("../../assets/icons/svg/sidebar/active/vegetationIndexactive.svg");
            padding-top: 5px;
            padding-inline-end: 5px;
          }
        }
      }

      .index-selector {
        margin-inline-start: 5px;

        .triangle {
          top: 100%;
          left: 40%;
        }

        .choices-list {
          top: 120%;
          inset-inline-start: -7% !important;
          width: 15em !important;
          max-height: 250px; /* Adjust this value as needed */
          overflow-y: auto;
        }

        .drop-down-selector-container {
          min-width: 7em !important;
          padding-inline: 5px !important;
          width: inherit;

          .current-choice {
            &::before {
              content: url("../../assets/icons/svg/sidebar/inactive/vegetationIndexInactive.svg");
              padding-top: 5px;
              padding-inline-end: 5px;
            }
          }
        }
      }

      .index-selector.ele {
        .current-choice {
          &::before {
            content: url("../../assets/icons/svg/VegetationIndex/elevDef.svg");
            padding-top: 5px;
            padding-inline-end: 5px;
          }
        }
      }

      .index-selector.selected {
        .drop-down-selector-container {
          background-color: #73e4cc;
          border: 2px solid #c4fff9;
          .current-choice {
            color: #165f59 !important;
          }

          .current-choice::before {
            content: url("../../assets/icons/svg/sidebar/active/vegetationIndexactive.svg");
            padding-top: 5px;
            padding-inline-end: 5px;
          }
        }
      }

      .ele-selected,
      .ele:hover {
        .current-choice::before {
          content: url("../../assets/icons/svg/VegetationIndex/elevHov.svg") !important;
          padding-top: 5px;
          padding-inline-end: 5px;
        }
      }
    }
  }

  #comparison-container {
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
    align-items: center;
    margin: 0px;
    padding: 0px;

    .BeforeMap {
      width: 50%;
      height: 100%;
      position: relative;
      border-radius: 5px 0px 0px 5px;
      margin-inline-end: 2.5px;
    }

    .AfterMap {
      width: 50%;
      height: 100%;
      position: relative;
      border-radius: 0px 5px 5px 0px;
      margin-inline-start: 2.5px;
    }
  }

  .Map-Button-Container .Farm-Button {
    &:hover {
      border-radius: 8px;
      cursor: pointer;
      background-color: #73e4cc;

      p {
        color: #03403a;
      }
    }

    height: 40px;
    background-color: #03403a;
    flex: 1 1;
    position: absolute;
    top: 14em;
    inset-inline-start: 0.4rem;
    right: 1;
    transition: all 0.2s ease-out;
    border-radius: 8px;
  }

  .SplitButton_Container {
    display: flex;

    .SplitViewSelected_Button {
      &:hover {
        cursor: pointer;
      }

      position: absolute;
      top: 11.2rem;
      bottom: 0px;
      inset-inline-start: 0.5rem;
      justify-content: center;
      align-items: center;
      width: 42px;
      height: 42px;
      border-radius: 5px;
      background-color: #73e4cc;
      display: flex;
      flex: 1;

      svg {
        width: 20px;
      }
    }

    .SplitView_Button {
      &:hover {
        background-color: #73e4cc;
        cursor: pointer;

        &::after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          top: 1.2em;
          border-bottom: 7px solid transparent;
          border-top: 7px solid transparent;
          border-right: 7px solid #003a35cc;
          inset-inline-start: 3rem;
        }
      }

      position: absolute;
      top: 11.2rem;
      bottom: 0px;
      inset-inline-start: 0.5rem;
      justify-content: center;
      align-items: center;
      width: 42px;
      height: 42px;
      border-radius: 5px;
      background-color: #03403a;
      display: flex;
      flex: 1;

      svg {
        width: 20px;
      }
    }

    .Split_Content {
      p {
        margin-top: 15px;
      }

      background-color: #003a35cc;
      border-radius: 3px;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 11.2rem;
      height: 30px;
      min-width: 100px;
      white-space: nowrap;
      padding-inline: 5px;
      inset-inline-start: 3.9rem;
    }

    .Link-Button,
    .Unlink-Button {
      top: 7rem;
      position: absolute;
      justify-content: center;
      align-items: center;
      width: 42px;
      height: 42px;
      border-radius: 5px;
      background-color: #03403a;
      display: flex;
      flex: 1 1;

      &:hover {
        background-color: #73e4cc;
      }

      svg {
        width: 21px;
      }
    }

    .Link-Button {
      inset-inline-start: 3.8rem;
      &:hover {
        // background-color: #73e4cc;

        &::before {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          top: 50px;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-bottom: 7px solid #003a35cc;
          inset-inline-start: 18px;
        }
        .link-tooltip {
          background-color: #003a35cc;
          border-radius: 3px;
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 5px;
          height: 30px;
          width: 100px;
          inset-inline-start: -1.5rem;
          color: #73e4cc;

          p {
            margin-block: 0 !important;
          }
        }
      }
    }

    // .Unlink-Button {
    //   inset-inline-start: 7.1rem;

    //   &:hover {
    //     background-color: #73e4cc;

    //     &::before {
    //       content: "";
    //       position: absolute;
    //       width: 0;
    //       height: 0;
    //       top: 50px;
    //       border-left: 7px solid transparent;
    //       border-right: 7px solid transparent;
    //       border-bottom: 7px solid #003a35cc;
    //       inset-inline-start: 18px;
    //     }

    //     .unlink-tooltip {
    //       background-color: #003a35cc;
    //       border-radius: 3px;
    //       flex: 1;
    //       display: flex;
    //       justify-content: center;
    //       align-items: center;
    //       position: absolute;
    //       top: 5px;
    //       height: 30px;
    //       width: 100px;
    //       inset-inline-start: -1.5rem;
    //       color: #73e4cc;

    //       p {
    //         margin-block: 0 !important;
    //       }
    //     }
    //   }

    //   svg {
    //     width: 24px;
    //   }
    // }
  }

  .mapboxgl-popup {
    margin-top: -20px;
  }

  .mapboxgl-popup-content {
    font-family: nunito_regular;
    padding: 0;
    max-width: 150px;
    min-width: 110px;
    min-height: 70px;
    border-radius: 10px;
  }

  .DayList-Container {
    flex-wrap: wrap;
    width: 97%;
    cursor: grab;
    overflow-x: scroll;

    ul {
      list-style-type: none;
      display: flex;
      padding-inline-start: 0;
      .slide {
        position: relative;
      }
      .previous-months-item {
        .card-wrapper {
          p {
            color: #73e4cc;
          }
        }
        &:hover {
          .card-wrapper {
            p {
              color: #205750;
            }
            svg {
              #Ellipse_1346 {
                fill: #205750;
              }
              #Path_3761 {
                stroke: #73e4cc;
              }
            }
          }
        }
      }
      .next-months-item {
        .card-wrapper {
          p {
            color: #73e4cc;
          }
        }
        &:hover {
          .card-wrapper {
            p {
              color: #205750;
            }
            svg {
              #Ellipse_1346 {
                fill: #205750;
              }
              #Path_3761 {
                stroke: #73e4cc;
              }
            }
          }
        }
      }
    }

    .DayListItem_Container {
      &:not(.cloudy):hover {
        background: #73e4cc 0% 0% no-repeat padding-box;
        border: 2px solid #c4fff9;
        border-radius: 5px;
        opacity: 1;
      }

      min-width: 100px;
      min-height: 60px;
      background: #1f847a 0% 0% no-repeat padding-box;
      border: 1px solid #46aaa0;
      border-radius: 5px;
      opacity: 1;
      margin-top: 8px;
      margin-inline-start: 10px;

      .card-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        position: relative;

        p {
          font-size: calc(10px + (12 - 10) * ((100vw - 320px) / (2800 - 320)));
          text-align: center;
          margin-bottom: 5px !important;
        }

        .calendar-icon {
          svg {
            width: 1.8em;
            height: 1.4em;
          }

          margin-block: 5px;
        }
      }

      .tooltip-wrapper {
        background: #003a35e6 0% 0% no-repeat padding-box;
        border-radius: 3px;
        width: 100px;

        .tooltiptext {
          background-color: transparent;
          width: 100%;
        }
      }
    }

    .DayListItemSelected_Container {
      background: #73e4cc 0% 0% no-repeat padding-box;
      border: 2px solid #c4fff9;
      font-family: "nunito_extraBold";
    }

    .cloudy {
      background: #205750be 0% 0% no-repeat padding-box;
      border: 1px solid #46aaa059;

      &:hover {
        border: 1px solid #46aaa0d9;
      }
    }
  }

  .issue_toggle_buttonSplit {
    padding: 0 !important;
    margin-inline-start: 46%;
    background-color: $primary !important;
    width: 65px !important;
    height: 18px !important;
    position: absolute !important;
    border-radius: 0px 0px 5px 5px !important;
    border-color: transparent !important;
    outline: none;
    box-shadow: none;
    opacity: 1 !important;
  }
}

@media (max-width: 878px) {
  .VegetationIndex_Container
    #Cardbody_VegetationMap
    #issues-container-view
    .quarter-selection {
    min-width: unset;
    width: 50px !important;
  }

  .DayListItem_Container {
    min-width: 70px !important;
  }
  .previous-months-item,
  .next-months-item {
    .DayListItem_Container {
      min-width: 50px !important;
    }
  }
  #comparison-container {
    flex-direction: column !important;

    .BeforeMap,
    .AfterMap {
      width: 100% !important;
    }

    .AfterMap {
      margin-top: 10px;
    }
  }
}

[dir="rtl"] {
  .VegetationIndex_Container {
    .color-map-wrapper {
      .info-icon {
        &::after {
          content: "";
          border-top: 7px solid transparent;
          border-right: none !important;
          border-left: 7px solid #003a35cc;
        }
      }
    }

    .SplitView_Button {
      &:hover {
        &::after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          top: 1.2em;
          border-bottom: 7px solid transparent;
          border-top: 7px solid transparent;
          border-right: none !important;
          border-left: 7px solid #003a35cc;
          inset-inline-start: 3rem;
        }
      }
    }

    .DayList-Container .DayListItem_Container .card-wrapper p {
      font-size: calc(12px + (13 - 12) * ((100vw - 320px) / (2800 - 320)));
    }

    #Cardbody_VegetationMap {
      .quarter-selection {
        font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
      }

      .quarter-selector {
        .triangle {
          top: 3.3em;
        }

        .quarter-picker-wrapper {
          position: absolute;
          top: 125%;
          inset-inline-start: -130%;
        }
      }
    }

    #Cardbody_VegetationMap
      .color-map-wrapper
      .color-map-container
      .color-map-title {
      font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
    }
  }

  .mapboxgl-popup-content {
    font-family: tajawal_regular;
  }
}

@media (max-width: 1100px) {
  #Cardbody_VegetationMap {
    .no-weather-data-disclaimer {
      inset-inline-start: 15% !important;
    }
    .satellite-weather-forecast-list.map-weather-cards-list {
      max-width: 40% !important;
      inset-inline-start: 18%;
    }
  }
}

@media (max-width: 770px) {
  #Cardbody_VegetationMap {
    .no-weather-data-disclaimer {
      inset-inline-start: 20% !important;
      width: 210px !important;
    }
    .satellite-weather-forecast-list.map-weather-cards-list {
      max-width: 40% !important;
      inset-inline-start: 18%;
    }
  }
}

@media (max-width: 650px) {
  #Cardbody_VegetationMap {
    .no-weather-data-disclaimer {
      inset-inline-start: 20% !important;
      width: 150px !important;
    }
    .satellite-weather-forecast-list.map-weather-cards-list {
      max-width: 35% !important;
      inset-inline-start: 18%;
    }
  }
}

@media (max-width: 570px) {
  #Cardbody_VegetationMap {
    .no-weather-data-disclaimer {
      inset-inline-start: 25% !important;
      width: 130px !important;
    }
    .DayInfo_Container {
      width: 11.5rem !important;
    }
    .satellite-weather-forecast-list.map-weather-cards-list {
      max-width: 30% !important;
      inset-inline-start: 30% !important;
    }
  }
}
@media (max-width: 350px) {
  .VegetationIndex_Container {
    margin-top: 40px;
    .chart-view {
      .calendar-button {
        width: 184px !important;
      }
    }
  }
}
@media (max-width: 570px) {
  #Cardbody_VegetationMap .DayInfo_Container {
    width: 15.9rem !important;
  }
  .DayInfo_Container .toggler-button-wrapper,
  .Modal_View_Day_Info .toggler-button-wrapper {
    position: absolute;
    inset-inline-end: 100%;
    top: 37%;
  }
  .expand-modal-icon,
  .modalBackground {
    display: none;
  }
}
