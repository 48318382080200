.todays-weather-body{
    padding:0!important;
}

.todays-weather-card{
    .card-header{
        padding:0 !important;
        padding-top: 1em !important;
        padding-inline: 1.25em !important
    }
}