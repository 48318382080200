.manual-meter-table-card {
    margin-bottom: 250px !important;

    // .card-body {
    //     overflow: scroll;
    // }

    .card-header {
        position: relative;

        textarea.form-control {
            height: calc(1.5em + 0.75rem + 2px) !important;
        }

        .table-buttons-container {
            position: absolute;
            right: 10px;
            top: 10px;
            display: flex;
            flex-direction: row;

            .add,
            .update {
                width: 30px !important;
                height: 30px !important;
                display: flex;
                justify-content: center;
                align-items: center;
                outline: none;
                border: none;
                margin-inline-end: 10px;
                background-color: #02312C;
                box-sizing: border-box;
            }

            .add:hover,
            .update:hover {
                background-color: #73E4CC;
                border: 2px solid #C4FFF9;
            }

            .add {
                svg {
                    display: flex;
                    flex-wrap: wrap;
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }

    .comments-wrapper {
        margin-top: 13px;
        position: relative;

        .input-group-prepend {
            top: inherit !important;
            bottom: 5px !important;
        }

        .input-suffix {
            position: absolute;
            right: 0;
            top: 40px;
            color: #46AAA0;
            font-size: 0.9em
        }

        form .input-group {
            margin-top: 50px !important;
        }
    }

    .search-empty-state {
        display: flex;
        height: 100%;
        align-items: center;

        .empty-data-state-container {
            width: 100%;
        }
    }

    .table-cell {
        textarea.form-control {
            height: 35px;
        }

        .input-wrapper {
            position: relative;

            .input-suffix {
                position: absolute;
                right: 0;
                top: 45px;
                color: #46AAA0;
                font-size: 0.9em
            }


        }

        .comments-wrapper {
            margin-top: 0 !important;
            position: relative;

            .input-suffix {
                position: absolute;
                right: 0;
                top: 80px;
                color: #46AAA0;
                font-size: 0.9em
            }

            form .input-group {
                margin-top: 40px !important;
            }
        }
    }

    .error {
        color: #F48585;
        margin-top: -20px;
        font-size: 0.9em;
    }

    .edit-action,
    .delete-action,
    .cancel-action,
    .submit-action {
        outline: none;
        border: none;
        background-color: #003A35;
        box-sizing: border-box;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-inline-end: 10px;
    }

    .edit-action:hover,
    .delete-action:hover,
    .cancel-action:hover,
    .submit-action:hover {
        background-color: #73E4CC;
        border: 2px solid #C4FFF9;
        box-sizing: border-box;
    }

    .submit-action:hover,
    .cancel-action:hover {
        svg {
            path {
                stroke: #0a4a45;
            }
        }
    }

    .edit-action:hover {
        svg path {
            d: path('M0,9.467V12H2.533L9.867,4.6,7.333,2.067,0,9.467Zm11.8-6.8a.644.644,0,0,0,0-.933L10.267.2a.644.644,0,0,0-.933,0l-1.2,1.2,2.533,2.533L11.8,2.667Z') !important;
            fill: #175852;
        }
    }

    .delete-action:hover {
        #Shape {
            fill: #003a35
        }

        rect {
            fill: #73e4cc
        }
    }

    .add-reading-link {
        color: #73E4CC;
        padding-block-start: 10px;

        &:hover {
            border-bottom: 2px solid #73E4CC;
        }
    }

    .table-view-wrapper {
        max-height: 90%;
        overflow-y: scroll;
    }

    // .edit-action {
    //     margin-inline-end: 10px;
    // }
}

[dir='rtl'] .manual-meter-table-card {
    .card-header {
        .table-buttons-container {
            right: inherit !important;
            left: 10px !important;
        }
    }
}