.Duplicate-Modal__title{
    color: $primary-txt;
}
.Duplicate-Modal__wrapper{
    @extend .modal-body-container;
    &__image{
      @extend .create-report-image;
    }
    &__header{
      @extend .empty-reports-header;
    }
    &__subheader{
      @extend .empty-reports-subheader;
    }
    &__input{
      @extend .input-container;
      width: 50%;
    }
 
}

