.pagination-row {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  
  .pagination-container {
    position: static;
    .pagination-item {
      font-size: 0.7rem;
    }
  }

  ul, p {
    margin-bottom: 0;
  }
}
