.Loading{
    display: flex;
    flex:1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;

    .actions-container {
        display: flex;
        gap: 16px;
        margin-top: 16px;
    }
}
.Loading-height{
    height: 100vh;
}