.default-button{
    background: none;
    border: none;
    outline: none;
    color: inherit;
}
.default-button:focus{
    outline: none;
}
.analysis-card__text{
    padding-inline-start: 1.25rem;
    margin-block: 1rem;
    transition: all 1s;
    &__button{
        @extend .default-button;
        &__title{
            padding-inline-start: 0.5rem;
        }
        &__arrow{
            visibility: hidden;
            padding-inline-start: 0.5rem;
            transition: visibility 0.4s ease-out;
        }
    }
    &__button:hover .analysis-card__text__button__arrow{
        visibility: visible;
    }
}
.analysis-card__message{  
    border-radius: 10px;
    margin-inline: auto;
    background: transparent linear-gradient(280deg, #46AAA0 0%, #73E4CC 66%, #C4FFF9 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    display: flex;
    margin-block: 1rem;
    width: 500px;
    color : #03403A;
    padding: 5px 10px 11px 8px;
    margin-block-end: 2rem;
    @media (min-width:2000px) {
        width: 581px;
    }
    @media (max-width:1500px) {
        width: 470px;
    }
    @media (max-width:1200px) {
        width: 270px;
    }
    @media (max-width:800px) {
        width: 90%;
    }
    &__text{
        margin-inline-start: 0.5rem;
        margin-block-start: 0.2rem;
        width: 100%;
        &__wrapper{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-block-end: 3px;
            &__title{
                letter-spacing: 0px;
                color: #003A35;
                text-transform: uppercase;
                opacity: 1;
                font: normal normal 800 0.75rem/1.25rem nunito_extraBold;
            }
            &__icon{
                @extend .default-button;
            }
        }

    }
}
[dir='rtl'] .analysis-card__message__text__wrapper__title{
    font: normal normal 800 0.75rem/1.25rem tajawal_extraBold;
}
