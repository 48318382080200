.ManagementRole_Container {
  .sensor-logs-card .card-header {
    display: block;
  }

  .FilterPanel-Title-View {
    margin-inline-start: 10px;
  }

  .proceed-button {
    height: 2.7em
  }

  .Panel {
    .proceed-button {
      margin-inline: auto;
      margin-top: auto;
      margin-bottom: 4.55rem;
      width: 150px;
    }
  }

  .users-panel-content-wrapper {
    padding: 10px;
    max-height: 80vh;
    overflow-y: scroll;

    .tables-container {
      width: 100%;

      .scroll {
        width: 100%;
      }
    }

    .drop-down-selector-container {
      width: 100% !important;
      background: none !important;
      border: none !important;
      border-bottom: 1px solid #6AB3A536;

      .choices-list {
        width: auto !important;
        box-shadow: 0px 3px 6px #02312c64;

        li {
          text-transform: lowercase;
        }
      }

      .triangle {
        display: none;
      }

      .current-choice {
        justify-content: flex-start !important;
        border-bottom: 2.3px solid #73E4CC !important;
      }

      .current-choice::after {
        margin-inline-start: auto;
        transform: rotate(90deg) scale(0.9) !important;
        content: url('../../assets/icons/svg/sidebar/inactive/ddlArrow.svg') !important;
        transition: transform 0.2s ease-out;
      }
    }

    .drop-down-selector-container.expanded {
      .current-choice::after {
        transform: rotate(-90deg) scale(0.9) !important;
        transition: transform 0.2s ease-out;
      }
    }

    .drop-down-selector-container.neuo {
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
    }

    .table-Container {
      padding-bottom: 0;

      .scroll {
        padding-bottom: 10px;
      }

      .table-title {
        background-color: #11766E;
      }
    }

    .add-user-fields-wrapper {
      padding-inline: 6%;
      margin-bottom: 20px;

      .mobile-input-key {
        text-transform: lowercase;
        color: $primary;
        bottom: 80% !important;
        font-size: 12px !important;
      }

      .error {
        .mobile-input-key {
          color: #F48585;
        }

        input {
          border-bottom-color: #F48585;
        }
      }

      .input-group {
        margin-bottom: 0.2rem !important;
      }
    }

    .fields-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));

      .Issues-Reminder-Message-Wrapper {
        flex: 0 0 50%;
      }
    }

    .add-new-farm {
      background-color: transparent !important;
      border: none !important;
      outline: none !important;
      color: #73E4CC;
      margin-top: 10px;
    }

    .add-new-farm:hover {
      text-decoration: underline;
    }
  }



  .modal-body-container {
    form {
      width: 75%;

      .input-group .form-control+span {
        left: 0 !important
      }
    }

    .delete-icon {
      img {
        width: 180px;
      }
    }

    .delete-header {
      color: #C4FFF9;
      text-transform: uppercase;
      margin-top: 20px;
    }

    .delete-subheader {
      margin-top: 5px;
      padding-inline: 50px;
      text-align: center;
    }

    .input-suffix {
      width: 75%;
      text-align: end;
      color: #46AAA0;
      margin-top: -20px;
      opacity: 0.7;
      font-size: 0.8em;
    }
  }

  .message-error {
    color: #F48585;
    font-size: 12px;
  }

  .key {
    color: #C4FFF9;
    text-transform: uppercase;
    //font-size: 0.9em;
  }

  .value {
    color: #46AAA0;
  }

  .btn.btn-primary {
    display: flex;
    align-items: center;
    width: 8em;
    height: 2.7em;
    border: none;
    border-radius: 8px;
    background-color: $primary;
    $width-cap: 83%;
    $hov: $primary;

    label {
      @extend .button;
      width: check($width-cap);
      cursor: pointer;
      margin: unset;
      font-size: 0.8rem;
      letter-spacing: 0.18px;
    }

    &::after {
      content: " ";
      width: 15%;
      height: 100%;
      margin-left: 2%;
      border-radius: 30px;
      background: url("../../assets/icons/svg/proceed.svg") no-repeat center;
      cursor: pointer;
    }

    &:hover {
      background-color: darken($hov, 25%);
      cursor: pointer;

      label {
        color: $primary-txt;
      }
    }

    &:disabled {
      background-color: $border;
      pointer-events: none;

      label {
        color: $sidebar;
      }
    }

    &:disabled:after {
      background: url("../../assets/icons/svg/proceed.svg") no-repeat center;
    }
  }

  .CTA-AR:disabled:after {
    background: url("../../assets/icons/svg/proceed.svg") no-repeat center !important;
  }

  .CTA-AR::after {
    background: $button-after-bg url("../../assets/icons/svg/proceed.svg") no-repeat center !important;
  }

  .users-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
    box-shadow: $dark-button-box-shadow;
    border-radius: 5px;
    height: 2.5em;
    position: relative;
    margin-bottom: 2em;

    .triangle {
      position: absolute;
      width: 0;
      height: 0;
      top: 2.7em;
      left: 5.8em;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 12px solid $light-container-background;
      z-index: 3000 !important;
    }

    .users-list-container {
      position: absolute;
      width: 13em;
      height: 6em;
      top: 3.5em;
      margin-bottom: 1em;
      z-index: 6000;

      .Checkbox-Container {
        .Checkbox-View {
          background-color: #196a63;
          box-shadow: 3px 3px 6px #02312c64;
        }
      }

      ul {
        border-radius: 5px;
        overflow-y: scroll;
        width: 100%;
        height: 100%;
        list-style: none;
        background-color: #196a63;
        padding-inline-start: 1em;
        padding-block: 1em;

        li {
          margin-bottom: 0.6em;
        }
      }
    }

    button {
      display: flex;
      outline: none;
      border-color: transparent;
      background-color: #165F59;
      align-items: center;
      color: #73e4cc;
    }

    .users-button {
      display: flex;
      flex: 4;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      height: 100%;
      font-size: 0.8em;

      span {
        margin-inline-start: 0.7em;
      }
    }

    .clear-button {
      height: 100%;
      display: flex;
      flex: 1;
      justify-content: center;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      position: relative;
      border-left: 1px solid #46aaa02c;
    }
  }

  .Issues-Reminder-Message-Wrapper {
    .input-group-prepend {
      white-space: nowrap;
      vertical-align: middle;
      margin-top: 0.1rem;
    }
  }

  .table-cell {
    .buttons-group {
      display: flex;
      justify-content: space-evenly;

      .confirm,
      .view-reminder-button {
        border-radius: 5px !important;
        padding: 0.15rem 0.25rem !important;
        background: #003a35 0% 0% no-repeat padding-box;
        box-shadow: -3px -3px 6px 0px #7afaed33, 3px 3px 7px 0px #000000a6;
        border-radius: 5px;
        border: 0;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        margin-inline: 0.5rem;
        height: 1.8em;
        width: 1.9em;
        justify-content: center;
        align-items: center;

      }

      .edit {
        svg {
          width: 0.8em;
          height: 0.8em;
        }
      }

      .delete {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 1em;
          height: 1em;
        }

        &:hover {
          background: #73E4CC 0% 0% no-repeat padding-box !important;
          border: 2px solid #C4FFF9 !important;

          svg {
            path {
              fill: #003a35;
            }

            rect {
              fill: #73e4cc;
            }
          }
        }
      }

      .suspend {
        svg {
          width: 1.2em;
          height: 1.2em;
        }
      }

      .reactivate {
        svg {
          width: 1.2em;
          height: 1.2em;
        }
      }

      .view-reminder-button:not(.delete):hover {
        background: #73e4cc 0% 0% no-repeat padding-box;

        svg {
          path {
            fill: #0a4a45;
          }

        }
      }

      .confirm:hover {
        background: #73e4cc 0% 0% no-repeat padding-box;

        svg {
          path {
            stroke: #0a4a45;
          }

        }
      }
    }
  }

  .Pending_Wrapper {
    .title {
      color: $primary-txt;
      text-transform: uppercase;
      font-size: 0.9em;
      margin-top: 20px;
    }

    .content {
      font-size: 0.9em;
      color: #46aaa0;
      margin-top: -10px;
      margin-bottom: 4rem;
    }
  }

  .PanelView_Button {
    display: flex;
    justify-content: center;
    margin-top: 10%;

    .btn.btn-primary {
      display: flex;
      align-items: center;
      width: 10rem;
      // max-width: 12rem;
      height: 2.7em;
      border: none;
      border-radius: 8px;
      background-color: #73e4cc;
    }
  }

  .PhoneInput {
    display: flex;
    align-items: center;

    input {
      position: relative !important;
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      outline: none !important;
      width: 100% !important;
      color: #c4fff9;
      font-size: 14px !important;
      padding: 8px 0 !important;
      box-sizing: border-box !important;
      border-radius: 0 !important;
      border-bottom: 2px solid;
      border-bottom-color: #c4fff92e;
      background: transparent !important;
      background-color: transparent !important;
      z-index: 2;
    }

    input:focus {
      border-bottom-color: $primary;
    }

    span {
      padding-inline-start: 0.8em;
      color: rgb(115, 228, 204);
      padding-bottom: 0px;
      display: inline-block !important;
      position: absolute !important;
      font-size: 14px !important;
      z-index: 1 !important;
      bottom: 16% !important;
      // left: 10% !important;
      transition: 0.5s !important;
    }

    [dir=ltr] span {
      left: 10% !important;
    }
  }

  .user-card-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .user-status-wrapper {
    display: flex;
    justify-content: space-between;
    width: 44em;

    &.user-status--fit-width {
      margin-inline: auto;
      justify-content: space-between;
      max-width: 100%;
      margin-top: 1rem;
    }

    .user-border {
      width: 0px;
      height: 53px;
      border: 1px solid #46AAA07D;
      opacity: 1;
    }

    .user-number-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 130px;

      &--responsive {
        @media (max-width: 1100px) and (min-width: 1066px) {
          width: 125px !important;
        }
      }

      @media (max-width: 600px) {
        width: 108px !important;
      }

      height: 53px;
      max-width: 90%;
      text-transform: lowercase;
      border-radius: 5px;

      & .number {
        text-align: center;
        letter-spacing: 0px;
        font-family: "nunito_extrabold";
        font-size: 1.1rem;
      }

      & .text {
        text-align: center;
        letter-spacing: 0px;
      }

      &.total-users {
        background: #C4FFF9 0% 0% no-repeat padding-box;
        color: #165F59;
      }

      &.active-users {
        background: #4B6FFF 0% 0% no-repeat padding-box;
        color: #C4FFF9;
      }

      &.available-users {
        background: #FFC977 0% 0% no-repeat padding-box;
        color: #165F59;
      }

      &.inactive-users {
        background: #165F59 0% 0% no-repeat padding-box;
        color: #C4FFF9;
      }
    }
  }
}

[dir="rtl"] .ManagementRole_Container {
  .user-number-box {
    height: 61px;

    & .number {
      font-family: "tajawal_extraBold";

    }
  }

  .user-border {
    height: 61px;
  }
}


@media (max-width: 850px) {
  .ManagementRole_Container {
    .Panel {
      .proceed-button {
        margin-bottom: 5rem;
      }
    }
  }
}