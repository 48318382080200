.empty-data-state-container{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-top:2%;
    background-color: #03272300;
    border-radius: 5px;
    padding:4em;
    .icon{
      margin-bottom:2%
    }
    .title{
      text-transform:uppercase;
      color:$primary-txt;
      font-size: 1em;
      margin-bottom: 0.6em;
    }
    .subtitle{
      text-align: center;
      color:$secondary-txt;
      font-size: 1em;
      width: 50%;
      min-height: 50px;
    }
    .content{
      color: $primary-txt;
    }
}