.date-time-picker-wrapper {
    position: relative;

    .date-time-button {
        background-color: transparent;
        border: none;
        outline: none !important;
        color: #73E4CC;
        padding-block: 7px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .clock-icon {
            display: flex;

            svg {
                transform: scale(0.75);

                #clock {
                    fill: #73E4CC;
                }
            }
        }

        &:hover {
            background: #73E4CC 0% 0% no-repeat padding-box;
            border: 2px solid #C4FFF9 !important;
            border-radius: 5px;
            color: #0A4A45;

            .clock-icon {
                svg {
                    #clock {
                        fill: #0A4A45;
                    }
                }
            }
        }
    }

    .triangle {
        position: absolute;
        top: 33px;
        inset-inline-start: 40%;
    }

    .date-time-content {
        position: fixed;
        width: 18em;
        background-color: $light-container-background;
        z-index: 1000;
        // top: 40px;
    }

    .date-picker-container {
        background-color: #196A63;
    }

    .Timer-Container {
        align-items: center;

        .calendar-button {
            display: none;
        }
    }

    .set-dates-button {
        background: #73E4CC 0% 0% no-repeat padding-box;
        border-radius: 5px;
        color: #165F59;
        letter-spacing: 0.16px;
        padding-inline: 1.5em;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-inline: auto;
        outline: none;
        border: none;
        margin-block-end: 1.5em;
        padding-block: 0.3em;
    }

    .set-dates-button::after {
        content: url('../../assets/icons/svg/calendar/proceed_EN.svg');
        // width: 60px;
        // height: 30px;
        background-repeat: no-repeat;
        padding-inline: 0;
        margin-inline-start: 0.7em;
        padding-block-start: 3px;
    }

    .Timer-Content {
        margin-bottom: 15px;
    }

    .tabs-labels-container {
        padding-block: 15px;
        display: flex;
        padding-inline-start: 20px;
        border-bottom: 1px solid #73E4CC33;

        .tab {
            color: #46AAA0;
            margin-inline-end: 15px;
            text-transform: capitalize;
        }

        .selected-tab {
            color: #73E4CC;
            text-transform: uppercase;
            border-bottom: 1px solid #73E4CC;
        }
    }
}

[dir='rtl'] .set-dates-button::after {
    content: url('../../assets/icons/svg/calendar/proceed_AR.svg') !important;
}