$body-bg:     $main_layer !important;
$body-color:  $secondary-txt !important;



body{
  font-family: nunito_semibold !important;
  [dir="rtl"] & *{
    font-family: tajawal_medium;
    letter-spacing: 0;
    .c-sidebar .c-sidebar-nav-item .c-active, .c-sidebar .c-sidebar-nav-item:hover, .c-sidebar .c-sidebar-nav-item:hover .c-sidebar-nav-link{
      font-family: tajawal_medium;
    }
  }
}

.regular-font {
    font-family: nunito_regular !important;
    [dir="rtl"] & {
      font-family: tajawal_regular !important;
    }
}

.semibold-font {
  font-family: nunito_semibold;
  [dir="rtl"] & {
    font-family: tajawal_medium;
  }
}

.bold-font {
  font-family: nunito_bold;
  [dir="rtl"] & {
    font-family: tajawal_extraBold;
  }
}

// [dir="rtl"]{
//   body, .heading, .card-header{
//     font-family: tajawal_medium !important;
//   }
// }
// [dir="ltr"]
// {
//   body {
//   font-family: nunito_semibold !important;
//   }
// }


.c-app {
  background-color: $body-bg;
  color: $body-color;
  --elevation-base-color: 0 !important
  }
  
  .fade{
    //margin-top: -0.2rem !important;
    margin-top: 0.5rem !important;
    //margin-top: 1rem !important;
  }
  .c-wrapper:not(.c-wrapper-fluid){
    .c-body{
      padding-left: 1.2vw;
      margin-right: 1.2vw;
      margin-top: -0.8rem;
    }
  }
  
@media (min-width:1680px){
  .c-wrapper:not(.c-wrapper-fluid){
    .c-body{
      padding-left: 1.2vw;
      margin-right: 1.2vw;
      margin-top: -0.8rem;
    }
  }
  .fade{
    //margin-top: -0.2rem !important;
    margin-top: 0.5rem !important;
    //margin-top: 1rem !important;
    }
}
  .container-fluid{
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
