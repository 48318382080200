.toggler-button-wrapper {
  width: 22px;
  height: 46px;
 // background-color: #165f59 0% 0% no-repeat padding-box;
  background-color: #165F59 !important;
  background: url('../../assets/icons/svg/sidebar/expand.svg') no-repeat;
  border-radius: 5px 0px 0px 5px;
  opacity: 0.95;
  outline: none !important;
  border: none;
  background-position: center !important;

  &.expanded {
    background: url('../../assets/icons/svg/sidebar/collapse.svg') no-repeat;
  }

  [dir="rtl"] & {
    background: url('../../assets/icons/svg/sidebar/collapse.svg') no-repeat;

    &.expanded {
      background: url('../../assets/icons/svg/sidebar/expand.svg') no-repeat;
    }
  }
}

[dir=rtl] .toggler-button-wrapper {
  border-radius: 0px 5px 5px 0px !important;
}
