#thirdly_button {
  padding: 10px 18px;
  border: 1px solid $secondary-txt;
  border-radius: 5px;
  color: $primary !important;

  &:hover {
    background: $secondary-txt;
    border: 1px solid $primary-txt;
    color: $primary-txt !important;
  }

  @media (max-width: 600px) {
    padding: 0 10px;
    height: 30px;
  }

}
