#graph-widget-card-header{
    padding-inline-end: 1% !important;
    display:flex !important;
    flex-direction:row !important;
    justify-content:space-between !important;
    padding-bottom: 0% !important;
    height: 30%;
    .right-side-container {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .title-tooltip-container {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }
}
#graph-widget-icon{
    display:flex !important;
    // margin-top: -3% !important;
    margin-inline-end: 3% !important;
    height: 2.5em !important;
    width:2.5em !important;
    background-image: url('../../assets/icons/svg/Cbg.svg');
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover;
    align-items: center !important;
    justify-content: center !important;
    // flex:0.16;
    img{
        display:flex !important;
        height: 1.7em !important;
        width:1.7em !important;
        align-self: center !important;
        justify-self: center !important;
    }
}
#graph-widget-card-header-div{
    min-width: fit-content;
}
#graph-widget-card-footer{
    // display:flex !important;
    // align-items: baseline !important;
    // justify-content: center !important;
    // padding-top: 0% !important;
    .px-1{
        font-size: 25% !important;
    }
}
.graph-widget-card-footer-text{
    color:#46AAA0;
    font-size:0.75em
}
#graph-widget-card-body-text{
    font-size:1.2em !important;
    font-weight:bold !important;
}
#graph-widget-card-body{
    text-align:center !important;
    padding-top:0 !important;
    padding-bottom:2em !important;
    padding-inline:0.7em !important;
    justify-content: center !important;
}
#graph-widget-card-header-title{
    @extend .gadget-title;
    //font-size:0.95em;
}
[dir='rtl'] #graph-widget-card-header-title{
    font-size:calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
    font-weight: 400;
}
#graph-widget-card-header-subtitle{
    @extend .subtitle;
    //font-size:0.9em;
    font-size: calc(11px + (12 - 11) * ((100vw - 320px) / (2800 - 320)));
    text-transform: capitalize !important;
}
@media (max-width:560px)
{
    #graph-widget-icon{
        // margin-top: -1% !important;
        height:4.5em !important;
        width:4.5em !important
    }
    #graph-widget-card-header{
        padding-inline-end: 1% !important;
        padding-bottom: 0% !important;
    }
    .graph-widget-card-footer-text{
        font-size:1.2em
    }
    #graph-widget-card-footer{
        .px-1{
            font-size: 45% !important;
        }
    }
}
[dir='rtl']{
    #graph-widget-card-header-subtitle {
        font-size: calc(11px + (13 - 11) * ((100vw - 320px) / (2800 - 320)));
        font-weight: 600;
    }
}