.screen-navigator-button {
    position: fixed;
    bottom: 2em;
    inset-inline-end: 2em;
    height: 2.5em;
    width: 2.5em;
    border: none;
    background-color: transparent;
    outline: none !important;
    z-index: 1000;
    padding: 0;
}

.screen-navigator-icon {
    svg {
        width: 2.5em;
        height: 2.5em
    }
}