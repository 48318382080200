.Add-New-Farm-Modal__wrapper {
  @extend .modal-body-container;

  &__image {
    @extend .create-report-image;
  }

  &__header {
    @extend .empty-reports-header;
  }

  &__subheader {
    @extend .empty-reports-subheader;
  }

  &__input {
    @extend .input-container;
    width: 50%;
  }

  .proceed-button {
    width: fit-content;
    max-width: 90%;
    padding-block: 1rem;
    display: flex;
    align-items: center;
    padding-inline: 1rem !important;
  }

  .input-group .form-control+span {
    inset-inline-start: 30px !important;
    margin-bottom: 2px;
  }

  .input-group-prepend {
    .input-group-text {
      padding: 0;
    }
  }

  .mb-4 {
    margin-bottom: 8px !important;
  }

  .farm-name-validation {
    color: #73E4CC;
    font-size: 0.8rem;
  }

  .failure {
    color: #F48585;
  }
}
.modalContainer{
  @media (orientation : landscape) {
    max-height: 100vh;
    overflow: scroll;
  }
}
.Add-New-Farm .modalBackground .modalContainer {
  @media (min-width: 800px) and (max-width: 1100px) {
    width: 55%;
  }
}