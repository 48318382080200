.water-volume-card-footer{
    display:flex;
    flex-direction:row; 
    font-size:.8em;
    .content-container{
        display:flex;
        flex-direction:column;
        .title{
            font-size: inherit;
            text-transform: initial;
            font-weight: initial;
        }
        .reading-date-container{
            margin-inline: 0;
            font-size: 0.9em;
            justify-content: flex-start;
            @media (max-width:1042px){
                font-size: 0.7em;
            }
        }
        .reading-date-container::before{
            @media (max-width:1042px){
                transform: scale(0.8,0.8);
            }
        }
    }
    .end{
        align-self: flex-end;
        text-align: start;
    }
}