.delete-modal-body-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .delete-icon {
      img {
        width: 180px;
      }
    }
    .delete-header {
      color: $primary-txt;
      text-transform: uppercase;
      margin-top: 20px;
      max-width: 90%;
      text-align: center;
    }
    .delete-subheader {
      margin-top: 5px;
      padding-inline: 50px;
      text-align: center;
      @media (min-width: 1024px) {
        width: 66%;
      }
    }
    .proceed-button{
      padding-inline: 1rem;
      margin-top: 30px;
      height: auto;
      padding: 10px 20px;
    }
   
}
