.Panel-Container-View {
    width: 100vw;
    min-height: 100vh;
    position: fixed;
    margin: 0;
    padding: 0;
    background-color: rgba(2, 34, 31, 0.85);
    top: 0;
    left: 0;
    z-index: 1050;

    .Panel{
        .closeButton{
            width:2rem;
            height:2rem;
            border:0;
            background-image:  url('../../assets/icons/svg/constants/close_btn.svg');
            background-size: contain;
            background-color: transparent;
            background-repeat: no-repeat !important;
            outline: none !important;
            position: absolute;
            inset-inline-end: 15px;
        }
    }

    [dir=ltr].Panel {
        transform: none;
        width: 30vw;
        display: flex;
        float: right;
        min-height: 100vh;
        transition: all 0.4s ease-out;
        background-color: $sidebar;
        flex-direction: column;

        .Panel-Header {
            height: 3em;
            display: flex;
            margin-top: 10px;
            position: relative;

      
            // .c-sidebar-close {
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            //     background-color: #1D554F;
            //     border-radius: 50px;
            //     width: 2.2em;
            //     height: 2.2em;
            //     // margin-top: 1.8em;
            //     margin-inline-end: 1.2em;


            //     .c-icon {
            //         flex: 1;
            //         color: #51A190;
            //     }
            // }
        }
    }

    [dir=rtl].Panel {
        transform: none;
        width: 30vw;
        display: flex;
        float: left;
        min-height: 100vh;
        transition: all 0.4s ease-out;
        background-color: $sidebar;
        flex-direction: column;

        .Panel-Header {
            height: 3em;
            display: flex;
            margin-top: 10px;
            position: relative;

            // .c-sidebar-close {
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            //     background-color: #1D554F;
            //     border-radius: 50px;
            //     width: 2.2em;
            //     height: 2.2em;
            //     // margin-top: 1.8em;
            //     margin-inline-end: 1.2em;

            //     .c-icon {
            //         color: #51A190;
            //     }
            // }
        }
    }

    .mobile {
        width: 100vw;
    }

    .small {
        width: 45vw;
    }

    .regular {
        width: 32vw;
    }
}

.FilterPanel-Title-View {
    margin-inline-start: 1.5em;
    color: #C4FFF9;
    text-transform: uppercase;
    //font-size: 0.9em;
    font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
}

@media (max-width: 850px) {
    .Panel {
        width: 100vw !important;
    }
}