.select-option{
    height:2em !important;
    position:relative;
    margin-inline-end: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline-start: 10px;
    width: 28px;
    flex-wrap: wrap;
    background: $container-background 0% 0% no-repeat padding-box;
    $lower-shadow: 4px 4px 8px darken($container-background, 3%);
    $upper-shadow: -3px -3px 6px lighten($container-background, 4%);
    -webkit-appearance: none !important;
    -webkit-box-shadow: $upper-shadow, $lower-shadow !important;
    border-radius: 5px;
    outline: none;
    .triangle{
        position: absolute;
        width: 0; 
        height: 0; 
        top: 2em;
        left: 0.5em;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;    
        border-bottom: 10px solid $sidebar;
        z-index: 1000;
    }
    .current-choice{
        display:flex;
        justify-content:center;
        align-items:center;
        width: 28px;
        height: 100%;
        color: $primary;
        border-radius: 5px;
        padding-inline-start: 15%;
    }
    .current-choice--hover {
        background-color: $primary;
        border: 2px solid $primary-txt;
        transition: 0.3s ease-out;
    }
    .choices-list{
        position:absolute;
        background-color: $sidebar;
        border-radius: 5px;
        list-style-type: none;
        padding:0;
        z-index: 100;
        top: 2.5em;
        left: -6.5em;
        width: 9.5em;
        color: #73e4cc;
        li{
            padding-inline:1em;
            padding-block: 0.5em;
            position: relative;
            font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
            .border{
                display: none;
            }
           
        }
        svg{
            margin-top: 3px;
        }
        .choice-on-focus{
            color: $primary-txt;
            .border{
                display: block;
                position: absolute;
                left:0;
                width:0.2em;
                height: 1.7em;
                background: $primary 0% 0% no-repeat padding-box;
                border-radius: 0px 2px 2px 0px;
            }
            
        }
    }
    .list-item{
        display: inline-flex;
        gap: 4px;
        align-items: center;
    }
}
[dir=ltr] .select-option{
    .choices-list{
        &:has(.choice-on-focus){
            left: -6.5em;
            width: 9.5em;
        }
        .choice-on-focus{
            text-transform: uppercase;
        }
    }

}
.select-option--top-mode{
    .choices-list{
        top: -8.5em !important;
        left: -1em;
    }
    .triangle{ 
        top: -1em;
        border-top: 10px solid $sidebar !important;
        border-bottom: none !important;
        left:0.7em !important;
    }
}
[dir='rtl'] .select-option{
    .choices-list{
        top: 2.5em;
        right: -5.5em;
        left: 0em;
        width: 8.5em;
    }
}