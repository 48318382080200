/* Tooltip container */
.tooltip-wrapper {
  position: relative;
  display: inline-block;
}
[dir='rtl'] .tooltip-wrapper .tooltiptext{
    width: 100px;
    right: 25%;
    left: 0%;
    .triangle {
        right: 43%;
        left: 0%;
      }
}
/* Tooltip text */
.tooltip-wrapper .tooltiptext {
  visibility: visible;
  width: 70px;
  background: $main_layer 0% 0% no-repeat padding-box;
  color: $primary-txt;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;
  top: 131%;
  margin-inline-start: -13px;
  position: absolute;
  z-index: 100;
  font-size: 0.8em;
  .triangle {
      position: absolute;
      width: 0;
      height: 0;
      top: -30%;
      left: 44%;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 10px solid $main_layer;
      z-index: 100;
    }
}
[dir='rtl'] .tooltip-wrapper .tooltiptext{
  margin-inline-start: -24px;
}


@media(max-width:1330px) {
  [dir='rtl'] .header-buttons-container .tooltip-wrapper:nth-child(3) .tooltiptext{
      width: 70px; 
      margin-inline-start: -13px;
  } 

}