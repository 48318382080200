.npk-graph-container{
    display: flex;
    flex-direction: row;
    align-items:center;
    .graph{
        width:85%
    }
    .type-title{
        margin-inline:10px;
        width:100px;
    }
}