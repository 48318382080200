.user-logs-table {
    .card-header {
        span{
            min-width: 100px;
        }
        .sorting-and-filtering-container .filter-view {
            width: calc(100% + 6rem);
            margin-inline-start: -6rem;
        }
    }

    .view-action {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-inline-start: 10px;
        width: 28px;
        flex-wrap: wrap;
        height: 30px;
        background: #003A35 0% 0% no-repeat padding-box;
        //box-shadow: $dark-button-box-shadow !important;
        $lower-shadow: 4px 4px 8px darken($container-background, 3%);
        $upper-shadow: -3px -3px 6px lighten($container-background, 4%);
        -webkit-appearance: none !important;
        box-shadow: $upper-shadow, $lower-shadow  !important;
        -webkit-box-shadow: $upper-shadow, $lower-shadow  !important;
        border-radius: 5px;
        outline: none;
    }

    .view-action:hover {
        background-color: $primary;
        border: 2px solid $primary-txt;
        transition: 0.3s ease-out;
    }
}
[dir='rtl']{
    .user-logs-table .card-header span{
        min-width: 110px;
    }
}