.Assign-Modal-Title{
    color: $primary-txt;
}
.Assign-Modal-Content-Title{
    color: $primary-txt;
}
.Assign-Seperator{
    border-top: 1px solid;
    width: 88%;
    opacity: 1;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.Assign-list{
    list-style-type: none;
    padding-inline-start: 0;
    .graph-card-container{
        max-width: 90%;
    }
}
.Assign-Modal-Content-Body{
    .users-selector {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 15em;
        -webkit-appearance: none !important;
        box-shadow: $dark-button-box-shadow  !important;
        -webkit-box-shadow: $dark-button-box-shadow  !important;
        border-radius: 5px;
        height: 2.5em;
        position: relative;
        margin-bottom: 2em;
    
        .triangle {
            position: absolute;
            width: 0;
            height: 0;
            top: 2.7em;
            left: 5.8em;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-bottom: 12px solid $light-container-background;
            z-index: 3000 !important;
        }
    
        .users-list-container {
            position: absolute;
            width: 13em;
            height: 15em;
            top: 3.5em;
            margin-bottom: 1em;
            z-index: 6000;
    
            ul {
                border-radius: 5px;
                overflow-y: scroll;
                width: 100%;
                height: 100%;
                list-style: none;
                background-color: #196A63;
                padding-inline-start: 1em;
                padding-block: 1em;
    
                li {
                    margin-bottom: 0.6em;
                }
            }
        }
    
        button {
            display: flex;
            outline: none;
            border-color: transparent;
            background-color: #0D4944;
            align-items: center;
            color: #73E4CC;
        }
        button:focus{
            outline: none;
        }
        .users-button {
            display: flex;
            flex: 4;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            height: 100%;
            .users-selector__number{
                width: max-content;
            }
            span {
                margin-inline-start: 0.7em;
            }
        }
    
        .clear-button {
            height: 100%;
            display: flex;
            flex: 1;
            justify-content: center;
            position: relative;
            outline: none;
            border-inline-start: 1px solid #46AAA02C; 
            border-start-end-radius: 5px;
            border-end-end-radius: 5px;
        }
    }
    #users-list-container .Checkbox-Container .Checkbox-View {
        -webkit-appearance: none;
        background-color: #196A63 !important;
        box-shadow: 3px 3px 6px #02312C64 !important;
        -webkit-box-shadow: 3px 3px 6px #02312C64 !important;
    }

}