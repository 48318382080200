.c-switch-input:checked ~ .c-switch-slider::before {
    transform: translateX(22px) !important;
}

.c-switch[class*="-3d"] .c-switch-slider::before {
    top: -1px !important;
    left: -1px !important;
    width: 15px !important;
    height: 15px !important;
    border: 0 !important;
    border-radius: 50em !important;
    box-shadow: 0 2px 5px rgb(0 0 21 / 30%) !important;
}

.c-switch-slider {
    position: relative;
    display: block;
    height: 15px !important;
    cursor: pointer;
    border: 0px solid !important;
    transition: 0.15s ease-out;
    border-radius: 0.25rem;
    background-color:$slider_bg !important;
    border-color: $slider_bg !important;
}

.c-switch-3d-primary .c-switch-input:checked + .c-switch-slider {
    background-color: $primary !important;
}