.sensor-logs-card{
    min-height: 40em !important;
    .card-header{
        padding-bottom:0 !important;
        display: flex;
        flex-direction: row;
    } 
    .card-body{
        padding-top:1em !important;
    }  
}