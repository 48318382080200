.forecast-banner-container {
    display: flex;
    align-items: center;
    padding-inline-start: 0.5em;
    margin-bottom: 0.5em;
    background: transparent linear-gradient(91deg, #228D82 0%, #09635A 69%, #055D52 100%) 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    width: 100%;
    min-height: 2.5em;
    z-index: 2000;
    //font-size: 0.9em;
    font-size: calc(12px + (12 - 10) * ((100vw - 320px) / (2800 - 320)));
    padding-block: 0.5em;
    .warning-icon {
        svg {
            width: 1.5em;
            height: 1.5em;
        }
    }

    .forecast-alert-header {
        color: #C4FFF9;
        text-transform: uppercase;
        margin-inline: 0.5em;
        font-weight: 800;
    }

    .alert-body {
        color: #C4FFF9;
        padding-inline-end: 0.3em;
    }

    .issues-link {
        color: #73E4CC
    }

    .issues-link:hover::after {
        content: url('../../assets/icons/svg/constants/hoverArrowEn.svg');
        width: 60px;
        height: 30px;
        margin-top: 1em;
        padding-inline: 0.3em;
    }
    // &.shrinked{
    //     .forecast-alert-header{
    //         color:red
    //     }
    // }
}

[dir=rtl] {
        .issues-link:hover::after {
        content: url('../../assets/icons/svg/constants/hoverArrowAr.svg');
        width: 60px;
        height: 30px;
        margin-top: 1em;
        padding-inline: 0.3em;
    }
    .forecast-banner-container .forecast-alert-header{
        font-weight: 400;
    }
}

@media (max-width:1260px) {
    .forecast-banner-container {
        font-size: 0.7em !important;
    }
}