.form-check-input{
    background-color: $dark-button-background !important;
}

.radio-button{
    border-style:none;
    outline:none !important;
    width:20px;
    height:20px;
    border-radius:50%;
    background-color:$dark-button-background;
    //box-shadow: -2px -1px 6px #338E875D;
    $lower-shadow: 3px 4px 8px darken($dark-button-background, 4.3%);
    $upper-shadow: -3px -3px 6px lighten($dark-button-background, 5%);
    box-shadow: $upper-shadow, $lower-shadow  !important;
    -webkit-box-shadow: $upper-shadow, $lower-shadow  !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:0 !important;
}

.radio-button-selection{
    width:10px;
    height:10px;
    border-radius:50%;
    background-color:$primary;
}

.radio-button-element{
    display:flex;
    align-items:center;
    justify-content:center;
    margin-bottom: 2%;
}