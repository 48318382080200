:root {
  --username-border: #C4FFF92E;
  --username-font: #73E4CC;
  --password-border: #C4FFF92E;
  --password-font: #73E4CC;
}

.config {
  // background-image: url("../../assets/icons/png/AuthBg.png");
  background: url("../../assets/icons/png/AuthBg.png") no-repeat fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  max-width: 120% !important;
  padding: 0 !important;
  .contact-us-wrapper{
    padding-top: 1rem;
    align-self: baseline;
    position: absolute;
    inset-inline-start: 2rem;
  }
  .username-error,
  .password-error {
    color: #F48585;
  }

  .username {
    .form-control:not(:placeholder-shown)~span {
      color: var(--username-font);
      bottom: 80% !important;
      font-size: 12px !important;
    }

    .form-control,
    .form-control:focus {
      border-bottom-color: var(--username-border);
    }
  }

  .password {
    .form-control:not(:placeholder-shown)~span {
      color: var(--password-font);
      bottom: 80% !important;
      font-size: 12px !important;
    }

    .form-control,
    .form-control:focus {
      border-bottom-color: var(--password-border);
    }
  }

  .card {
    background-color: transparent;
  }

  h1 {
    // color: $primary-txt;
    @extend .heading;
    text-align: center;
    padding-top: 1em;
  }

  .bg-primary {
    p {
      // color: $primary;
      @extend .link;
      text-align: center;
      align-self: center;
      justify-self: center;
      width: 20em;
      padding-bottom: 10%;
      margin-inline-start: 12%;
      text-transform: unset;
    }
  }

  .logo {
    // text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      width: 15em !important;
      height: 15em !important;
    }

    p {
      // color: $primary;
      text-align: center;
      padding-bottom: 10%;
      @extend .link;
      font-size: calc(12px + (24 - 12) * ((100vw - 320px) / (2800 - 320)));
      margin:0;
      padding: 0;
      text-transform: unset;
    }

    span {
      color: $primary-txt;
      text-align: center;
    }

    .logo-text {
      margin-top: 1.8em;
      //font-size: 1.8em;
      font-size: calc(12px + (24 - 12) * ((100vw - 320px) / (2800 - 320)));
      text-align: center;
      width: 25em;
    }
  }

  [dir=ltr] .logoConfig {

    // background: url("../../assets/icons/svg/SignIn/Logo-en.svg") no-repeat center;
    svg {
      height: 20em;
    }
  }

  [dir=rtl] .logoConfig {

    // background: url("../../assets/icons/svg/SignIn/Logo-ar.svg") no-repeat center;
    svg {
      height: 20em;
    }
  }

  .text-right.col-6 {
    flex: none;
    max-width: none;
  }

  .download-app-container {
    color: $secondary-txt !important;
    text-transform: uppercase !important;
    font-weight: 600 !important;
    font-size: .9em !important;
    background-color: transparent !important;
    width: 20em !important;
    border-radius: 0% !important;
    border-color: #C4FFF92E !important;
    border-top: 1px solid !important;
    position: fixed;
    bottom: 2em;
    // left: 0em;
    text-align: center !important;

    p {
      margin-bottom: 0% !important;
    }

    svg {
      width: 8em !important;
      height: 4em !important;
    }
  }
  
  .bg-primary{
    background: transparent linear-gradient(180deg, #109a8c 0%, #09635A 40%, #03403A 90%) 0% 0% no-repeat padding-box !important;
    box-shadow:-5px 5px 5px #00000012,
    -10px 10px 15px #00000012,
    -20px 20px 20px #00000012,
    -15px 15px 25px #00000012,
    -25px 25px 50px #00000012,
    -35px 35px 100px #00000012 !important;
    border-radius: 15px !important;
    opacity: 1 !important;
    width: 30em;
    height: 40em;

    // align-items: center;
    .forgot-passowrd {
      justify-content: flex-end;
      margin-top: -1em;

      .btn {
        color: $primary !important;
        //font-size: .85em !important;
        font-size: calc(12px + (12 - 12) * ((100vw - 320px) / (2800 - 320)));
        &:hover{
          text-decoration: underline;
        }
      }
    }

    .dont-have-account {
      p {
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding: 0;
        width: auto;
        color: $primary-txt;
        text-transform: unset;
      }
      .signup {
        text-transform: uppercase;
        text-decoration: underline;
        color: $primary !important;
        font-weight: 400 !important;
      }
      align-items: center;
      justify-content: center;
      margin-top: 15%;
    }
  }

  .copy-rights {
    font-style: italic;
    padding-bottom: 0 !important;
    position: absolute; 
    font-size: 0.8em !important;
    bottom: -5em !important;
    width: fit-content !important;
    max-width: 90% !important;
    @media (min-width: 992px){
      inset-inline-end: 0;
    }
    @media (max-width: 991.98px){
      margin-inline-start: 0 !important;
    }
  }

  .buttonConfig {
    margin-top: 25%;
  }

  .login-error {
    position: absolute;
    margin-top: 5%;
    width: 30em;
    justify-content: center;
  }
}

/// mobile device
@media (min-width:310px) and (max-width:480px) {
  .buttonConfig {
    margin: 0 auto;
    margin-top: 15%;
  }
}

// ipad 
@media (min-width:481px) and (max-width:768px) {
  .buttonConfig {
    margin-top: 15%;
    margin: 0 auto;
  }
}

@media (min-width:769px) and (max-width:1024px) {
  .buttonConfig {
    margin-top: 15%;
    margin: 0 auto;
  }

  .bg-primary {
    margin-inline-end: -4em;
    margin-inline-start: 4em;
  }
}

@media (min-width:1025px) {
  .buttonConfig {
    margin-top: 15%;
    margin: 0 auto;
  }

  .error-message {
    padding-top: 10%;
    font-size: .6em;
    text-align: center;
  }

  .bg-primary {
    margin-inline-end: -5em;
    margin-inline-start: 5em;
  }
}

@media (min-width:1350px) {
  .bg-primary {
    margin-inline-end: -8em;
    margin-inline-start: 8em;
  }
}

@media (min-width:1150px) {
  .bg-primary {
    margin-inline-end: -6em;
    margin-inline-start: 6em;
  }
}

[dir=ltr] .download-app-container {
  left: 0em !important
}

[dir=rtl] .download-app-container {
  right: 0em !important
}


@media (min-width:1680px) {
  .logo-text {
    width: 23em;
  }

  .config {
    .bg-primary {
      width: 35em !important;
      height: 55em !important;
      margin-bottom: 5% !important;
      margin-left: 10% !important;
    }
  }
}