.user-orgs-wrapper {
    min-height: 100vh;
    background: url("../../assets/icons/svg/Organizations/bg.svg") no-repeat fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;

    .screen-header {
        display: flex;
        flex-direction: row;

        .container-logo {
            margin-top: 25px;
            margin-inline-start: 20px;

            svg {
                width: 8.5em;
                height: 8.5em;
                margin-top: 0;
            }
        }

        .buttons-container {
            margin-inline-start: auto;
            display: flex;

            .contact-us {
                width: 2.8em;
                height: 2.4em;
            }

            .organizations-list-avatar-drop-down {
                padding-top: 27px;
            }
        }
    }

    .Organizations-List {

        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
        padding: 0 !important;
        justify-content: center;
        position: relative;
        margin-top: 1em;


        .content-container {
            display: flex;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;

            ::-webkit-scrollbar {
                height: 100% !important;
                width: 7px;
            }

            ::-webkit-scrollbar-track {
                border-radius: 10px;
                background: transparent;
            }

            ::-webkit-scrollbar-thumb {
                background: #256F68;
                border-radius: 10px;
                width: 2px !important;
                cursor: pointer !important;
            }

            ::-webkit-scrollbar-thumb:hover {
                background: #348C83;
            }

            ::-webkit-scrollbar {
                display: block !important;
            }

            .header {
                color: var(--info);
                text-transform: uppercase;
                font-size: 1.2rem;
            }

            .paragraph {
                color: var(--primary);
                padding-inline: 30%;
                margin-inline: auto;
            }

            .bg-container {
                width: 26rem;
                max-width: 90%;
                height: 100%;



                .orgs-list {
                    list-style-type: none;
                    padding-inline: 0;
                    max-height: 55vh;
                    overflow-y: scroll;

                    .org-item {
                        background: #05463F 0% 0% no-repeat padding-box;
                        border: 1px solid #46AAA0;
                        border-radius: 10px;
                        opacity: 1;
                        letter-spacing: 0px;
                        color: #C4FFF9;
                        opacity: 1;
                        margin-bottom: 1rem;

                        .content-wrapper {
                            display: flex;
                            align-items: center;
                            justify-content: start;
                            margin-inline-start: 1.5rem;
                            max-width: 95%;
                            height: 63px;

                        }

                        .org-name {
                            margin-inline-start: 1.5rem;
                            text-transform: uppercase;
                            opacity: 0.9;
                        }

                        .unsubscribed-admin-content {
                            display: none;
                        }


                        &:not(.unsubscribed):hover {
                            background: #73E4CC 0% 0% no-repeat padding-box;
                            border: 1px solid #C4FFF9;
                            color: #165F59;
                            cursor: pointer;

                            .org-name {
                                opacity: 1;
                                font-family: 'nunito_extrabold';
                            }

                            #Ellipse_588 {
                                opacity: 0.4;
                                fill: #46aaa0;
                            }

                            #Ellipse_589 {
                                fill: #205c4f;
                            }
                        }
                    }



                    .unsubscribed {
                        background: #05463F 0% 0% no-repeat padding-box;
                        color: #46AAA0;
                        border: none !important;

                        .badge-wrapper {
                            margin-inline-start: auto;
                            margin-inline-end: 5px;

                            .badge-container {
                                padding-inline: 9px;
                                padding-block: 1px;

                                .badge-text {
                                    text-transform: capitalize;
                                    font-family: nunito_semibold;
                                }
                            }
                        }

                        #org_iconHov {
                            opacity: 0.5
                        }
                    }

                    .admin:hover {
                        background: transparent linear-gradient(90deg, #37ACA0 0%, #095F59 100%) 0% 0% no-repeat padding-box;

                        .content-wrapper {
                            display: none;
                        }

                        .unsubscribed-admin-content {
                            display: flex;
                            align-items: center;
                            justify-content: start;
                            width: 100%;
                            height: 63px;
                            border: 1px solid #46AAA0;
                            border-radius: 10px;
                            padding-inline-start: 30px;

                            .text-wrapper {
                                text-align: start;
                                font-size: 0.8rem;

                                .org-title {
                                    color: #FFFFFF;
                                    font-family: nunito_semibold;
                                }

                                .org-subtitle {
                                    color: #FFFFFF;
                                    text-transform: lowercase;
                                    font-family: nunito_semibold;
                                }

                                .bold-text {
                                    font-family: nunito_bold;
                                    font-size: 0.9rem;
                                    text-transform: uppercase;
                                }
                            }

                            .arrow {
                                width: 15px;
                                margin-inline-start: auto;
                                margin-inline-end: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}


[dir='rtl'] .Organizations-List {
    .orgs-list {
        .org-item:not(.unsubscribed) {
            .org-name {
                &:hover {
                    font-family: 'tajawal_extrabold';
                }
            }
        }

        .org-name {
            font-family: tajawal_bold;
        }

        .admin {
            &:hover {
                .arrow {
                    svg {
                        #first_path {
                            transform: translate(-1570px, -4788.51px) !important;
                        }

                        #second_path {
                            transform: rotate(-180deg) translate(-1610.76px, -4803.13px) !important;
                        }
                    }
                }
            }
        }
    }
}


@media (max-width: 500px) {
    .user-orgs-wrapper .Organizations-List .content-container .paragraph {
        padding-inline: 10%;
    }
}