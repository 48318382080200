.slider-group{
    border-radius:5px !important;
    position: relative !important;
    height: 2rem !important;
    background: $slider_bg 0% 0% no-repeat padding-box !important;
    .btn{
        @extend .gadget-subtitle;
    }
}
.slider-button{
    background: transparent !important;
    border-width: 0 !important;
    color: $secondary-txt !important;
    overflow: hidden !important;
}
.slider-button:first-of-type{
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}
.slider-button:last-child{
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}
.slider-button:hover{
    box-shadow: 0px 0px 0px $slider_box_shadow !important;
    z-index: 0 !important;
}
.slider-button:focus, .slider-button:active{
    @extend .slider-selected;
    z-index: 0 !important;
    position: relative !important;
    height: 2rem !important;
    box-shadow: 0px 0px 0px $slider_box_shadow !important;
    color: $primary-txt !important;
    transition: .5s !important;
    text-align: center !important;
    padding-inline: 0 !important;
    &:hover{
        color:$primary-txt !important;
        box-shadow: 0px 0px 0px $slider_box_shadow !important;
    }
}
// .btn-outline-primary:active > span{
//         color: $primary !important;
//         text-transform: uppercase !important;
// }

// .slider-button:first-child{
//     border-top-left-radius: 5px !important;
//     border-bottom-left-radius: 5px !important;
// }
// .slider-button:last-child{
//     border-top-right-radius: 5px !important;
//     border-bottom-right-radius: 5px !important;
// }
// .slider-button:hover{
//     box-shadow: 0px 0px 0px #013934BA !important;
// }

// .slider-button:focus{
//     z-index: 0 !important;
//     position: relative !important;
//     height: 2.3rem !important;
//     box-shadow: 0px 0px 0px #013934BA !important;
//     text-transform: uppercase !important;
//     color: $primary !important;
//     &:hover{
//         color:$primary !important;
//         box-shadow: 0px 0px 0px #013934BA !important;
//     }
//     span{
//         // position: absolute !important;
//         z-index: 3;
//         top: 0 !important;
//     }
// }
.slider-div{
    background: rgba(18, 209, 184, 0.247) 0% 0% no-repeat padding-box !important;
    height: 1.7rem !important;
    position: absolute !important;
    top: 0.15rem !important;
    box-shadow: 0px 0px 0px $slider_box_shadow !important;
    border: 2px solid $primary !important;
    border-radius: 5px !important;
    z-index: 1 ;
    transition: .5s !important;
    overflow: visible !important;
}