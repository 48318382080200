.pannelsWrapper {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 20px;
}
.irriwatch-container {
  display: flex;
  flex-direction: column;
  height: 80vh; /* Ensure it uses the full viewport height */
  inset-inline-end: -3px;
  position: absolute;
  z-index: 1000;
}
.toggler-button-wrapper {
  position: absolute;
  inset-inline-end: 100%;
  top: 20%;

}
.irrigation-recommendation-container {
  width: -webkit-fill-available;
  height: 600px  !important;
  margin: 2rem !important;
  //margin-left: 1rem !important;
  background-color: #05342f !important;
  .Map-Button-Container .map-view-button {
    display: none;
  }
  .card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-transform: unset;
    padding-block-end: unset !important;
    padding-block: 2vh !important;
    .card-title {
      display: flex;
      flex-direction: column;
      text-transform: unset;
      font-size: 0.78rem;
      width:55%;
      color: #c4fff9;
      span {
        color: #46aaa0;
      }
    }
    .total-water-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .total-water-card {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        opacity: 1;
        padding-inline: 1em;
        padding-block: 0.5em;
        margin-inline-end: 1em;
        width: 270px;
        .total-water {
          display: flex;
          flex-direction: column;
          text-align: start;
          font-size: 0.8rem;
          letter-spacing: 0px;
          opacity: 1;
          padding-inline-end: 2em;
        }
        .total-water-value {
          // text-align: left;
          font-size: 1.2rem;
          font-weight: 1200;
          opacity: 1;
        }
      }
      .needed {
        background: #03403a 0% 0% no-repeat padding-box;
        .total-water,
        .total-water-value {
          color: #46aaa0;
        }
      }
      .applied {
        background: #1552a2 0% 0% no-repeat padding-box;
        .total-water,
        .total-water-value {
          color: #c4fff9;
        }
      }
    }
  }
  .card-body {
    padding-block-start: unset;
    padding-block-start: unset;
    position: absolute;
    width: 100%;
    overflow-x: scroll;
    top:126px;
    height: 470px;
    .irrigation-recommendation-days-container {
      display: flex;
      position: absolute;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: unset;
      padding-inline-start: unset;
      margin-block-start: 1em;
      font-size: 0.8rem;
      //margin-inline-start: 3rem;
      li {
        list-style-type: none;
      }
      li:first-child {
        padding-top: unset;
      }
      .irrigation-recommendation-day-container {
        display: flex;
        flex-direction: column;
        background: #03403a 0% 0% no-repeat padding-box;
        border-radius: 10px;
        opacity: 1;
        //height: 90vh;
        margin-inline: 0.5em;
        align-items: center;
        .day-date {
          width: 112px;
          height: 3vh;
          background: #165f59 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 6px #00000014;
          border-radius: 15px;
          opacity: 1;
          text-align: center;
          font-size: 0.8rem;
          letter-spacing: 0px;
          color: #c4fff9;
          align-self: center;
          align-content: center;
          margin-block-start: -1em;
          height: 10%;
          padding: 3px;
        }
        .water-recommended-container {
          display: flex;
          flex-direction: column;
          margin-block: 1.5vh;
          //min-height: 16vh;
          .water-recommended-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 120px;
            border-radius: 8px;
            opacity: 1;
            color: #c4fff9;
            text-align: center;
            align-self: center;
            position: relative;
            padding: 15px 0px;
            .true-icon {
              position: absolute;
              top: 1px;
              right: 2px;
            }
          }
          .water-drop1 {
            align-self: center;
            margin-block-start: -2%;
            margin: 5px;
          }
          .text {
            font-size: 12px !important;
            color: #c4fff9;
          }
          .noWaterText {
            font-size: 12px !important;
            color: #02312c;
          }
          .water-drop2 {
            align-self: center;
            margin-block-start: -2%;
          }
          .water-recommended-value-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            align-self: center;
            width: 110px;
            height: 17px;
            background: #1552a2 0% 0% no-repeat padding-box;
            border-radius: 40px;
            opacity: 1;
            padding: 3px;
            .water-recommended-value {
              color: #c4fff9;
              text-align: center;
              align-content: center;
            }
            .exclamation-mark {
              margin-inline-end: -1em;
              margin-inline-start: 1em;
            }
            .exclamation-tooltip {
              display: none;
              position: absolute;
              margin-inline-start: 13vw;
              width: 9vw;
              height: 7vh;
              background: #165f59fa 0% 0% no-repeat padding-box;
              box-shadow: 5px 3px 6px #00000029;
              border-radius: 10px;
              opacity: 1;
              align-content: center;
              align-self: center;
              color: #c4fff9;
              text-align: left;
              padding-inline-start: 1em;
            }
            .exclamation-mark:hover + .exclamation-tooltip {
              display: block;
            }
          }
        }
        [dir="rtl"]
          .water-recommended-container
          .water-recommended-box
          .true-icon {
          right: 0px;
          left: 0px;
        }
        .weather-estimate-container {
          width: 135px;
          background: #04514a 0% 0% no-repeat padding-box;
          border-radius: 10px;
          opacity: 1;
          margin-block-end: 1em;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-family: "nunito_regular";
          padding: 5px;
          .row {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-content: center;
            margin-block: 0.7vh;
            .cell {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 60px;
              background: #02312c66 0% 0% no-repeat padding-box;
              border-radius: 5px;
              opacity: 1;
              margin-inline: 0.5vh;
              margin-block-start: 2vh;
              // font: normal normal bold 14px/19px Nunito Sans;
              letter-spacing: 0.04px;
              color: #c4fff9;
              font-weight: 600;
              font-size: 12px;
              padding: 4px;
              .wind {
                text-wrap-mode: nowrap;
              }
              span {
                text-align: center;
                // font: normal normal normal 14px/19px Nunito Sans;
                letter-spacing: 0.04px;
                color: #46aaa0;
                font-size: 11px;
                font-weight: 500;
                // text-wrap-mode: nowrap;
              }
              .icon {
                justify-self: center;
                margin-block-start: -1em;
              }
            }
          }
        }
        .scheduled-tasks-container {
          width: 11vw;
          background: #04514a 0% 0% no-repeat padding-box;
          border-radius: 10px;
          opacity: 1;
          display: flex;
          flex-direction: column;
          padding: 0.5vh;
          max-height: 38vh;
          .irrigation-scheduled-header {
            text-align: left;
            font-size: 0.8rem;
            letter-spacing: 0.05px;
            color: #c4fff9;
            opacity: 1;
            padding: 0.5em;
            margin-bottom: 0.5em;
          }
          .scheduled-tasks-list {
            margin-bottom: unset;
            padding-block: 0px;
            margin-block: 0px;
            padding-inline-start: unset;
            overflow-y: scroll;
            max-height: 18vh;
            li {
              padding-block: 0px;
              margin-block: 0px;
              list-style-type: none;
            }
            li:first-child {
              padding-top: unset;
            }
          }
          .create-task {
            background: #003a3580 0% 0% no-repeat padding-box;
            border-radius: 5px;
            opacity: 1;
            text-align: left;
            align-content: center;
            height: 6vh;
            padding-inline-start: 1em;
            margin-block: 0.5em;
            cursor: pointer;
            color: #73e4cc;
            &:hover {
              background: #02312ccc 0% 0% no-repeat padding-box;
              border: 1px solid #46aaa0;
              border-radius: 5px;
            }
          }
          .water-still-needed {
            display: flex;
            text-align: left;
            letter-spacing: 0px;
            color: #46aaa0;
            opacity: 1;
            padding-block: 1vh;
            svg {
              margin: 1vh;
            }
            span {
              width: 9vw;
            }
          }
          li {
            display: flex;
            flex-direction: row;
          }
          .irrigation-task-card {
            width: 11vw;
            display: flex;
            flex-direction: column;
            height: 8vh;
            background: #84b9ff 0% 0% no-repeat padding-box;
            border-radius: 5px;
            padding-inline-start: 1em;
            text-align: left;
            font-weight: 600;
            font-size: 0.8rem;
            letter-spacing: 0px;
            color: #02312c;
            opacity: 1;
            margin-block-end: 0.5em;
            justify-content: center;
            span {
              font-weight: 1200;
              font-size: 1.2rem;
            }
            &:hover {
              background: #1552a2 0% 0% no-repeat padding-box;
              border: 1px solid #c4fff9;
              color: #c4fff9;
              cursor: pointer;
            }
          }
          .selected-task-tooltip {
            position: absolute;
            display: flex;
            width: 12vw;
            background: #165f59fc 0% 0% no-repeat padding-box;
            border-radius: 10px;
            opacity: 1;
            margin-inline-start: 10vw;
            flex-direction: column;
            text-align: left;
            font-weight: 400;
            font-size: 0.8rem;
            letter-spacing: 0px;
            color: #c4fff9;
            padding: 2vh;
            text-transform: capitalize;
            .row {
              display: flex;
              flex-direction: row;
              margin-right: unset;
              margin-left: unset;
              padding-block: 0.5vh;
              span {
                color: #46aaa0;
              }
            }
            .update-task-container {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              margin-inline: 1vh;
              margin-block: 3vh;
              .edit-task {
                color: #73e4cc;
                cursor: pointer;
              }
              .cancel-task {
                color: #ff5b33;
                cursor: pointer;
              }
            }
          }
          .irrigation-task-card:hover + .selected-task-tooltip {
            display: flex;
          }
        }
      }
      .require-irrigation {
        border: 3px solid #73e4cc;
        .day-date {
          background-color: #73e4cc;
          color: #165f59;
        }
      }
      // .no-irrigation{
      //   // display: inline;
      // }
    }
  }
}
.irrigation-recommendation-container
  .card-body
  .irrigation-recommendation-days-container
  .last-day
  .irrigation-recommendation-day-container
  .scheduled-tasks-container
  .selected-task-tooltip {
  margin-inline-start: -12vw;
}

.header-row {
  height: 61px;
  width: 100%;
  background: #065a53;
  display: flex;
  flex-direction: row;
  padding: 20px;
  border-radius: 10px 10px 0 0;
  align-items: left;
  align-items: center;
  justify-content: space-between;
}
.legend-container {
  display: flex;
  align-items: center; // Center the legend vertically
  justify-content: center;
  //   margin-top: -18px;
  //   margin-bottom: 8px;

  .legend {
    width: 10px;
    height: 10px;
    border-radius: 50%; // Make it a circle
    margin-left: 5px;
    margin-right: 10px;
    background-color: #ff5b33;
  }

  span {
    color: #46aaa0;
  }
}
@media(max-width: 1024px) {
  .irrigation-recommendation-container{
   // height: 780px !important;
  }
  .irrigation-recommendation-container .card-body{
    top: 11rem!important;
    overflow-x: scroll;
  }
}
@media(max-width: 767px) {
  .irrigation-recommendation-container .card-body .irrigation-recommendation-days-container .no-irrigation{
    display: none !important;
  }
  // .irrigation-recommendation-container{
  //   // height: 650px !important;
  // }

  .irrigation-recommendation-container .card-header{
    flex-direction: column;
    align-items: center;
  }
  .irrigation-recommendation-container .card-header .card-title{
    width: 100%;
  } 
  // .irrigation-recommendation-container .card-body .irrigation-recommendation-days-container{
  //   //margin-inline-start: 5rem;
  //   //width: 100%;
    
  // }
  .irrigation-recommendation-container .card-body{
    top: 16rem!important;
    overflow-x: scroll;
  }
 [dir='rtl'] .irrigation-recommendation-container .card-body{
  top: 13rem!important;
    overflow-x: scroll;
  }
}
@media (min-width: 500px) and (max-width: 767px) {
  .irrigation-recommendation-container{
  //  height: 620px !important;
  }
  .irrigation-recommendation-container .card-body{
    top: 200px!important;
    overflow-x: scroll;
  }
}
/* Extra Small Devices (Phones) */
@media (max-width: 576px) {  
  /* Styles for phones */
  .irrigation-recommendation-container {
    height: 680px !important;
}
}

/* Small Devices (Tablets) */
@media (min-width: 577px) and (max-width: 768px) {  
  /* Styles for tablets */
  .irrigation-recommendation-container {
    height: 660px !important;
}
}

/* Medium Devices (Laptops & Small Desktops) */
@media (min-width: 769px) and (max-width: 1023px) {  
  /* Styles for small desktops */
  .irrigation-recommendation-container {
    height: 660px !important;
}
}

/* Large Devices (Desktops & Monitors) */
@media (min-width: 1024px) and (max-width: 1900px) {  
  /* Styles for larger desktops */
  .irrigation-recommendation-container {
    height: 600px !important;
}

}

/* Large Devices (Desktops & Monitors) */
@media (min-width: 1200px) {  
  /* Styles for larger desktops */
  .irrigation-recommendation-container {
    height: 565px !important;
}

}
