.new-tooltip__wrapper{
    &.op-0{
        opacity: 0;
    }
    &.op-1{
        opacity: 1;
    }
    position: fixed;
    &__content{
        position: relative;
        display: flex;
        $bg-opacity: 0.8;
        &__triangle{
            width: 10px;
            height: 10px;
            @include rgba-color('background-color', $dark-button-background, $bg-opacity);
            position: absolute;
            &.bottom{
                clip-path: polygon(50% 0, 100% 100%, 0 100%);
                top: -10px;
                inset-inline-start: calc(50% - 5px);
            }
            &.top{
                clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
                bottom: -10px;
                inset-inline-start: calc(50% - 5px);
            }
            &.end{
                clip-path: polygon(50% 50%, 100% 0% , 100% 100%);
                top: calc(50% - 5px);
                inset-inline-start: -10px;
            }
            &.start{
                clip-path: polygon(0% 0%, 50% 50% , 0% 100%);
                top: calc(50% - 5px);
                inset-inline-end: -10px;
            }
        }
        &__text{
            width: 8rem;
            @include rgba-color('background-color', $dark-button-background, $bg-opacity);
            color: $primary-txt-with-opactiy;
            text-align: center;
            padding: 5px 5px;
            border-radius: 6px;
            font-size: 0.8em;
            font-family: nunito_regular; // add in Arabic
        }
    }
}
[dir='rtl'] .new-tooltip__wrapper{
    &__content{
        &__triangle{
            &.bottom{
                clip-path: polygon(50% 0, 100% 100%, 0 100%);
                top: -10px;
                inset-inline-end: calc(50% - 5px);
                inset-inline-start: inherit;
            }
            &.top{
                clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
                bottom: -9px;
                inset-inline-end: calc(50% - 5px);
                inset-inline-start: inherit;
            }
            &.end{
                clip-path: polygon(0% 0%, 50% 50% , 0% 100%);

            }
            &.start{
                clip-path: polygon(50% 50%, 100% 0% , 100% 100%);
            }
        }
    }
}