.cardFooter{
    color: $secondary-txt !important;
    padding-top: 2.5em !important;
    padding-bottom: 1.5em !important;
}
.cardFooterDimmed{
    color: $secondary-txt !important;
    padding-top: 2.5em !important;
    padding-bottom: 1.5em !important;
    opacity: 0.2 !important;
}