.component-type-wrapper {
    #graph-widget-card-body {
        padding-block: 1em !important;
    }

    #graph-widget-card-footer {
        padding-block: 10px;
    }

    .xmedium-card {
        height: 17.5em;
    }

    .bullet-widget {
        #graph-widget-card-body {
            padding-block: 0 !important;
        }
    }

    .weather-component {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;

        .card {
            display: flex;
            flex: 1 15%;
            margin-bottom: 0.5rem;
        }
    }

    .default-component {
        #graph-widget-card-body {
            padding-block: 1em !important;
        }

        .card-body {
            display: flex;
        }

        .text-wrapper {
            display: flex;
            margin: auto;
            font: normal normal 1.8em/1.25em nunito_regular;
            padding-inline: 1rem;
            padding-block: 1.5rem;
            width: fit-content;
            max-width: 90%;
            // height: -webkit-fill-available;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            background: #03403A 0% 0% no-repeat;

            @media only screen and (max-width:1200px) {
                font: normal normal 1.8em/1.25em nunito_regular;
            }

            @media only screen and (max-width:1024px) {
                font: normal normal 1.7em/1.25em nunito_regular;
            }

            @media only screen and (max-width:700px) {
                font: normal normal 1.3em/1.25em nunito_regular;
            }
        }
    }
    .colored-widget {
        #graph-widget-icon {
            img {
                width: 20px !important;
            }
        }

        .card-header {
            height: 80px;
        }

        .text-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 14px;
            width: 206px;
            height: 129px;

            h2 {
                font-size: 36px;
                font-family: nunito_extraBold;
                margin: 0;
            }

            p {
                font-size: 13px;
                font-family: nunito_regular;
                margin: 0;
                font-style: italic;
            }

            .calculation-note {
                font-size: 13px;
                line-height: 20px;
            }
        }

        .search-drop-down-list .dropdown-menu {
            text-transform: capitalize;

        }
        .filter-dropdown {
            width: 90px;
           
            color: #73E4CC;
            .drop-down-selector-container {
                padding: 10px 20px;
            }
        }
    }
}

.component-with-background {
    border: 1px solid;
    border-radius: 0.25rem;
    background-color: #02312d;
    border-color: #02312d;

    .card {
        background-color: #05342F !important;
        border: 1px solid #46AAA040;
    }

    .component-type-body {
        padding: 1rem;
    }
}

@media (max-width:700px) {

    .component-type-wrapper {

        .weather-component {
            .card {
                flex: 1 100%;
            }
        }
    }
}