.empty-state-graph{
    background-color:$empty-state-graph ;
    // margin-top: 5%;
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    svg{
        width:45%;
        height:45%;
    }
    .text-container{
        display:flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}