.Checkbox-Container{
    display: flex;
    flex-direction: row;
    align-items: center;
    .Checkbox-View{
        display: flex;
        width: 17px;
        height: 17px;
        align-items: center;
        justify-content: center;
        background-color: $tab-background;
        border-radius: 4px;
        flex-shrink: 0;
        //box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        $lower-shadow: 3px 4px 8px darken($tab-background, 4.3%);
        $upper-shadow: -3px -3px 6px lighten($tab-background, 5%);
        box-shadow: -3px -3px 6px #19726ba1, 3px 4px 8px #061918c2;
        -webkit-box-shadow: -3px -3px 6px #19726ba1, 3px 4px 8px #061918c2;
        //@extend .neuo;
        .Checkbox-checked {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50%;
            height: 50%;
            border-radius: 2px;
            background-color: $primary;
            @extend .gadget-title;
        }
        .checkbox-content{
            display:flex;
            justify-content:center;
            align-items:center;
            font-weight:900;
            transform:scaleX(1.5);
            color:$dark-button-background;
        }
    }
    .Checkbox-Title{
        margin-inline-start: 12px;
        @extend .link;
    }
}
[dir='rtl']{
    .Checkbox-Container .Checkbox-View{
        box-shadow: -3px -3px 6px #19726bbf, 3px 4px 8px #032f2b;
        -webkit-box-shadow: -3px -3px 6px #19726bbf, 3px 4px 8px #032f2b;
    }
   
}