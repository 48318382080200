.selector-component-wrapper {
    position: relative;

    .selector-button {
        height: 45px;
        border-radius: 3px;
        border: 2px solid #165F59;
        color: #07403A;
        font-weight: 500;
        font-size: 0.8em;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        font-family: nunito_bold;
        outline: none;
        display: flex;
        align-items: center;

        .button-triangle {
            background-color: #07403A;
            clip-path: polygon(0 0, 100% 0, 50% 100%);
            width: 0.8em;
            height: 0.6em;
            margin-inline-start: 5px;
        }
    }

    .selector-list {
        position: absolute;
        padding-inline-start: 0;
        background: #FFFFFFE6 0% 0% no-repeat padding-box;
        border: 2px solid #165F59;
        border-radius: 5px;
        top: 110%;
        padding: 1em;
        z-index: 2000;

        .selector-list-item {
            list-style-type: none;
            position: relative;
            display: flex;
            flex-direction: column;
            padding-block: 5px;

            &:hover {
                cursor: pointer;
            }

            &:not(:last-child):after {
                content: "";
                position: absolute;
                border: 1px solid #165F59;
                opacity: 0.25;
                width: 100%;
                top: 100%;
            }

            &.selected::before {
                content: "";
                position: absolute;
                top: 0;
                width: 7px;
                height: 90%;
                inset-inline-start: -1em;
                background: #46AAA0 0% 0% no-repeat padding-box;
                border-radius: 0px 3px 3px 0px;
            }
        }
    }
}

.selector-component-wrapper.expanded {
    .button-triangle {
        clip-path: polygon(50% 0, 0 100%, 100% 100%);
    }
}

[dir='rtl'] .selector-component-wrapper {
    .selected::before {
        border-radius: 3px 0px 0px 3px !important;
    }
}
