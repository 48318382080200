//mapping RAG states
// @each $name, $i in $states{
//     .#{$name}{
//         color: nth($i,1);
//         background-color: nth($i,2);
//     }
// }

//mapping fonts type
@each $key,
$type in $fonts-en {
  .#{$key} {
    @each $i in $type {
      #{nth($i,1)}: nth($i, 2);
    }
  }
}

@each $key,
$type in $fonts-ar {
  .#{$key} {
    @each $i in $type {
      #{nth($i,1)}: nth($i, 2);
    }
  }
}


//function for rtl and ltr 



@mixin shadow($neuo) {
  -webkit-appearance: none !important;
  $lower-shadow: 3px 4px 8px darken($neuo, 4.3%);
  $upper-shadow: -3px -3px 6px lighten($neuo, 5%);
  box-shadow: $upper-shadow, $lower-shadow !important;
  -webkit-box-shadow: $upper-shadow, $lower-shadow !important;
}

.neuo {
  border-radius: 5px;
  border: none;
  background: $main_layer;
  @include shadow($main_layer);
  cursor: pointer;

  // &:not(.disabled):not(.lang):not(.export-calendar-button):hover {
  //   background-color: $primary;
  // }

}

// @mixin neuo($neuo){
//     border-radius: 5px;
//     border: none;
//     background: $neuo; 
//     $lower-shadow: 3px 4px 8px darken($neuo, 4.3%);
//     $upper-shadow: -3px -3px 6px lighten($neuo, 5%);
//     box-shadow: $upper-shadow,$lower-shadow !important;
//     cursor: pointer;  
//     &:hover{
//       background-color: $primary;
//       border:2px solid $primary-txt;
//   }
// }


@mixin validation-alert {

  @each $name,
  $i in $validation {
    // .#{$name}{
    color: nth($i, 1) !important;
    background: nth($i, 2) !important;

    @if (#{$name}=='danger') {
      &::before {
        content: url('../../assets/icons/svg/constants/error.svg');
        padding: 0 10px;
        vertical-align: middle;
      }
    }

    // .alert-link {
    //   color: darken(nth($i,1), 10%);
    //   }
  }

  // }
}


//utilities API
// $utilities: (
//   "background-color": (
//     property: background-color,
//     class: text,
//     values: (
//         "cta": $primary,
//         "secondary":  $btn-secondary
//          )
//     ) 
//  );