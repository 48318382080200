// @mixin shadow($neuo) {
//     $lower-shadow: 4px 4px 10px darken($neuo, 5%);
//     $upper-shadow: -4px -4px 5px lighten($neuo, 7%);
//     box-shadow: $upper-shadow,$lower-shadow;
// }
// .neuo{
//     border-radius: 5px;
//     border-style: hidden;
//     background: $main_layer;
//     @include shadow($main_layer);
//     cursor: pointer;   
// }

.schedule-card-wrapper{
    height: 46.5em
}
.irrigation-schedule-card-container{
    display:flex;
    flex-direction:row;
    height:100%;
    .pannel-view-container{
        flex:1;
        background-color:$dark-button-background;
        height:100%;
        .Checkbox-Container .Checkbox-View {
            background-color: $dark-button-background !important;
            -webkit-appearance: none !important;
            box-shadow: $dark-button-box-shadow !important;
            -webkit-appearance: $dark-button-box-shadow !important;
        }
        .all-plots-selector{
            margin-inline-start:2em;
            padding-block:1.3em;
            .Checkbox-Title{
            color: $primary-txt !important;}      
        }
        .all-plots-selector-separator{
            border-color:$secondary-txt-light-opacity;
            height:0.05em;
            margin:0
        }
        .selection-list-container{
            padding-inline-start:2em;
            padding-block:1em;
            max-height:15em;
            overflow:scroll;
            .list-option-container{
                margin-bottom:1.3em
            }
        }
        .date-picker-separator{
            border-style: dashed;
            border-color:$secondary-txt-light-opacity;
            width:90%;
            height:0.1em;
            margin-bottom:0
        }
        .date-picker-container{
            // display:flex;
            // justify-content:center;
            margin-top:-3%;
            transform:scale(1,0.8);
            margin-inline: auto;
            width:85%;
        }
    }
    .buttons-container{
        display:none;
        flex-direction:row;
        margin-bottom: 1em;
        margin-inline-start: 2em;
        button{
            width:12em;
            height:2.5em;
            background: $container-background 0% 0% no-repeat padding-box;
            border-radius: 3px;
            outline: none !important;
            border: none !important;
            margin-inline-end: 1.5em;
            color:$primary
        }
    }
    .schedule-container{
        flex:3;
        margin-inline-start:2em;
        height:100%;
        .month-title{
            color:$primary-txt;
            margin-inline-start:5em;
            margin-bottom:1em
        }
    }
}



@media (max-width: 878px){

    .irrigation-schedule-card-container{
        flex-direction: column !important;
        overflow: scroll;
        padding-block: 0.5em;
    }
    .pannel-view-container{
        display: none;
    }
    .month-title{
        display: none;
    }
    .buttons-container{
        display: flex !important;
        z-index: 100 ;
        .calendar, .plots{
        position: relative !important;}
        .plots{
            .triangle{
                position: absolute;
                width: 0; 
                height: 0; 
                top: 2.8em;
                left:4em;
                -webkit-appearance: none;
                border-left: 12px solid transparent;
                border-right: 12px solid transparent;    
                border-bottom: 12px solid $light-container-background;
                -webkit-border-bottom:  12px solid $light-container-background;
                z-index: 3000 !important;
              }
        }

        .calendar{
            .triangle{
                position: absolute;
                width: 0; 
                height: 0; 
                top: 2.7em;
                left:5.8em;
                -webkit-appearance: none !important;
                border-left: 12px solid transparent;
                border-right: 12px solid transparent;    
                border-bottom: 12px solid $light-container-background;
                -webkit-border-bottom:  12px solid $light-container-background !important;
                z-index: 3000 !important;
              }
            span{
                z-index: 100 !important;
            }
            button::before{
                content: url('../../assets/icons/svg/MainMap/calendar.svg');
                width: 3.5em;
                height: 3.5em;
                z-index: 1000 !important;
                padding-inline-end: 0.6em;
           }  
        }
    }
    .mobile-list-container{
        position: absolute;
        top:2.6em;
        left:0;
        z-index: 2000;
        background-color: $light-container-background!important;
        width: 10em;
        max-height: 15em;
        overflow: scroll;
        padding-top: 1em;
        padding-bottom: 1.5em;
        // padding-inline-start: 0.5em;
        border-radius: 5px;
        margin-top: 1em;
        .Checkbox-Container .Checkbox-View {
            background-color: $light-container-background !important;
            -webkit-appearance: none !important;
            box-shadow: 2px 2px 4px $light-checkbox-box-shadow !important;
            -webkit-box-shadow:  2px 2px 4px $light-checkbox-box-shadow !important;
            margin-inline-end: 0.3em;
        }
        .all-plots-selector{
            padding-inline-start: 0.5em;
            .Checkbox-Title{
                color: $primary-txt !important;
            } 
        }
        .selection-list-container{
            padding-inline-start: 0.5em;
            .Checkbox-Container{
                padding-bottom: 1em;
            }
        }
        .all-plots-selector-separator{
            border-color:$secondary-txt-light-opacity !important;
        }
    }
    .mobile-calendar {
        .date-picker-container{
            // display:flex;
            // justify-content:center;
            transform:scale(1,0.9);
            position: absolute;
            // left:-2em;
            top:2.5em;
            background-color: $light-container-background!important;
            width:13em;
        }
        .DayPicker-Months{
        background-color: $light-container-background!important;
        width: 10em;}
        .DayPicker{
        position: absolute;
        left:-2em;
        top:1.2em;
        transform: scale(0.8,0.8);
    }
    }
}

@media (min-width: 1550px) and (max-width:2000px){
    .schedule-card-wrapper{
        height: 55em !important
    }
}

@media (min-width: 2000px) and (max-width:3000px){
    .schedule-card-wrapper{
        height: 65em !important
    }
}

@media (min-width: 3100px) {
    .schedule-card-wrapper{
        height: 70em !important
    }
}

