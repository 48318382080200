.create-funds,
.view-fund {
  @media (max-width: 310px) {
    margin-top: 45px;
  }
  position: relative;
  background: url("../../assets/icons/svg/Funds/bg.png") no-repeat fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  max-width: 120% !important;
  .exclamation-responsive-mode {
    display: none;
    .exclamation-content {
      background: #095950 0% 0% no-repeat padding-box;
      box-shadow: -2px 0px 6px #04403999;
      border: 1px solid #5dd2c659;
      border-radius: 10px;
      padding: 10px;
      opacity: 1;
      letter-spacing: 0.34px;
      color: rgba(196, 255, 249, 0.9);
      max-width: 92%;
      padding-block: 1.3rem;
      margin-block: 1rem;
      margin-block-start: 2rem;
      margin-inline: auto;
    }
    .exclamation-icon {
      position: absolute;
      top: -13px;
      inset-inline-start: 50%;
      transform: translateX(-50%);
    }
  }
  .form-wrapper {
    background: #165f59 0% 0% no-repeat padding-box;
    border: 1px solid #46aaa0;
    border-radius: 10px;
    margin-top: 1rem;
    opacity: 1;
    width: 56%;
    padding: 2rem;
    margin-inline-start: 10rem;
    ::-webkit-scrollbar {
      height: 100% !important;
      width: 7px;
    }
    ::-webkit-scrollbar-track {
      border-radius: 10px;
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #256f68;
      border-radius: 10px;
      width: 2px !important;
      cursor: pointer !important;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #348c83;
    }
    ::-webkit-scrollbar {
      display: block !important;
    }
    &__header {
      letter-spacing: 0px;
      color: #c4fff9;
      text-transform: uppercase;
      opacity: 1;
    }
    &__input {
      width: 90%;
      form .input-group .form-control + span {
        inset-inline-start: 0px !important;
      }
    }
    form .input-group .form-control {
      border-bottom-color: rgba(115, 228, 204, 0.4);
    }
    form .input-group .form-control:focus {
      border-bottom-color: rgb(115, 228, 204);
      color: #c4fff9;
      font-size: 16px;
    }
    form .input-group .form-control + span.default-color {
      color: #c4fff9 !important;
    }
    form .input-group .form-control:focus ~ span {
      color: #73e4cc;
      text-transform: capitalize;
    }
    // inactive filled state "out of focus" label
    form .input-group .form-control:not(:placeholder-shown):not(:focus) ~ span {
      text-transform: capitalize;
      color: #46aaa0 !important;
      font-size: 12px;
    }
    form .input-group .form-control:not(:placeholder-shown):not(:focus) {
      color: #c4fff9;
      font-size: 16px;
      border-bottom-color: rgba(115, 228, 204, 0.4);
    }
    .input-error-wrapper {
      margin-top: -13px;
      color: #4fb4a9;
    }
    .mb-key {
      margin-bottom: 10px;
    }
    .input-failure {
      color: #f48585;
      font-size: 14px;
      text-transform: lowercase;
    }
    .farm-name__wrapper {
      .farm-name {
        margin-top: 10px;
        color: #c4fff9;
      }
    }
    .farm-area__wrapper {
      margin-inline-start: 1rem;
      .area {
        margin-top: 10px;
      }
    }
    .name-area__wrapper {
      display: flex;
    }
    .farm-name__wrapper,
    .farm-area__wrapper {
      flex: 1;
      max-width: 18rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .farm-name__wrapper {
      margin-top: 15px;
      color: rgb(115, 228, 204);
      text-transform: capitalize;
    }
    .address-wrapper {
      margin-top: -8px !important;
    }
    .revenue-cost-wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: -14px;
      .revenue-wrapper,
      .cost-wrapper {
        flex: 1;
        max-width: 45%;
      }
    }
    .revenue-cost-wrapper + .revenue-wrapper,
    .revenue-cost-wrapper + .cost-wrapper {
      margin-top: -10px;
    }
    .submit-wrapper {
      display: flex;
      margin: auto;
      justify-content: space-evenly;
      width: 100%;
      #cancel-fund {
        background: #165f59 0% 0% no-repeat padding-box !important;
        border: 1px solid #46aaa0 !important;
        border-radius: 5px;
        color: #73e4cc !important;
        margin-inline-start: 3rem;
        .proceed-icon {
          display: none;
        }
      }
    }
  }
  .funds-exclamation {
    position: absolute;
    inset-inline-end: -1.2vw;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    .exclamation-content {
      background: #095950 0% 0% no-repeat padding-box;
      box-shadow: -2px 0px 6px #04403999;
      border: 1px solid #5dd2c659;
      border-end-start-radius: 10px;
      border-start-start-radius: 10px;
      padding: 10px;
      opacity: 1;
      letter-spacing: 0.34px;
      color: rgba(196, 255, 249, 0.9);
      max-width: 18rem;
      padding-block: 1.3rem;
    }
    .exclamation-rectangle {
      position: relative;
    }
    .exclamation-triangle {
      position: absolute;
      inset-inline-start: -20px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: calc(50% - 27px);
      width: 20px;
      height: 54px;
      opacity: 1;
      background: #165f59 0% 0% no-repeat padding-box;
      border: none;
      border-end-start-radius: 5px;
      border-start-start-radius: 5px;
      outline: none;
      color: rgba(196, 255, 249, 0.9);
    }
    .exclamation-icon {
      position: absolute;
      top: -15px;
      inset-inline-start: 50%;
    }
    .exclamation-small-size {
      background: #095950 0% 0% no-repeat padding-box;
      box-shadow: -2px 0px 6px #04403999;
      border: 1px solid #5dd2c659;
      width: 30px;
      height: 30px;
      border-end-start-radius: 10px;
      border-start-start-radius: 10px;
      opacity: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .form-wrapper__input__scrolling {
    height: 82vh;
    overflow-y: scroll;
  }
}
.create-funds {
  .form-wrapper {
    height: 100vh;
  }
}
.submit-fund {
  text-align: center;
  margin-top: 1rem;
  background: url("../../assets/icons/svg/Funds/bg.png") no-repeat fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  max-width: 120% !important;
  .submit-fund {
    &__icon-wrapper {
      svg {
        width: 20rem;
        height: 20rem;
      }
    }
    &__title {
      margin-top: 1rem;
      color: #c4fff9;
    }
    &__subtitle {
      color: #53c4b9;
      font-size: 0.9rem;
    }
    &__exclamation {
      background: #095950 0% 0% no-repeat padding-box;
      border: 1px solid #5dd2c659;
      padding: 1rem;
      border-radius: 5px;
      padding-inline: 6rem;
    }
    &__button-wrapper {
      display: flex;
      margin-inline: auto;
      justify-content: center;
      margin-top: 1rem;
    }
  }
}
.fund-cancel-header {
  font: 1rem;
  letter-spacing: 0px;
  color: #c4fff9;
  text-transform: uppercase;
  opacity: 1;
}
.view-fund {
  .data__wrapper {
    margin-top: 2rem;
    .data__wrapper__value {
      color: #c4fff9;
    }
  }
  .name-area__wrapper {
    margin-top: 2rem;
    .farm-name {
      margin-top: 0 !important;
    }
  }
  .revenue-cost-wrapper {
    margin-top: 2rem !important ;
    .revenue-cost-field-wrapper {
      flex: 1;
      max-width: 45%;
    }
  }
  .form-wrapper {
    .data__wrapper,
    .farm-area__wrapper {
      color: #73e4cc;
    }
    .farm-area__wrapper {
      .area {
        color: #c4fff9;
      }
    }
    .farm-area__wrapper {
      margin-top: 10px;
    }
  }
}

@media (min-width: 1px) and (max-width: 1270px) {
  .create-funds,
  .view-fund {
    .exclamation-responsive-mode {
      display: block;
      position: relative;
      .show-disclaimer-button {
        border: none;
        outline: none !important;
        background: none;
        color: $primary;
      }
    }
    .funds-exclamation {
      display: none;
    }
  }
}
@media (max-width: 600px) {
  .default-input-focus-padding-inline {
    padding-inline-start: 0em;
  }
  .submit-fund {
    .submit-fund__title {
      font-size: 1.3rem;
    }
    .submit-fund__exclamation {
      padding-inline: 0;
    }
    .submit-fund__icon-wrapper svg {
      width: 12rem;
      height: 12rem;
    }
  }
}
@media (max-width: 500px) {
  .create-funds,
  .view-fund {
    .form-wrapper {
      width: 93%;
      margin-inline: auto;
      padding-inline-end: 0;
      .form-wrapper__header {
        margin-bottom: -1rem;
      }
      .name-area__wrapper {
        flex-direction: column;
        .farm-name__wrapper,
        .farm-area__wrapper {
          max-width: 100%;
          margin-inline-start: 0;
        }
        .farm-area__wrapper {
          margin-top: 10px;
          form {
            margin-top: -10px;
          }
        }
      }
    }
    .exclamation-responsive-mode {
      width: 100%;
      margin-inline: auto;
    }
  }
  .view-fund {
    .form-wrapper {
      .form-wrapper__header {
        margin-bottom: 0rem;
      }
      .revenue-cost-field-wrapper {
        max-width: 100%;
      }
      .revenue-cost-field-wrapper:first-child {
        max-width: 100%;
        margin-bottom: 20px;
      }
      .farm-area__wrapper .area{
        margin-top: 0;
      }
      .data__wrapper {
        margin-block: 20px;
      }
      .name-area__wrapper {
        margin-block: 0 !important;
      }
      .revenue-cost-wrapper {
        margin-top: o !important;
      }
      .farm-name__wrapper {
        margin-top: 0 !important;
      }
      .farm-area__wrapper{
        // margin-block: 20px;
      }
    }
  }
}
@media (min-width: 501px) and (max-width: 800px) {
  .create-funds,
  .view-fund {
    .form-wrapper {
      width: 90%;
      margin-inline: auto;
    }
    .funds-exclamation {
      .exclamation-content {
        max-width: 16rem;
      }
    }
  }
}
@media (max-width: 767px) {
  .revenue-cost-wrapper {
    flex-direction: column;
    .revenue-wrapper,
    .cost-wrapper {
      max-width: 100% !important;
      width: 100%;
    }
  }
}
