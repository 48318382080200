.satellite-weather-forecast-list {
  list-style: none;
  display: flex;
  flex-direction: row;
  padding-inline-start:0;
  .weather-card-wrapper {
    margin-inline-end: 15px;
    border-radius: 5px;
    background-color:#03403AD9;
    height: 40px;
    display: flex;
    align-items: center;
    @extend .semibold-font;
    .weather-card-reading{
        margin-inline-start: 5px;
        padding-inline-end: 10px;
        white-space: nowrap;
    }
    .weather-icon {
      margin-inline-start: 5px;
      width: 2em;
      height: 1.8em;
    }
  }
}

.no-weather-data-disclaimer{
    background-color: #03403AD9;
    border-radius: 5px;
    color: #73E4CC;
}
