.create-update-panel{
    display: flex;
    flex-direction: column;
    flex:1;
    width: 100%;
    height: 100%;
    padding-top: 1.2em;
    font-size: .85em;
    .program-name-error{
        margin-inline-start: 2.4em;
        margin-inline-end: 2.4em;
        margin-top: 1.2em;
        margin-bottom: 1.2em;
        background-color: #FF9A9A;
        padding: 1em;
        border-radius: 3px;
        span{
            color: #5A0A0A;
            padding-inline-start: 1em;
            font-weight: 500;
        }
    }
    .content{
        display: flex;
        flex-direction: column;
        background-color: #0A2B2766;
        // height: 48em;
        border-radius: 5px;
        overflow-y: scroll;
        padding-top: .5em;
        padding-inline-start: 2em;
        padding-inline-end: 2em;
        margin-inline-end: 1.2em;
        margin-inline-start: 1.2em;
        .input-group{
            margin: 20px 0 !important;
        }
        .radio-buttons-container{
            display: flex;
            flex-direction: row;
            label{
                color: #73E4CC;
                margin-bottom: 0em;
            }
        }
        .title{
            color: #C4FFF9;
            text-transform: uppercase;
            margin-top: 1em;
            margin-bottom: .5em;
        }
        .subtitle{
            color: #46AAA0;
            margin-bottom: .8em;
            font-size: 1.2em;
        }
        .calendar-button {
            position: relative;
            width:13em;
            height:3em;
            margin-top: .5em;
            margin-bottom: .5em;
            background: $calendar-background 0% 0% no-repeat padding-box;
            -webkit-appearance: none;
            box-shadow: $dark-button-box-shadow;
            -webkit-box-shadow: $dark-button-box-shadow;
            border-radius: 5px;
            outline: none !important;
            color:$primary;
            span{
                padding-inline-start: 3.5em;
                color: #73E4CC;
                font-weight: 500;
                font-size: 0.92em;
            }
        }
        .calendar-button::before{
            content: url('../../assets/icons/svg/sidebar/inactive/scheduler.svg');
            left:0.5em;
            top:0.65em;
            width: 2em;
            height: 1.5em;
            z-index: 1000 !important;
            position: absolute;
        }
        .input-container{
            position: relative;
            // form{ 
            //     width:100%;
            // }
            .prefix{
                display: none;
                position: absolute;
                bottom: 2em !important;
                left:0
            }
            .suffix{
                display: none;
                position: absolute;
                bottom: 2em !important;
                left:85%
            }
            form:focus-within ~ span {
                display: inline-block !important;
            }
            .error{
                display: inline-block;
                position: absolute;
                bottom: 0 !important;
                margin-inline-start:0;
                color:#F48585 ;
                font-size: .86em;
                left: inherit;
            }
        }
    }
    .button-view{
        align-self: center;
        display: flex;
        // flex-direction: column-reverse;
        flex: 1;
        margin-top: 1em;
    }
}
.create-program-title{
    color: #C4FFF9;
    padding-top: 2em;
    font-size: 1em;
    padding-inline-start: 2em;
    text-transform: uppercase;
}