.colored-group-container {
    display: flex;
    gap: 10px;
    width: 100%;
    @media (min-width: 1330px) {
      height: 49%;
    }
    @media (max-width: 1330px) {
      flex-wrap: wrap;
    }

    .widget-container {
      width:25%;
      @media (max-width: 1330px) {
          flex: 1;
        }
      @media (max-width: 700px) {
        flex: 1 100%;
      }
    }

    .colored-widgets-chart {
      flex: 1 50%;
      .xmedium-card {
        height: 100%;
      }
      @media (max-width: 1330px) {
        flex: 1 100%;
      }
    }
    #graph-widget-card-body{
      padding-top: 2.5rem !important;
      align-self: center;
    }
    .colored-widget {
        #graph-widget-icon {
            img {
                width: 20px !important;
            }
        }

        .card-header {
            height: 80px;
        }

        .text-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 14px;
            width: 14em;
            height: 8em;
            border-radius: 5px;
            h2 {
                font-size: 1.25rem;
                margin: 0;
                [dir="ltr"] & {
                    font-family: nunito_extraBold;
                }
                [dir="rtl"] & {
                    font-family: tajawal_medium;
                }
            }

            p {
              margin: 0;
              font-size: 0.75rem;
              line-height: 1.8;
            }
            [dir=ltr] p {
                margin: 0;
                font-style: italic;
            }
            .calculation-note {
                font-size: 13px;
                line-height: 18px;
            }
        }

        .search-drop-down-list .dropdown-menu {
            text-transform: capitalize;

        }
        .filter-dropdown {
            width: 90px;
          
            color: #73E4CC;
            .drop-down-selector-container {
                padding: 10px 20px;
            }
        }
    }
    .widget-container{
      background-color: #05342F;
      .xmedium-card{
          background-color: #05342F;
          height: 80%;
      }
    }
  }

