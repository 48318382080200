.warning-card {
    border-radius: 3px;
    opacity: 1;
    padding-block: 10px;
    padding-inline: 5px;
    border-radius: 3px;
    display: flex;
    gap: 8px;
    align-items: center;
  &.normal {
    color: $warning-normal-card-color;
    background: $warning-normal-card-bg 0% 0% no-repeat padding-box;
  }
  &.warning {
    color: #A76A12;
    background: #FDC473 0% 0% no-repeat padding-box;
  }
  &.danger {
    color: $warning-danger-card-color;
    background: $warning-danger-card-bg 0% 0% no-repeat padding-box;
  }
  svg{
    height: 16px;
    width: 16px;
  }
  .warning-text {
    font-family: nunito_regular;
    font-size: 16px;

    [dir='rtl'] & {
      font-family: tajawal_regular;
    }
  }
}
