.valve-controller{
    position: relative;
    // width: 12vw;
    // height: 10vh;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    margin: 0%;
}
#valve-stand{
    // position: absolute;
    z-index: 1;
    display: flex;
    margin-bottom: 0%;
    margin-top: 5%;
}
#valve{
    position: absolute;
    z-index: 2;
    display: flex;
    transition: all 1s ease-out !important;
    overflow: visible !important;
    // margin-inline-start: 3%;
    // margin-bottom: 12%;
    // margin-inline-start: -10%;
    // -webkit-transform:rotate(90deg);
    // -ms-transform: rotate(90deg);
    // transform: rotate(90deg);
}