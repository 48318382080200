.NotificationPanel-Container {
    width: 100%;
    max-height: 80vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    padding-inline-start: 20px;
    padding-inline-end: 20px;
    //margin-top: 25px;
    font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));

    .NotificationPanel-SubTitle {
        display: flex;
    }

    .NotificationPanel-Content {
        display: flex;
        width: 100%;

        .NotificationPanel-Content-Wrapper {
            //font-size: 0.7rem;
            flex: 1;

            // margin-inline-end: 6rem;
            .NotificationPanel-Content-Wrapper-Title {
                color: $primary-txt;
                font-weight: 600;
            }
        }
    }

    .NotifcationPanel-Subtitle2 {
        color: $primary-txt;
        font-weight: 600;
        margin-bottom: 10px;
        //font-size: 0.8rem;
        display: none;
    }

    .NotificationPanel-Status {
        display: flex;
        width: fit-content;
        border-radius: 10px;
        align-items: center;
        justify-content: center;
        height: 1.2rem;
        border: 1px solid;
        margin-inline-start: 10px;

        p {
            margin-top: 0.9rem;
            font-size: 0.7rem;
            padding-inline-start: 0.5rem;
            padding-inline-end: 0.5rem;
        }
    }

    .NotificationPanel-Description {
        margin-top: 10px;

        .NotificationPanel-Description-Title {
            color: $primary-txt;
            font-weight: 600;
        }

        //font-size: 0.7rem;
    }

    .NotificationPanel-Message {
        margin-top: 10px;

        .NotificationPanel-Message-Title {
            color: $primary-txt;
            font-weight: 600;
        }

        //font-size: 0.7rem;
    }

    .NotificationPanel-SubTitle-UserIcon {
        margin-top: 0.1rem;

    }

    .NotificationPanel-SubTitle-UserInfo {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        margin-inline-start: -1rem;

        .NotificationPanel-SubTitle-Sender {
            display: flex;
            // p{
            //     font-size: 0.7rem;
            // }
        }

        .NotificationPanel-SubTitle-SenderDate {
            display: flex;
            margin-top: -0.5rem;

            p {
                //font-size: 0.7rem;
                color: $primary-txt;
                margin-inline-start: 0.2rem;
            }
        }

        .NotificationPanel-SubTitle-ReceiverInfo {
            display: flex;
            margin-top: -0.5rem;

            p {
                font-size: 0.7rem;
            }
        }


    }

    .NotificationPanel-Receiver {

        .NotificationPanel-Receiver-Title {
            color: $primary-txt;
            font-weight: 400;
        }

        .NotificationPanel-Wrapper {
            ul {
                display: flex;
                list-style-type: none;
                flex-wrap: wrap;
                margin: -1rem;
                margin-inline-start: -2.5rem;
                align-items: center;
            }

            .NotificationPanel-Receiver-Icons {
                display: flex;
                align-items: center;
                //    width: 100px;
            }
        }

        .NotificationPanel-MailWrapper {
            ul {
                display: flex;
                list-style-type: none;
                flex-wrap: wrap;
                margin: -1rem;
                margin-inline-start: -2.5rem;
                align-items: center;
            }

            .NotificationPanel-Mail-Content {
                display: flex;
                align-items: center;
                //    width: 100px;
            }
        }

    }

    .ShowMoreIcon {
        padding-inline-start: 1rem;
        padding-bottom: 0.9rem;
        cursor: pointer;
        color: #73E4CC;

        &:hover {
            text-decoration: underline;
        }
    }

    .ShowLessButton {
        padding-inline-start: 1rem;
        padding-bottom: 1rem;
        cursor: pointer;
        color: #73E4CC;

        &:hover {
            text-decoration: underline;
        }
    }

}

.NotificationPanel-Reciever {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .MoreIcons {
        margin-inline-end: 10px;
        position: relative;
        margin-top: 7px;
        cursor: pointer;
        color: #73E4CC;

        &:hover {
            text-decoration: underline;
        }
    }

    ul {
        display: flex;
        list-style-type: none;
        flex-wrap: wrap;
        margin: -1rem;
        margin-inline-start: -7.5rem;
        padding-top: 10px;
        // column-count: 3;

    }

    li {
        margin-inline-end: -2.5rem;
    }

    .RecieverIcon-Wrapper {
        display: flex;
    }

    .RecieverIcon {
        position: relative;
        width: 50px;

        .UserRowIcon {
            width: 50px;
            height: 50px;
            // &:hover{
            //     // border: 2px solid #73E4CC;
            //     z-index: 100;
            //     position: absolute;
            //     // top: 0;
            //     // bottom: 0;
            //     // left: 0;
            //     // right: 0;
            //     z-index: 1000;

            // }
        }
    }
}

.NotificationMail_Row {
    display: flex;
    color: #46AAA0;
    font-size: 0.7rem;
    align-items: center;
    justify-content: space-between;

    .MoreIcons {
        margin-inline-end: 10px;
        position: relative;
        margin-top: 7px;
        cursor: pointer;
        color: #73E4CC;

        &:hover {
            text-decoration: underline;
        }
    }

    ul {
        display: flex;
        list-style-type: none;
        flex-wrap: wrap;
        margin: -1rem;
        margin-inline-start: -3.5rem;
        padding-top: 10px;

    }

    li {
        // margin-inline-end: -2.5rem;
    }

    .RecieverIcon-Wrapper {
        display: flex;
    }

    .RecieverIcon {
        position: relative;
        // width: 50px;
        margin-inline-end: 0.7rem;

        .UserRowIcon {
            width: 50px;
            height: 50px;
            // &:hover{
            //     // border: 2px solid #73E4CC;
            //     z-index: 100;
            //     position: absolute;
            //     // top: 0;
            //     // bottom: 0;
            //     // left: 0;
            //     // right: 0;
            //     z-index: 1000;

            // }
        }
    }
}

.triangle {
    position: absolute;
    width: 0;
    height: 0;
    top: 0.9rem;
    left: 0.5em;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 10px solid #196A63;
    z-index: 1000 !important;
}

.MoreUsers {

    position: absolute;
    top: 1.5rem;
    bottom: 1.2rem;
    left: -2px;
    background-color: #165F59;
    border-radius: 5px;
    right: 0;
    width: 7rem;
    height: 7rem;
    overflow-y: scroll;
    max-height: 7rem;
    z-index: 10;

    ul {
        list-style-type: none;
        flex-wrap: wrap;
        display: contents;
        // margin: -1rem;
        // margin-inline-start: -7.5rem;
        padding-top: 1rem;
        // column-count: 3;

    }

    li {
        margin-inline-end: 0;
    }

    .UsersIcon {
        display: flex;
        align-items: center;
        margin-bottom: -20px;
        margin-inline-start: 0.8rem;

        p {
            margin-top: 5px;
            margin-inline-end: 5px;
        }
    }
}

[dir='rtl'] {

    .NotificationPanel-Container .NotificationPanel-Content .NotificationPanel-Content-Wrapper .NotificationPanel-Content-Wrapper-Title,
    .NotificationPanel-Container .NotificationPanel-Description .NotificationPanel-Description-Title,
    .NotificationPanel-Container .NotificationPanel-Message .NotificationPanel-Message-Title {
        font-weight: 400;
    }
}