.weekly-list-container{
    overflow-x:scroll;
    padding-top:2em;
    height:13em;
    padding-inline-start:0.7em;
    list-style-type:none;
    display:flex;
    flex-direction:row;
    .card-with-icon-container {
        border: 1px solid transparent !important;
    }
    .item-container{
        margin-inline-end:1em;
        .content-container{
            display:flex;
            align-items:center;
            flex-direction:column;
            margin-top:2.5em;
            span{
                font-size: 0.9em ;
                font-weight: 400;
            }
            .summary{
                font-size: 0.9em !important;
                font-weight: 600 !important;
                text-transform:uppercase;
                margin-bottom:0.5em;
                color: inherit !important;
            }
            .temperature{
                margin-bottom:0.5em;
            }
        }
    }
}