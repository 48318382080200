.unsubscribed-wrapper {
    @extend .LinkExpired_Wrapper;
    .user-farms-avatar-dropdown{
        padding-top: 24px;
    }
    .buttons-container {
        position: absolute;
        top: 0;
        inset-inline-end: 20px;
        display: flex;
    }
    .logo svg {
        width: 180px;
        height: 180px;
    }

    .title {
        color: #C4FFF9;
        font-weight: bolder;
        text-align: center;
        font-size: calc(16px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
        margin-bottom: 0.5rem !important;


    }

    .subtitle {
        color: #53C4B9;
        text-align: center;
        font-size: calc(14px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
        width: 30%;
    }

    .proceed-button {
        height: 40px;
        padding-inline: 20px;
    }
}