#gaugeCard{
    .gauge-card-header{
        display:flex;
        flex-direction:row;
        .gauge-card-subheader{
            color:#46AAA0;
            text-transform:capitalize
        }
    }
    .gauge-chart-container{
        width:75%;
        height:70%;
        display:flex;
        justify-content:center;
        margin-inline:auto;
        margin-bottom:-20px
    }
    .reading-container{
        display:flex;
        flex-direction:column;
        align-items:center
    }
}