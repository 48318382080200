/****************************
        Namaa Palettes
****************************/
//Dark Theme "normal"

//backgrounds color
$main_layer: #03403A !default;
$secondary_layer: #247F77 !default;
$config_bg: linear-gradient(#37ACA0, #09635A, #03403A) !default;
$action_button_hover: #73E4CC !default;
$sidebar: #165F59 !default;
$light-bg: #FFFFFFE6 !default;
//modal
$modal_bg:#022420E6 !default;
$remaining-bg: #FFC67D !default;
$text-shadow:#01302BD4 !default;
$box-shadow:#07766B !default;
$border:#1E776E !default;
//forcast card
$forcast_bg:linear-gradient(180deg, #37ACA0 0%, #09635A 50%, #03403A 130%) !default;
//slider component 
$slider_bg: #004E47 !default;
$slider_box_shadow:#013934BA !default;

//accordion component 
$accordion-bg: #065A53 !default;
$accordion-title: #72E3CB !default;
$accordion-border:#37998447 !default;

//empty graph
$empty-state-graph:#05342F !default;

$forcast-color:rgba(173, 129, 60,0.2) !default;
//calendar component
$selected_days_range_bg:#1C645E !default;
//buttons color
$primary:  #73E4CC !default;
$button-after-bg: #A0FFEB !default;
//$cta: $primary;
$secondary: $main_layer;

//text colors
$cta-txt: #165F59 !default;
$border-primary : #C4FFF92E;
$primary-txt: #C4FFF9 !default;
$primary-txt-with-opactiy: rgba(196, 255, 249, 0.8);
$primary-txt-opacity: #C4FFF945;
$secondary-txt:  #46AAA0 !default;
$secondary-txt-opacity: #46AAA04D;
$secondary-txt-light-opacity: #46AAA033;
$secondary-txt-dark-opacity: #46AAA040;
$thirdly-txt: #298A80 !default;
$input-field: $primary-txt;
$unselected-text: #4FC5B9 !default;
//RAG backgrounds ~ plot status
$danger-bg: #A83737 !default;
$warning-bg: #FDC473 !default;
$normal-bg: $primary;
$no-sensor: rgba(93, 93, 93, 0.6) !default;
$text-to-light-bg : #07403A !default;
//RAG texts
$danger-txt: #FFC6C6 !default;
$warning-txt: #B47916 !default;
$normal-txt: $thirdly-txt;
$remaining-color : #73460D !default;
// Warning Card 
$warning-normal-card-color:#c4fff9 !default;
$warning-normal-card-bg:#46aaa0b3 !default;
$warning-danger-card-color:#FFB5B5 !default;
$warning-danger-card-bg:#FF5B33B3 !default;
$warning-text-color-file-upload: #FF5B33 !default;
//validation
$error-helper: #FF9A9A !default;
$validation-txt: #5A0A0A !default;
$validation-bg: $error-helper;

//badge
// $badge: linear-gradient(to right,#F3B3B3, #FF8181, #FDC473);
$validation:(
  danger:(
    $validation-txt,
    $validation-bg
    ),
  // info:(
  //   $validation-txt,
  //   $badge
  // )
);

//others
$bubble-selection: transparent linear-gradient(to right, #85F1DA , #1DAE9F) !default;
$sidebar-dark: #165F59;
$sidebar-dark-dropdown-item-bg: #03403A3E;
$container-background: #02312C;
$light-container-background: #196A63;
$tab-background: #145D57;
$blocked-tab-background: #145D57;
$dark-button-background: #003A35;
$dark-button-box-shadow: 3px 3px 6px #032925,-.5px -.5px 5px #73E4CC40;
$events-hover-background: #79B2FF;
$events-hover-border:#B7D6FF;
$light-checkbox-box-shadow:#032925CB;
$tooltip-header: #318179;
$tooltip-body: white;
$calendar-button-box-shadow:#B0FFF733;
$graph-card-background:#024842;
$graph-card-overlay:#085750;
$action-button-box-shadow: #0B837780;
$card-with-icon-border: #46AAA066;
$line-separator: #46AAA046;
$capsule-outline: #056056;
$depth-status-background: #012B26;
$depth-status-border: #09525299;
$filter-background: #0A4640;
$filter-border: #46AAA0A6;
$calendar-background: #0D4944;
$error-message: #F48585;
$calendar-border: #46aaa047;
$events-background: #05342F9B;
$fertigation-background:#7BC792;
$fertigation-border: #C6FFD7;
$irrigation-background:#84B9FF;
$irrigation-border: #C9E0FF;
$reminder-background:#FFD0A8;
$reminder-border:#FFF7EF;
$off-range-event-background: #0f3e39;
$program-type: #CBF4EA;
//Light Theme "normal"
//themes

// scss-docs-start theme-colors-map
$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $primary,
    "info":       $primary-txt,
    "warning":    $warning-bg,
    "danger":     $error-helper
    // "light":      $light,
    // "dark":       $dark
  ),
  $theme-colors
);