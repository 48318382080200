.help-button-wrapper {
    outline: none !important;
    background-color: #03403A;
    box-shadow: 3px 3px 6px #001A17A2;
    width: 36px;
    border-radius: 18px;
    color: #73E4CC;
    border: none;
    position: relative;

    &:not(.expanded):hover {
        border: 2px solid #C4FFF9;
        background-color: #73E4CC;
        color: #165F59;

        &::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 30%;
            border-bottom: 7px solid transparent;
            border-top: 7px solid transparent;
            border-left: 7px solid #003A35CC;
            inset-inline-start: -10px;
        }

        .button-tooltip {
            position: absolute;
            background-color: #003A35CC;
            border-radius: 3px;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 3px;
            padding-inline: 8px;
            padding-block: 5px;
            white-space: nowrap;
            width: 15em;
            inset-inline-start: -15.8em;
            color: #73E4CC
        }
    }



    .help-icon {
        @extend .semibold-font;
        font-size: 15px;
    }

    .button-tooltip {
        display: none;
    }

    .drop-down-wrapper {
        display: none;
    }
}

.help-button-wrapper.expanded {
    border: 2px solid #C4FFF9;
    background-color: #73E4CC;
    color: #165F59;

    .drop-down-wrapper {
        display: block;
        position: absolute;
        top: 33px;
        inset-inline-start: 0;

        .triangle {
            position: relative;
            left: 0;
            top: 0;
            margin-inline-start: 10px;
            border-right: 7px solid transparent;
            border-left: 7px solid transparent;
            border-bottom: 7px solid #195F57;
        }

        .drop-down-content {
            color: #C4FFF9;
            background: #175F58 0% 0% no-repeat padding-box;
            border-radius: 5px;
            opacity: 0.97;
            width: 25em;
            max-height: 40em;
            overflow: scroll;
            padding-inline: 10px;
            padding-block: 20px;
            margin-inline-start: -15em;
            text-align: start;

            ::-webkit-scrollbar {
                height: 100% !important;
                width: 7px;
            }

            /* Track */
            ::-webkit-scrollbar-track {
                border-radius: 10px;
                background: transparent;

            }

            /* Handle */
            ::-webkit-scrollbar-thumb {
                background: #256F68;
                border-radius: 10px;
                width: 2px !important;
                cursor: pointer !important;
            }

            /* Handle on hover */
            ::-webkit-scrollbar-thumb:hover {
                background: #348C83;

            }

            ::-webkit-scrollbar {
                display: block !important;
            }
        }
    }
}

[dir=rtl] .help-button-wrapper {
    &:not(.expanded):hover {

        &::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 30%;
            border-bottom: 7px solid transparent;
            border-top: 7px solid transparent;
            border-left: none !important;
            border-right: 7px solid #003A35CC;
            inset-inline-start: -10px;
        }
    }
}