.card-header-with-icon{
    display: flex;
    align-items: end;
    &__icon{
        margin-inline-start: 0.6rem;
        position: relative;
    }
    &__tooltip{
        visibility: hidden;
        position: absolute;
        inset-inline-start: 3px;
        inset-block-start: 20px;
        z-index: 100;
        &__wrapper__text{
            margin-inline-start: -39px;
            background: $main_layer 0% 0% no-repeat padding-box;
            color: $primary-txt;
            text-align: center;
            padding: 7px 5px;
            border-radius: 6px;
            font-size: 0.8em;
            width: 14rem !important;
            &__triangle{
                width: 0;
                height: 0;
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
                border-bottom: 10px solid $main_layer;
            }
        }
    }
}
.card-header-with-icon__icon:hover .card-header-with-icon__tooltip{
    visibility: visible;
}