.dual-line-chart-container {
    background-color: #05342F;
    height: 100%;
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 768px) {
        height: 446px !important;

    }

    .chart-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        height: 40%;

        .right-side-header {
            display: flex;
            align-items: center;
            gap: 10px;
    

            .crop-filter {
                width: 90px;
                color: #73E4CC;
                text-transform: capitalize;

                .search-drop-down-list {
                    margin-bottom: 0;
                    margin-inline-start: -10px;
                }

                .drop-down-selector-container {
                    padding: 10px 20px;
                }
            }

            .title {
                font-weight: 400;
                [dir='ltr'] &{
                    font-size:calc(11px + (12 - 11) * ((100vw - 320px) / (2800 - 320)));
                }
                [dir='rtl'] &{
                    font-size:calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
                }
            }
        }

        .left-side-header {
            .date-duration-container{
                margin-block-start: 1.4em;
            }
            .calendar-button {
                background: #03403A 0% 0% no-repeat padding-box;
                border-radius: 5px;
                outline: none !important;
                color: #73E4CC;
                // padding: 10px 15px;
                display: flex;
                align-items: center;
                gap: 12px;
                height: 2.5em;
                @media (max-width: 768px) {
                    padding: 8px;
                   .date {
                    display: none;
                   }
                }

            }

            .calendar-holder {
                position: relative;
                .triangle {
                    position: absolute;
                    width: 0;
                    height: 0;
                    top: 0;
                    left: 50%;
                    border-left: 12px solid transparent;
                    border-right: 12px solid transparent;
                    border-bottom: 12px solid $light-container-background;
                    z-index: 1000 !important;

                    @media (max-width: 768px) {
                        left: 15%;
                    }
                }

                .calendar {
                    position: absolute;
                    top: 12px;
                    z-index: 100 !important;
                    @media (max-width: 768px) {
                        right: -15%;
                    }

                    .date-picker-container {
                        width: 17em;
                        background-color: $light-container-background !important;
                    }
                }
            }
        }
    }

    .dual-line-content {
        display: flex;
        gap: 20px;
        height:80%;
        @media (max-width: 768px) {
            flex-direction: column;
            margin-top: 20px;
        }

        .cards-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            @media (max-width: 768px) {
                flex-direction: row;
                justify-content: center;
            }

            #card {
                color: #03403A;
                border-radius: 5px;
                text-align: center;
                padding: 6px 12px;
                max-width: 100%;

                @media (max-width: 768px) {
                    padding: 6px 20px;
                }
    

                .card-value {
                    font-size: 14px;
                    margin: 0;
                    line-height: 22px;
                    [dir="ltr"] & {
                        font-family: nunito_extraBold;
                    }
                    [dir="rtl"] & {
                        font-family: tajawal_medium;
                    }
                }

                .card-title {
                    font-size: 13px;
                    margin: 0;
                    // font-family: nunito_semibold;
                    line-height: 16px;;
                }
            }

            .sensor-eto {
                background-color: #6CABFF;
            }
    
            .sensor-etc {
                background-color: #D183DD;
            }

            .sensor-gdd {
                background-color: #BF688B;
            }

        }

        .chart-holder {
            width: 100%;
        }

        .footer-note {
            display: flex;
            justify-content: center;
            margin-top: 5px;
            [dir="ltr"] p {
                font-style: italic;
                // font-family: nunito_semibold;
            }
            
        }
    }
}

.weather-sensors-loader {
    height: 100%;
    .Loading-height {
        height: 100%;
    }
}