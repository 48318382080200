@mixin lowerShadow($neuo) {
  -webkit-appearance: none !important;
  $lower-shadow: 4px 4px 10px darken($neuo, 5%);
  box-shadow: $lower-shadow;
  -webkit-box-shadow: $lower-shadow !important;
}
@mixin shadow($neuo) {
  -webkit-appearance: none !important;
  $lower-shadow: 4px 4px 10px darken($neuo, 5%);
  $upper-shadow: -4px -4px 5px lighten($neuo, 7%);
  box-shadow: $upper-shadow, $lower-shadow;
  -webkit-box-shadow: $upper-shadow, $lower-shadow !important;
}

@mixin rgba-color($property: 'background-color', $hex-color, $opacity:0.8) {
  $red: red($hex-color);
  $green: green($hex-color);
  $blue: blue($hex-color);
  #{$property}: rgba($red, $green, $blue, $opacity);
}
