$card-border-color:  darken($main_layer,3%) !default;
$card-cap-color:     $primary-txt !default;
$card-bg:           darken($main_layer,3%) !default;

.card {
    border:none;
    border-radius: 5px !important;
    border-color:none;   
}
.card-header {
    border-bottom:none;
    background-color:transparent !important ;
    border-color: transparent !important;
       @extend .gadget-title ;
    letter-spacing: 0px;
    padding: 1rem 1.25rem !important;
    font-size:calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
}

@media (max-width: 990px){
    .card-header{
        padding: .7rem 1rem !important;
    }
    .card-body{
        padding: .7rem 1rem !important;
    }
}
.card-footer {
    background-color:$card-bg !important ;
    border-radius: 5px !important;
    border-color: $card-bg !important;
}    
.c-wrapper{
    background: $main_layer 0% 0% no-repeat padding-box !important;
    border: 0 !important;
    .col-sm-8,.col-sm-4,.col-sm-7,.col-sm-5,.col-sm-6,.col-sm-4,.col-sm-3,.col-sm-10,.col-sm-2,.col-sm-9,.col-sm-12,.col-sm-11,.col-sm-1 {
        padding-left: 6px ;
        padding-right: 6px ;
    }
    .dashboard-intial-row{
        padding-left: 10px !important;
        padding-right: 10px !important;
        margin-bottom: -10px !important;
        background: $main_layer 0% 0% no-repeat padding-box !important;
    }
    .dashboard-small-row{
        padding-left: 10px !important;
        padding-right: 10px !important;
        margin-bottom: -12px !important;
    }
    .small-card{
       height: 12em ;
    }
    .small-card#gaugeCard{
        height: 13em;
    }
    .xmedium-card{
        height: 14.85em;
    }
    .medium-card{
        height: 20.8em ;
    }
    .large-card{
        height: 34.4em ;
    }
    .xlarge-card{
       height:33.5em;
    }
    .xxlarge-card{
        height: 43em;
    }
    .xxxlarge-card{
        height: 90em !important;
    }
    .xxxxlarge-card{
        height: 118em !important;
    }
    @media (min-width: 576px){
        .dashboard-intial-row{
            .col-sm-9{
                max-width: 80% !important;
                flex:0 0 80% !important;
            }
            .col-sm-3{
                max-width: 20% !important;
            }
        } 
    }
    #gaugeCard{
        margin-bottom: 0.5rem;
        .card-header{
            margin: 0% !important;
            padding-bottom: 0% !important;
            padding-inline: 6% !important;
        }
        .card-body{
            padding: 0% !important;
            margin-top: 0% !important;
            margin-bottom: 0% !important;
            margin-right: 6%  !important;
            margin-left: 6%  !important;
        }
        .card-footer{
            margin: 0% !important;
            padding-top: 2% !important;
            text-align: center !important;
        }
    }
  }
  [dir='rtl'] .c-wrapper{
      .dashboard-small-row{
          padding-left: 10px !important;
          padding-right: 10px !important;
          margin-bottom: -12px !important;
      }
      .dashboard-initial-row{
        padding-left: 10px !important;
        padding-right: 10px !important;
        margin-bottom: -12px !important;
    }
  }
.first-card{
    padding-left: 0 !important;
}
[dir='rtl'] .first-card{
    padding-left: 6px !important;
    padding-right: 6px !important;
}
[dir='rtl'] .last-card{
    padding-right: 6px !important;
    padding-left: 6px !important;
}
@media (min-width:600px) and (max-width: 1000px){
    .c-wrapper{
        .card-footer{
            font-size: .6em !important;
        }
    }
}
@media (min-width:1000px) and (max-width: 1150px){
    .c-wrapper{
        .card-footer{
            font-size: .78em !important;
        }
    }
}
@media  (max-width: 600px){
    .c-wrapper{
        .dashboard-intial-row{
            padding-left: 10px !important;
            padding-right: 10px !important;
            margin-bottom: -10px !important;
            background: $main_layer 0% 0% no-repeat padding-box !important;
        }
        .dashboard-small-row{
            padding-left: 10px !important;
            padding-right: 10px !important;
            margin-bottom: 1px !important;
        }
        .card-footer{
            font-size: .6em !important;
        }
    }
}
.weather-forecast-cards{
    .small-card{
        height: 9.8em !important;
    }
    .large-card{
        height: 19.8em !important;
    }
}


@media (min-width:1680px){
    .weather-forecast-cards{
        .small-card{
            height: 9.8em;
        }
        .large-card{
            height: 19.8em;
        }
    }
    .medium-card{
        height: 23.8em;
    }
    .large-card {
        height: 37.8em;
    }
    .xlarge-card {
        height: 25em;
    }
    .c-wrapper{
        .xxlarge-card{
           height: 43em;
        }
    } 
    #gaugeCard{
        margin-bottom: .8rem;
    }
}
.mapD.xxlarge-card{
    height: 30.5em;
}
.dashboard-small-row{
    .xlarge-card{
        height: 37em;
    }
}
[dir='rtl'] .card-header{
    font-size:calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
    font-weight: 400;
}

