.avatar-dropdown-component{
    list-style-type: none;
    &.c-header-nav-items:has(#avatar-button) {
        background-color: transparent;
        border-radius: 50%;
        height: 33px;
        #avatar-button {
          color: $primary-txt !important;
          &.avatar-name-wrapper{
            width: 40px;
            height: 34px;
            border-radius: 50%;
            padding: 0 !important;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .avatar-image {
            // padding: 0.3em 0;
            font-size: 12px;
            letter-spacing: 1px;
            color: #73E4CC;
            outline: none;
            border-color: transparent;
            text-transform: uppercase;
          }
          .arabic-name{
            font-size: 14px;
          }
        }
        .nav-link {
          padding: 0.15rem 0.5rem !important;
          justify-content: center;
          align-items: center;
          vertical-align: middle;
        }
        .dropdown-header {
          width: 40px !important;
          background-color: $cta-txt !important;
          border: 0px !important;
          margin-left: 10px !important;
          color: $primary !important;
          height: 30px !important;
          min-width: 40px !important;
          font-size: .7em !important;
          padding: 0% !important;
          padding-top: 5px !important;
        }
        .dropdown-menu {
          width: 40px !important;
          min-width: 9rem !important;
          background-color: $cta-txt !important;
          border: 0px !important;
          margin-left: 10px !important;
          &.show {
            display: block !important;
            transform: translate3d(-90px, 54px, 0px) !important;
          }
        }
        .dropdown-item {
          color: $primary !important;
          height: 30px !important;
          min-width: 40px !important;
          font-size: calc(12px + (12 - 12) * ((100vw - 320px) / (2800 - 320)));
      
          img {
            padding-right: 5px !important;
            padding-left: 5px !important;
            width: 25px !important;
            height: 25px !important;
      
            &.img-top {
              display: inline !important;
            }
      
            &.img-down {
              display: none !important;
            }
          }
      
          &:hover {
            background: $bubble-selection 0% 0% no-repeat padding-box !important;
            color: $cta-txt !important;
            text-transform: uppercase !important;
            font-family: nunito_bold;
      
            .img-top {
              display: none !important
            }
      
            .img-down {
              display: inline !important;
            }
          }
        }
    }
    & .nav-items.dropdown.nav-item {
        .c-avatar~.dropdown-menu.show {
          box-shadow: rgb(3 51 45 / 73%) -3px 3px 6px;
          margin: -7px 7px !important;
        }
        &.show {
          .dropdown-menu.show {
            box-shadow: rgb(3 51 45 / 73%) -3px 3px 6px;
            margin: -7px 7px !important;
          }
        }
      }
      & .nav-items:hover {
        background-color: transparent;
      }

}
