.activity-table-header .Checkbox-Container{
    justify-content: center !important;
}
.activity-table .Checkbox-Container .Checkbox-View {
    background-color: $dark-button-background !important;
    box-shadow: $dark-button-box-shadow !important;
}

.activity-table .table-drop-down-view-container {
    bottom: 2% !important;
}