.FilterPanel-Container {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    height: inherit;
    overflow-y: auto;
    padding-top: 20px;

    .Tabs-Container {
        display: flex;
        // flex:1;
        flex-direction: row;
        justify-content: space-evenly;

        .Selected-Tab-View {
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
            border-radius: 5px;
            background-color: $primary;
            border: 2px solid $primary-txt;
            transition: 0.3s ease-out;
            outline: none !important;
        }

        .Tab-View,
        .Blocked-tab-view {
            position: relative;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
            border-radius: 5px;
            outline: none !important;
            background-color: $tab-background ;

            .Tab-Content {
                opacity: 0.5;
            }
        }

        .Tab-View:hover {
            background-color: $primary;
            border: 2px solid $primary-txt;
            transition: 0.3s ease-out;
        }

        .Blocked-tab-view:hover {
            display: block;
        }

        .Blocked-tab-view:hover:after {
            background-size: 500px 500px;
            background: $blocked-tab-background url("../../assets/icons/svg/constants/disabled.svg") no-repeat center;
            opacity: 0.6;
            position: absolute;
            left: 0;
            top: 0;
            content: "";
            width: 100%;
            height: 100%;
        }

        .Tab-Content {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;

            #sensor-icon {
                width: 90%;
                height: 90%;
            }

            #plot-icon {
                width: 65%;
                height: 65%
            }

            #schedule-icon {
                width: 60%;
                height: 60%
            }

            #report-icon {
                width: 65%;
                height: 65%
            }
        }
    }

    .FilterPanel-View-Content {
        padding-inline-start: 28px;
        padding-top: 20px;
        padding-inline-end: 28px;

        .Title {
            font-size: 0.8rem;
            text-transform: uppercase;
            color: $primary-txt;
        }

        .SubTitle {
            margin-top: -10px;
            font-size: 0.7rem;
        }

        .Specification-Container {
            .Program-View {
                background-color: #0D4944;
                height: 22rem;
                max-height: 22rem;
                overflow-y: scroll;
                padding-inline-start: 10px;
                padding-inline-end: 10px;
                padding-top: 10px;
                margin-inline-start: -11px;
                margin-inline-end: -11px;
                border-radius: 5px;

                .Content-Title {
                    color: $primary-txt;
                    font-size: 0.8rem;
                }

                .Content-Value {
                    font-size: 0.7rem;
                    margin-top: -10px;
                }

                .Seperator {
                    border-top: 1px solid;
                    flex-wrap: wrap;
                    opacity: 0.3;
                    margin-bottom: 9px;
                }

                .Table-View {
                    display: flex;
                    //  width: 100%;
                    flex-wrap: wrap;

                    .table-Container {
                        background-color: transparent;
                        // width: inherit;
                        height: inherit;
                        display: flex;
                        flex: 1;
                        flex-direction: column;
                        /* padding-bottom: 1em; */
                        flex-wrap: wrap;
                        border-radius: 5px;
                    }
                }
            }
        }

        .DateDuration-Container {
            .DateDuration-View {
                background-color: #0D4944;
                overflow-y: scroll;
                max-height: 25rem;
                padding-inline-start: 20px;
                padding-inline-end: 20px;
                padding-top: 20px;
                border-radius: 5px;
                margin-inline-start: -11px;
                margin-inline-end: -11px;

                .Footer {
                    float: right;
                    font-size: 0.7rem;
                }
            }
        }

        .calendar-button {
            position: relative;
            width: 13em;
            height: 2.5em;
            background: $calendar-background 0% 0% no-repeat padding-box;
            box-shadow: $dark-button-box-shadow;
            border-radius: 5px;
            outline: none !important;
            color: $primary;
            margin-inline-start: 10px;

            span {
                padding-inline-start: 1.7em;
                color: $primary;
                font-weight: 500;
                font-size: 0.92em
            }
        }

        .calendar-button::before {
            content: url('../../assets/icons/svg/MainMap/calendar.svg');
            width: 2em;
            height: 1.5em;
            z-index: 1000 !important;
            position: absolute;
            left: 0.3em;
            top: 0.75em;
        }

        .Calendar-Wrapper {
            position: relative;
            padding-top: 20px;

            .date-picker-container {
                width: 65%;
            }

            .triangle {
                position: absolute;
                width: 0;
                height: 0;
                top: 0.5em;
                left: 1.5em;
                border-left: 12px solid transparent;
                border-right: 12px solid transparent;
                border-bottom: 12px solid #196A63;
                z-index: 1000 !important;
            }
        }

        .Rules-Container {
            .TimeDuration-View {
                background-color: #0D4944;
                overflow-y: scroll;
                height: 25rem;
                margin-inline-start: -11px;
                margin-inline-end: -11px;
                padding-inline-start: 20px;
                padding-inline-end: 20px;
                padding-top: 20px;
                border-radius: 5px;
                padding-bottom: 20px;

                .calendar-button {
                    height: 3em;
                    color: #73E4CC;
                    margin: 0;

                    span {
                        padding-inline-end: 3.5em;
                    }
                }

                .calendar-button::before {
                    content: url('../../assets/icons/svg/sidebar/inactive/scheduler.svg');
                    left: 0.5em;
                    top: 0.75em;
                }

                .Timer-Wrapper {
                    padding-top: 20px;
                }
            }

            .Occurence-Wrapper {
                margin-top: 20px;
            }

            .Days-View {
                align-items: center;
                display: flex;
                flex-wrap: wrap;
                flex: 1;
                overflow-x: scroll;
                max-height: inherit;
            }

            ul {
                list-style-type: none;
                column-count: 3;
                column-gap: 10px;
                display: flex;
                flex-wrap: wrap;
                margin-inline-start: -2.5rem;
                // margin-top: 13px;
            }

            .Days-Wrapper {
                width: 100px;
                margin-block-end: 10px;
                margin-inline-end: 10px;
            }
        }

        .Summary-Container {
            .accordion-tab-body {
                display: block;
            }

            .Program-Specs-Summary {
                width: 100%;

                .Program-View {
                    padding-inline-start: 10px;
                    padding-inline-end: 10px;

                    // padding-top: 10px;
                    .Content-Title {
                        color: $primary-txt;
                        font-size: 0.8rem;
                    }

                    .Content-Value {
                        font-size: 0.7rem;
                        margin-top: -10px;
                    }

                    .Seperator {
                        border-top: 1px solid;
                        flex-wrap: wrap;
                        opacity: 0.3;
                        margin-bottom: 9px;
                        margin-top: -8px;
                    }

                    .Table-View {
                        display: flex;
                        //  width: 100%;
                        flex-wrap: wrap;

                        .table-Container {
                            background-color: transparent;
                            // width: inherit;
                            height: inherit;
                            display: flex;
                            flex: 1;
                            flex-direction: column;
                            /* padding-bottom: 1em; */
                            flex-wrap: wrap;
                            border-radius: 5px;
                        }
                    }
                }

                .SelectedGroup-Container {
                    width: 7rem;
                    height: 4rem;
                    border-radius: 5px;
                    background-color: rgb(27, 126, 117);
                    flex-direction: column;
                    position: absolute;
                    top: 13px;
                    left: -6rem;
                    right: 0px;
                    bottom: 0px;
                    z-index: 1;

                    &::before {
                        content: "";
                        position: absolute;
                        width: 1px;
                        height: 1px;
                        background-color: #03403A;
                        top: -0.4em;
                        border-left: 7px solid transparent;
                        border-right: 7px solid transparent;
                        border-bottom: 7px solid #1b7e75;
                        bottom: 0px;
                        left: 5.4rem;
                        right: 0px;
                    }

                }

                .SelectedGroup-Wrapper {
                    display: flex;
                    font-size: 0.7rem;
                    font-weight: 600;
                    justify-content: center;
                    // align-items: center;
                    cursor: pointer
                }

                .SelectedGroup-Seperator {
                    border-top: 1px solid;
                    flex-wrap: wrap;
                    opacity: 0.3;
                    margin-bottom: 9px;
                    margin-top: -8px;
                }
            }

            .Create-Another-Group {
                &:hover {
                    cursor: pointer;
                }

                margin-top: 10px;
                font-size: 0.8rem;
                text-transform: capitalize;
                color: #73E4CC;
            }

            .Groups-Container {
                width: 100%;
                margin-top: -20px;
                height: fit-content;
                .accordion-tab{
                    background-color: #065A5362 !important;
                    color: #46AAA0;
                    border: 1px solid #065A53;
                }
                .accordion-list {
                    list-style-type: none;
                    padding: 0;
                    width: 100%;
                }

                .accordion-tab-body {

                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    /* align-items: center; */
                    background-color: #003A35;
                    border: 1px solid #37998447;
                    overflow: scroll;
                    margin-bottom: -3px;
                    padding-block-start: 20px;
                    padding-top: 30px;
                }
            }

            .accordion-tab-body {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                /* align-items: center; */
                background-color: #003A35;
                border: 1px solid #37998447;
                overflow: scroll;
                margin-bottom: -3px;
                padding-block-start: 20px;
            }
        }

        .Title-Wrapper {
            display: flex;
            justify-content: space-between;
        }

    }

    .Button-View {
        align-self: center;
        display: flex;
        flex-direction: column-reverse;
        flex: 1;
        margin-bottom: 10px;
    }

    @mixin shadow($neuo) {
        -webkit-appearance: none !important;
        $lower-shadow: 4px 4px 10px darken($neuo, 5%);
        $upper-shadow: -4px -4px 5px lighten($neuo, 7%);
        box-shadow: $upper-shadow, $lower-shadow;
        -webkit-box-shadow: $upper-shadow, $lower-shadow  !important;
    }

    .neuo {
        border-radius: 5px;
        border-style: hidden;
        background: $main_layer;
        @include shadow($main_layer);
        cursor: pointer;
    }
}

.CreationFailed-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    flex: 1;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 0.9em;
    position: relative;
}

.CreationFailed-Button {
    bottom: -55px;
    position: relative;
}



.Button-View {
    align-self: center;
    display: flex;
    flex-direction: column-reverse;
    flex: 1;
    margin-bottom: 10px;
}

@mixin shadow($neuo) {
    -webkit-appearance: none !important;
    $lower-shadow: 4px 4px 10px darken($neuo, 5%);
    $upper-shadow: -4px -4px 5px lighten($neuo, 7%);
    box-shadow: $upper-shadow, $lower-shadow;
    -webkit-box-shadow: $upper-shadow, $lower-shadow  !important;
}

.neuo {
    border-radius: 5px;
    border-style: hidden;
    background: $main_layer;
    @include shadow($main_layer);
    cursor: pointer;
}

.CreationFailed-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    flex: 1;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 0.9em;
    position: relative;
}

.CreationFailed-Button {
    bottom: -55px;
    position: relative;
}

.Cancel-Program-Modal-Button {
    label {
        font-size: 0.7rem !important;
    }
}


.Map-Container {
    padding-inline-start: -28px;
    position: relative;

    .Map-View {
        background-color: #0D4944;
        margin-inline-start: -11px;
        margin-inline-end: -11px;
        border-radius: 5px;
        padding-bottom: 10px;
        height: 25rem;
        max-height: 25rem;
        overflow-y: scroll;
        display: flex;
        flex: 1;
        position: relative;
        flex-direction: column;

        .Map-Wrapper {
            position: relative;
            display: flex;
            flex: 1
        }

        .Selected-Valves {
            display: flex;
            flex: 2;
            flex-direction: column;

            // background-color: red;
            .Valves-Container {
                margin: 20px;

                ul {
                    list-style-type: none;
                    column-count: 3;
                    column-gap: 10px;
                    display: flex;
                    flex-wrap: wrap;
                    margin-inline-start: -2.5rem;
                    // overflow-y: scroll;
                    // max-height: inherit;
                    // margin-top: 13px;
                }

                li {
                    margin-bottom: 20px;
                }

                .Valve-Wrapper {
                    border-radius: 5px;
                    display: flex;
                    justify-content: space-evenly;
                    width: fit-content;
                    height: 2rem;
                    background: #114A45 0% 0% no-repeat padding-box;
                    box-shadow: -2px -2px 6px #46AAA04B;
                    border-radius: 18px;
                    opacity: 1;

                    //  background-color:red;
                    .Valve-Seperator {
                        border-right: 1px solid;
                        opacity: 0.3;
                        padding-left: 10px;
                        padding-right: 10px;
                    }

                    .Valve-Name {
                        margin-inline-start: 10px;
                        margin-top: 5px;
                        font-size: 0.8rem;
                    }

                    .De-Select-Valve {
                        margin-inline-start: 10px;
                        margin-inline-end: 10px;
                        margin-top: 5px;
                        font-size: 0.8rem;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

[dir='rtl'] .FilterPanel-Container {
    .triangle {
        right: 1.5em;
        left: inherit !important;
    }
}