.CropPhase-Card{
    height: 80em !important;
}

.crop-phase-card-container{
    min-height:50em;
}

.CropViewDistribution{
    display: flex;
    width: 100%;
    flex-direction: row;
    height: 100%;
    border: inherit;
    justify-content: 'flex-start';
    align-items:flex-start;
    @media (max-width: 600px) {
        flex-direction: column;
    }
    .DistributionSeperator{
        margin-block: 33px 20px;
        flex-wrap: wrap;
        opacity: 0.3;
        flex:0.2;
        border-right: 2px  dashed  ;
        height:450px ;
    }
}

#season-summary-tooltip {
    background-color: transparent;
    font-size: 14px;
    @extend .semibold-font;
    opacity: 1;

    .tooltip-inner {
        background-color: #196A63;
        text-align: unset;
        padding: 0;
        max-width: 300px;
        color: unset;
    }

    .tooltip-arrow::before {
        border-inline-color: #196A63 !important;
    }   
    
    .season-summary {
        border-radius: 5px;
    }
}

.season-summary {
    padding: 20px;
    background-color: #196A63;
    font-size: 0.8rem;

    .season-name {
        line-height: 22px;
    }

    .primary-txt {
        color: $primary-txt ;
    }

    .info {
        width: 50%;
        text-align: unset;
        margin-bottom: 12px;

        &:first-child{ 
            width: 100%;
        }

        .value {
            text-transform: capitalize;
        }

        .name {
            text-transform: unset;
            @extend .semibold-font
        }

    }

    p {
        margin-bottom: 0;
    }
}

.info {
    text-align: center;
    .value {
        color: $primary-txt;
    }
    .name {
        line-height: 16px;
        text-transform: lowercase;
    }
}

.crops-view {
    .fade{
        margin-inline-end: 15px;
    }
    .plot-seasons {
        .header-container {
            display: flex;
            gap: 12px;
            align-items: center;
            @media (max-width: 600px) {
                padding-top: 20px;
               p {
                margin-bottom: 0;
               }
            }
        }
        .plot-seasons-cards-container {
            display: flex;
            gap: 10px;
            padding-top: 0;
    
            .plot-seasons-card {
                background: #003A35;
                border-radius: 10px;
            }
    
            .filters-card {
                @extend .plot-seasons-card;
                width: 20%;
                @media (max-width: 1000px) {
                    width: 25%;
                }
    
                @media (max-width: 600px) {
                    display: none;
                }
    
                .Checkbox-View {
                    background: #003A35 0% 0% no-repeat padding-box; 
                }
                .Checkbox-Title {
                    font-size: 0.8rem;
                }
    
                .plot-filters-header {
                    padding-block: 30px 20px;
                    padding-inline: 20px 10px;
                    border-bottom: 1px solid #0B827833;
                }
    
                .plots-container {
                    padding: 20px;
                    max-height: 700px;
                    overflow-y: scroll;
                    .Checkbox-Container {
                        padding: 10px 0 40px;
                    }
                }
            }
    
            .seasons-card {
                @extend .plot-seasons-card;
                padding: 30px;
                width: 80%;
                display: flex;
                flex-direction: column;
    
                @media (min-width: 600px) and (max-width: 1000px)  {
                    width: 75%;
                }
    
                @media (max-width: 600px) {
                    width: 100%;
                    padding: 15px;

                    ::-webkit-scrollbar {
                        height: 4px !important;
                        width: 4px;
                        display: inline-block;
                    }
                
                    ::-webkit-scrollbar-corner {
                        background: transparent
                    }
                
                    ::-webkit-scrollbar-thumb {
                        background: #256f68;
                        border-radius: 10px;
                     //   width: 2px !important;
                        cursor: pointer !important;
                    }
                
                    ::-webkit-scrollbar-thumb:hover {
                        background: #348c83;
                    }
                }
    
                .header-container {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                }
    
                .new-season-btn {
                    padding: 10px 12px;
                    margin-top: 0;
                    font-size: 0.8rem;
                    height: auto;
                    text-wrap: nowrap;
    
                    @media (max-width: 600px) {
                        font-size: 12px;
                        padding: 8px;
                    }
                }
                
                .sensor-details-buttons-container {
                    padding-inline-start: 0;
                    font-size: 0.8rem;
    
                    @media (max-width: 600px) {
                        font-size: 12px;
                    }
                }
    
                .seasons-container {
                    display: flex;
                    gap: 20px;
                    margin-top: 20px;
                    overflow: scroll;
    
                    @media (max-width: 600px) {
                        flex-direction: column;
                        min-width: 100%;
                        max-height: 600px;
                    }
                }

                .empty-data-state-container {
                    padding: 20px 10px;
                    margin-top: 0;
                    flex-grow: 1;

                    .subtitle {
                        width: auto;
                        margin-bottom: 0;
                    }

                    img {
                        width: 200px;
                    }
                }
            }
    
            .plot-card {
                padding: 20px 20px 15px;;
                background: #04514A;
                border-radius: 12px;
                position: relative;
                font-size: 0.8rem;
    
                @media (hover: hover) {
                    &:hover {
                        background: #075D55;
                        border: 1px solid #C4FFF9;
                    }
                }
                
                .plot-name {
                    color: $primary-txt;
                    margin-inline-end: 8px;
                }
    
                .plot-info-container {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
    
                    .info {
                        margin-bottom: 11px;
                    }
                }
    
                .view-btn {
                    :hover {
                        svg {
                            path {
                                fill: #0B8278;
                            }
                        }
                    }
                }
    
            }
    
            .actions {
                display: flex;
                gap: 15px;
            }
        }
    }

    .badge-container {
        padding: 4px 16px;
        border-radius: 13px;
        display: flex;
    }

    .primary-txt {
        color: $primary-txt ;
    }

    .expand-icon {
        svg {
            width: 19px;
            height: 19px;
        }
    }

    .gain {
        font-size: 15px;
        line-height: 22px;
        color: #7BC792;

        p {
            margin-inline-start: 5px;
        }
        
        &.negative {
            color: $error-message;

            svg {
                transform: rotate(180deg);
            }
        }
    }

    .season-card {
        padding: 15px 20px 10px;
        background: $main_layer;
        border-radius: 10px;
        width: 290px;
        cursor: pointer;
        font-size: 0.8rem;
        flex-shrink: 0;

        ::-webkit-scrollbar {
            display: none !important;
        }

        @media (max-width: 600px) {
            min-width: 100%;
            max-width: 100%;
        }

        p {
            margin-bottom: 0;
        }

        .season-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .season-name {
                font-size: 0.8rem;
            }

        }

        .info-container {
            display: flex;

            .info {
                width: 50%;
                .name {
                    line-height: 18px;
                }
            }
        }

        .plots-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 12px;
            height: 450px;
            overflow-y: scroll;
        }

        .add-plot-container {
            padding: 20px 24px;
            background: #04514A;
            border-radius: 8px;
            text-align: center;
            color: $primary;

            &:hover {
                background: #0E605A 0% 0% no-repeat padding-box;
                border: 1px solid #C4FFF98C;
            }
        }
    }

    .plot-season-card {
        padding: 20px 20px 15px;
        background: #003A35;
        border-radius: 12px;

        p {
            margin-bottom: 0;
        }

        @media (hover: hover) {
            &:hover {
                background: #075D55 0% 0% no-repeat padding-box;
                border: 1px solid $primary-txt;
            }
        }

        .season-name {
            font-size: 16px;
        }
    }
    
    .selected-plot-seasons {
        border-radius: 5px;
        .card-body {
            padding-top: 0;

            .selected-plot-seasons-content {
                border-radius: 5px;
                padding: 20px;
                background: #05342F 0% 0% no-repeat padding-box;
            }
            
            .cards-container {
                display: flex;
                gap: 22px;
                flex-wrap: wrap;
                margin-top: 15px;
                
    
                @media  (max-width: 1200px)  {
                    gap: 12px;
                }

                @media (max-width: 600px) {
                    max-height: 440px;
                    overflow-y: scroll;
                }
    
                .item {
                    width: 18%;
                    min-height: 227px;
                    cursor: pointer;
    
                    @media (min-width: 600px) and (max-width: 1200px)  {
                        width: 22%;
                    }
                    @media (max-width: 600px)  {
                        width: 100%;
                    }
                }
    
                .add-new-season {
                    padding: 20px 20px 15px;
                    background: #0b4541;
                    border-radius: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $primary;
                    flex-grow: 0;
                    border: 1px solid #46aaa070;
    
                    &:hover {
                        background: #0B8278B3 0% 0% no-repeat padding-box;
                        border: 1px solid $primary-txt;
                        color: $primary-txt;
                    }
                }
            }
            
            .pagination-row {
                margin-top: 22px;
            }
        }

        .sensor-details-buttons-container {
            padding-inline-start: 0;
            @media (max-width: 600px) {
                button {
                    margin-inline-end: 0;
                    padding-inline-end: 0;
                }
            }
        }
    }

    .season-summary-modal {
        .modalContainer {
            background-color: #114A45;
            padding: 10px;

            .closeButton {
                background-image: none;

                svg {
                    width: 19px;
                    height: 19px;
                }
            }
        }
        
        .season-summary {
            padding-top: 0;
            background-color: #114A45;
        }
    }

    .plot-status-card {
        padding: 12px;
        border-radius: 5px;
        font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.danger {
            background-color: #FF5B33;
            color: #FFFFFF;
        }
        &.warning {
            background-color: $warning-bg;
            color: $warning-txt;
        }
        &.normal {
            background-color: $primary;
            color: #0A645C;
        }
    }

    .map .mapboxgl-popup-content {
        td:nth-child(2) {
            padding-inline-end: 8%;
        }
    }

    .crop-empty-state {
        margin-top: 0;
        padding-block: 15px;
        padding-inline: 20px 15px;
        display: flex;
        flex-direction: row;
        gap: 30px;
        align-items: center;
        border-radius: 5px;
        border: 1px solid #165f59b0;
        @media (max-width: 600px) {
            flex-direction: column;
            gap: 15px;
        }
        .contact-us-btn {
            display: flex;
            align-items: center;
            gap: 5px;
            background-color: #73E4CC;
            color: #165F59 !important;

            svg {
                height: 18px;
                width: 18px;
                path {
                    fill: #165F59 ; 
                }
            }
           

            &:hover {
                background-color: #19B392;
                color: #C4FFF9 !important;
                path {
                    fill: #C4FFF9 ;
                }
            }
        }
    }
}

.delete-season-modal {
    .empty-data-state-container {
        padding-inline: 70px !important;
        text-align: center;

        @media (max-width: 600px) {
            padding-inline: 50px !important;
        }
    }
    .proceed-button {
        height: auto;
        padding: 10px 22px;
    }
}

.FarmCropDetailsDis{
    width: 65%;
    @media (max-width: 600px) {
        width: 100%;
    }
    .crop-details-list{
        display: flex;
        width: 100%;
        gap: 2em;
        overflow-x: scroll;
        cursor: grab;

        &::-webkit-scrollbar {
            height: 7px !important;
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: transparent;
        }
    
        /* Handle */
        &::-webkit-scrollbar-thumb {
        background: #256f68;
        border-radius: 10px;
        width: 2px !important;
        cursor: pointer !important;
        }
    
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
        background: #348c83;
        }
        
        &::-webkit-scrollbar {
        display: inline-block !important;
        }
        .card-with-icon-container{
            display: flex;
            flex-wrap:wrap;
            position:relative;
            min-width: 300px;
            padding-bottom: 0;
            margin-top: 2em;
            border-radius:5px;
            border:1px solid #003A35;
            background-color:#003A35;
            justify-content: center;
            .icon-container{
                left:unset;
                top:-1.7em;
                position:absolute;
                width:3.5em;
                height:3.5em;
                border-radius:1.75em;
                background-repeat: no-repeat;
                background-size: 2em 2em;
                background-position: center center;
                border: 1px solid $secondary-txt;
            }

            .Header-Seperator {
                margin-block: 5px 15px;
            }

            p {
                margin: 0;
            }

            .Content{
                flex-wrap: wrap;
                width: 100%;
                padding-inline: 15px ;
    
                .plot-cards-container {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    height: 300px;
                    overflow-y: scroll;
                }
                .empty-data-state-container {
                    height: 40em ;
                }

                .pagination-row {
                    margin-top: 8px;
                }
    
            }
        }
        .Header{
            text-align: center;
            color: #C4FFF9;
            text-transform: uppercase;
            font-weight: bolder;
            font-size: 0.8em;
            margin-top: 34px;

            .phase {
                text-transform: capitalize;
                color: #46aaa0;
                font-size: 13px;
            }
        }
        .Header-Seperator{
            border-top: 1px solid;
            width: 100%;
            opacity: 0.3;
            margin-inline-start: 20px;
            margin-inline-end: 20px;
            margin-bottom: 9px;
        }
    }
}

.FarmDetailsDis{
    height: 100%;
    width: 35%;
    @media (max-width: 600px) {
        width: 100%;
    }
    .Content{      
        height: 100%;
        justify-content: space-evenly;
        flex-direction: row;
        .Chart-View{
            flex:2;
            .Title{
                margin-inline-start: -20px;
                margin-bottom: 30px;
                display: flex;
                height:10%;
                width: 100%;
                flex-direction: row;
                justify-content: space-evenly;
                .Plots{
                    text-align: center;
                }
                .Plots-Count{
                    text-align: center;
                    .number{
                        color: $primary_txt;
                        font-weight: bold;
                        font-size: 2.3rem;
                        color: #C4FFF9;
                        margin-top: -16px;
                        font-weight: bold;
                    }
                }
                .Types{
                    text-align: center;
                }
                .Types-Count{
                    text-align: center;
                    .number{
                        font-size: 2.3rem;
                        color: #C4FFF9;
                        margin-top: -16px;
                        font-weight: bold;                    
                    }
                }
                .Seperator{
                    border-right: 1px  solid  ;
                    opacity: 0.3;
                    margin-inline-start:20px ;
                    margin-inline-end:20px ;
                }
                .Text{
                    margin-top: -25px;
                    font-size: 13px;
                }
            }
        }
    }
}

.CropPhase-Container{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    flex: 1;
    .TimeLine{
        display: flex;
        margin-bottom: 10px;
        background-color: #003A35;
        flex-direction: row;
        .Container{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex: 0.7;
            .Crop-Image-View{
                width: 70%;
                height: 300px !important;
                border-radius: 10px;
                position: relative;
                margin-bottom: 2%;
                margin-top: -5%;
                display: flex;
                flex-direction: column;
                .no-current-phase{
                    margin-block: 35%;
                    text-align: center;
                    margin-inline: 8%;
                    span{
                        color: #C4FFF9;
                        text-transform: uppercase;
                    }
                }
                .Line{
                    position: absolute;
                    left: -10%;
                    bottom: 5%;
                    right: -10%;
                    .Scroll-View{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        width: 100%;
                        overflow-y: scroll;
                        .Header{
                            border: 2px solid #65AAA4;
                            background-color: #65AAA4;
                        }
                        .Footer{
                            border: 2px solid #04403B;
                            background-color: #04403B;
                        }
                        .Footer,.Header{
                            display: flex;
                            flex-direction: row;
                            height: 10px;
                            width: 40px;
                            border-radius: 10px;
                            border-left-width: 0px;
                            justify-content: center;
                            align-items: center;
                        }
                        .content{
                            border-top-width: 1px;
                            width: 37px;
                            align-items: stretch;
                            border-top-style: dashed;
                        }
                        .Icon-Style{
                            width: 20%;
                            height: 7px;
                            margin-inline-start: 2px;
                            background-color: #03403A;
                            border-radius: 10px;
                            border: 2.5px solid #14766B;
                        }
                        .List-Container{
                            display: flex;
                            flex: 1;
                            flex-direction: row;
                            padding: 0%;
                            margin: 0%;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            .Seperator{
                                display: flex;
                                flex-direction: column;
                                height: 10px;
                                width: 40px;
                                border: 2px solid #04403B;
                                border-left-width: 0px;
                                justify-content: center;
                                align-items: center;
                            }
                            .Circle-Wrap{
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                text-align: center;           
                                margin-top: 5%;
                                .Circle{
                                    border: 2.5px solid;
                                    height: 28px;
                                    width: 25px;
                                    border-radius: 30px;
                                }
                            }
                            p{
                                font-size: 0.7em;
                                font-weight: bold;
                                margin: 0%;
                                display: inline-block;
                                vertical-align: middle;
                            }
                        }
                    }
                }
            }
        }
    
        .Description{
            flex:1.3;
            display: flex;
            width: 100%;
            height: 100%;
            flex-direction: row;
            p{
                margin-bottom: 2%;
            }
            .Content-Seperator{
                opacity: 0.3;
                border-left: 2px dashed;
                height: 18em;
                display: flex;
                flex: 0.1;
                margin-top: 12px;  
            }
            .Crop-Content{
                display: flex;
                flex-direction: column;
                flex: 3;
                margin-top: 2%;
                .Crop-Header{
                    color: #C4FFF9;
                    text-transform: uppercase;
                    font-weight: bolder;
                    font-size: 0.8em;
                    width: 100%;
                }
                .Crop-Header-Seperator{
                    border-top: 1px solid;
                    opacity: 0.3;
                    margin-inline-end: 20px;
                    margin-bottom: 9px;
                }
                .Crop-Body{
                    display: flex;
                    flex-direction: row;
                    margin-top: 2%;
                    .SubTitle{
                        margin-inline-end: 18%;
                    }
                }
                .crop-added-details-container{
                    display: flex; 
                    align-self: flex-end;
                    margin-inline-end: 2%;
                    flex-direction: column;
                }
            }
        }
    }

    .Content{
        flex: 2 1;
        background-color: #05342F;
        border: 1px solid #46AAA040;
        padding: 20px;
        flex-wrap: wrap;
        display: flex;
        flex-direction: column;
        .Tabs-View{
            display: flex;
            .Tab-Wrapper{
                margin-inline-start: 20px;
                p{
                    font-size: 0.9em;
                }
                .selectedTabBorder{
                    border-top-width: 2px;
                    border-top-style: solid;
                    margin-top: -8px;
                    border-top-color: #73E4CC;
                 }
            }
         
        }
    }

    .OptimalReading-Container{
        display: flex;
        flex-direction: column;
        // width: 100%;
        height: 100%;
        flex: 1 1;
        margin-top: 20px;
        margin-inline-start: 20px;
        .Header{
            color: $primary_txt;
            text-transform: uppercase;
            font-weight: 400;
            font-size: 0.9em;      
        }
        .Reading-List{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            max-height: 20em;
            overflow-x: scroll;
            .Item-Wrapper{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-inline-start: 5%;
                margin-top: 18px;
            }
        }
        .Averagre-StatusBar{
            width: 60px;
            height: 4px;
            border-radius: 10px;
            margin-bottom: 12%;
            
        }
        // .List-Wrapper{
        //     display: flex;
        //     justify-content: center;
        //     /* align-items: center; */
        //     /* text-align: center; */
        //     flex-wrap: wrap;
        //     width: 100%;
        //     max-height: fit-content;
        //     overflow-y: scroll;
        //     .col-sm-2 {
        //         padding-left: 6px !important;
        //         padding-right: 6px !important;
        //         // margin-inline-start: 30px !important;
        //     }
        //     .Averagre-StatusBar{
        //         width: 60px;
        //         height: 4px;
        //         border-radius: 10px;
        //         margin-bottom: 12%;
                
        //     }
        // }
        .Average-Reading-Container{
            margin-top: 3%;
            .empty-data-state-container {
                padding: 2em !important;
            }
        }
    }
 
    .Diseases-Container{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        flex: 1 1;
        margin-top: 20px;
        flex-wrap: wrap;
        // background-color: black;
        .Disease-Header-List{
        background-color: #02312C;
        box-shadow: -2px -2px 5px #0b837780;
        border-radius: 60px;
        width: 3%;
        height: 28px;
        margin-top: 5px;
        p{
            text-align: center;
            letter-spacing: 0px;
            color: #73E4CC;
            text-transform: uppercase;
            font-size: 1.3em;
            /* font-weight: bold; */
            margin-top: -2px;
        }
        }
        .Diseases-Item-Wrapper{
            display: flex;
            width: 100%;
            // justify-content: space-evenly;
            align-items: flex-start;
            margin-bottom: 0.8em;
            padding-bottom: 10px;
            gap: 15px;
            overflow-x: scroll;
            cursor: grab;
            &::-webkit-scrollbar {
                height: 7px !important;
            }
            
              /* Track */
            &::-webkit-scrollbar-track {
            border-radius: 10px;
            background: transparent;
            }
        
            /* Handle */
            &::-webkit-scrollbar-thumb {
            background: #256f68;
            border-radius: 10px;
            width: 2px !important;
            cursor: pointer !important;
            }
        
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
            background: #348c83;
            }
            
            &::-webkit-scrollbar {
            display: inline-block !important;
            }

            .Disease-Wrapper{
                height: 2.5rem;
                border-radius: 23px;

                box-shadow: -2px -2px 5px #0b837780;
                border: 1px solid transparent;
                flex: 0 0 auto;
                &:hover{
                    background: #73E4CC 0% 0% no-repeat padding-box;
                    border: 1px solid #C4FFF9;
                    // border-radius: 37px;
                    cursor: pointer;
                    // opacity: 1;

                    p{
                        padding-inline-start: 20px;
                        padding-inline-end: 20px;
                        margin-top: 5px;
                        color:#165F59;
                        font-weight: bolder;
                        font-size: 0.7em;
                    }
                }
                
                // background-attachment: #02312C;
                border-radius: 23px;
                box-shadow: -2px -2px 5px #0B837780;
                p{
                    padding-inline-start: 20px;
                    padding-inline-end: 20px;
                    padding-top: 7px;
                    font-size: 0.7em;
                    color: #73E4CC;
                    text-transform: uppercase;
                    font-weight: bolder;            
                    margin-top: 5px;
                }
            }

            
        }

        .Diseases-Content{
            display: flex;
            flex: 9;
            flex-direction: row;
            background: #022C27 0% 0% no-repeat padding-box;
            border: 1px solid #46AAA052;
            @media (max-width: 600px) {
                flex-direction: column;
            }
            .Header{
                p{
            color: #C4FFF9;
            text-transform: uppercase;
            font-weight: 400;
            font-size: 1em;
        
                }
            // width: 100%;
            align-self: flex-start;
            margin-inline-start: 20px;
            margin-top: 20px
            
            }
            .Body{
                .carousel {
                    position: relative;
                    /* display: flex; */
                    align-self: flex-start ;
                }
                // justify-content: center;
                align-items: center;
                display: flex;
                flex: 1;
                margin-inline: 20px;
                margin-top: 15px;

            }
            .Accordion-Container
            {
                // background-color: white;

                display: flex;
                flex-direction: row;
                padding-inline-end: 20px;
                @media (min-width: 601px) {
                    width: 70%;
                }
                .accordion-list {
                    list-style-type: none;
                    padding: 0;
                    width: 100%;
                    margin-inline-start: 20px;
                    /* justify-content: center; */
                    display: flex;
                    margin-top: 70px;
                    flex-direction: column;
                    @media (max-width: 600px) {
                        margin-top: 20px;
                    }
                }
                .Content-Seperator{
                    opacity: 0.3;
                    border-left: 2px dashed;
                    height: 92%;
                    display: flex;
                    flex: 0.1;
                    margin-top: 10px;           
                }
                }
            .Carousel-Container
                {
                    display: flex;
                    //flex: 1.2 1;
                    // justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    padding-bottom: 1em;
                    @media (min-width: 601px) {
                        width: 30%;
                    }
                    .Carousel-Image{
                        width: 100%;
                        height: 300px;
                    }
                }
        }
        
    }

    .Fertigation-Container{
        display: flex;
        width: 98%;
        /* height: 100%; */
        flex-wrap: wrap;
        flex: 1 1;
        margin-top: 20px;
        // position: relative;
        /* margin-inline-start: -20px; */
        .empty-data-state-container {
            height:40em;
        }
        .table-Container {
            background-color: transparent;   
            width: inherit;
            height: inherit;
            display: flex;
            flex: 1;
            flex-direction: column;
            /* padding-bottom: 1em; */
            flex-wrap: wrap;
            border-radius: 5px;
        }
       .pagination-container {
            display: flex;
            list-style-type: none;
            position: absolute;
            bottom: 1em;
            /* right: 10px; */
            left: -3em;
            padding-inline-start: 14px !important;
        }
        [dir=ltr].New-Record-Row{
            width:inherit ;
            background-color: #003A35;
            height: 3.5em;
            // margin-top: -75px;
            width: 100%;
            p{
                color:#73E4CC;
                font-weight: 400;
                font-size: 1em;
                text-transform: uppercase;
                margin-top: 15px;
                margin-inline-start: 10px;
            }
        }
        [dir=rtl].New-Record-Row{
            width:inherit ;
            background-color: #003A35;
            height: 3.5em;
            // margin-top: -87px;
            width: 100%;
            p{
                color:#73E4CC;
                font-weight: 400;
                font-size: 1em;
                text-transform: uppercase;
                margin-top: 15px;
                margin-inline-start: 10px;
            }
        }
    }
    
    .Program-Container{
        display: flex;
        width: 100%;
        height: 100%;
        flex: 1 1;
        flex-direction: row;
        padding-bottom: 2em;
        .cards-list{
            display: flex;
            flex-direction: column;
            flex:.9;
            padding-top: 0%;
            .card-with-icon-container {
                margin-top: 3.5em;
                display: flex;
                flex-wrap: wrap;
                width: 16em;
                height: 8em;
                padding-bottom: 1em;
                .icon-container{
                    left:6em;
                }
                .content-container{
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    flex-wrap: wrap;
                    background: #05342F 0% 0% no-repeat padding-box;
                    box-shadow: -4px -2px 7px #0B837765;
                    border-radius: 5px;
                    opacity: 1;
                    width: 16em;
                    height: 8em;
                    .summary{ 
                        font-size:1em;
                        color:$primary ;
                        font-weight:400, 
                    }
                }
                .content-container-selected{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    background: #73E4CC 0% 0% no-repeat padding-box;
                    box-shadow: -4px -2px 7px #0B837765;
                    border: 3px solid #C4FFF9;
                    border-radius: 5px;
                    opacity: 1;
                    width: 16em;
                    height: 8em;
                    .summary{ 
                        font-size:1em;
                        color:$cta-txt ;
                        text-transform: uppercase;
                        font-weight:600, 
                    }
                }
            }
        }
        .Seperator{
            display: flex;
            flex:.1;
            opacity: 0.3;
            // margin-inline-start:20px ;
            // margin-inline-end:20px ;
            border-left: 2px dashed;
            height: 95%;
            display: flex;
            margin-top: 1em;
            // margin-inline-start: 2%;
        }
        .programs-list-container{
            display: flex;
            flex-direction: column;
            flex:4 1;
            margin-inline-start:1%;
            margin-inline-end:2%;
            margin-top: 2%;
            width: 60%;
            .programs-names-list{
                .NamesList-Container
                {
                    overflow-x:scroll;
                    display: flex;
                    flex:1;
                    list-style-type:none;
                    padding-inline-start: .3em;
                    padding-bottom: .3em;
                    min-width: 55%;
                    overflow-y: scroll;
                    .Header-List{
                        background-color: #02312C;
                        box-shadow: -2px -2px 5px #0b837780;
                        border-radius: 60px;
                        width: 2.2em;
                        height: 2.2em;
                        margin-top: 5px;
                        align-items: center;
                        padding-inline-start: .7em;
                        padding-inline-end: 1.5em;
                        &:hover{
                            background: #73E4CC 0% 0% no-repeat padding-box;
                            border: 1px solid #C4FFF9;
                            cursor: pointer;
                            p{
                                color:#165F59;
                                font-weight: bold;
                            }
                        }
                        p{
                            text-align: center;
                            letter-spacing: 0px;
                            color: #73E4CC;
                            text-transform: uppercase;
                            font-size: 1.3em;
                            margin-top: -2px;
                        }
                    }
                    .Item-Wrapper{
                        display: flex;
                        // width: 100%;
                        align-items: flex-start;
                        .Wrapper,#Wrapper-selected{
                            height: 2.2em;
                            width: max-content;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-top: 5px;
                            border-radius: 23px;
                            margin-inline-start: 15px;
                            box-shadow: -2px -2px 5px #0b837780;
                            padding-inline-end: 10px;
                            &:hover{
                                background: #73E4CC 0% 0% no-repeat padding-box;
                                border: 1px solid #C4FFF9;
                                cursor: pointer;
                                p{
                                    color:#165F59;
                                    font-weight: bold;
                                }
                                circle{
                                    fill:#05342f,
                                }
                            }
                            p{
                                padding-inline-start: 20px;
                                padding-inline-end: 10px;
                                font-size: 0.7em;
                                color: #73E4CC;
                                text-transform: uppercase;
                                font-weight: 600;    
                                margin-bottom: 0 !important;        
                            }
                        }
                    }
                }
            }
            .programs-content{
                display: flex;
                flex-direction: column;
                margin-top: 2em;
                background: #022C27 0% 0% no-repeat padding-box;
                // border: 1px solid #46AAA052;
                min-height: 30em;
                border-radius: 4px;
                padding-bottom:8em;
                .triangle{
                    position: absolute;
                    width: 0; 
                    height: 0; 
                    border-left: 12px solid transparent;
                    border-right: 12px solid transparent;    
                    border-bottom: 12px solid #022C27;
                    z-index: 1000 !important;
                    margin-top: -12px;
                    
                }
                .program-description-container{
                    display:flex;
                    flex-direction:column;
                    margin: 1.2em;
                    .program-description-header{
                        display:flex;
                        flex-direction:row;
                        justify-content: space-between;
                        margin-bottom: .5em;
                        .program-name{
                            text-transform: uppercase;
                            color: #C4FFF9;
                            font-weight: 600;
                        }
                        .button-container{
                            background-color: #05342F;
                            background: #022C27 0% 0% no-repeat padding-box;
                            box-shadow: -2px -2px 5px #0B837780;
                            border-radius: 5px;
                            opacity: 1;
                            width:2em;
                            height:2em;
                            margin-right: .3em;
                            margin-left: .3em;
                            display: flex;
                            align-items: center;
                            svg{
                                width:1.1em;
                                height:1.2em;
                                display: block;
                                margin: 0 auto;
                                text-align: center;
                            }
                            &:hover{
                                background: #73E4CC 0% 0% no-repeat padding-box;
                                border: 1px solid #C4FFF9;
                                cursor: pointer;
                                path {
                                    fill: #022C27;
                                }
                            }
                        }
                    }
                    .program-description-content{
                        display:flex;
                        flex-direction:row;
                        font-size: .85em;
                        .program-description-content-column{
                            display:flex;
                            flex-direction:column;
                            margin-inline-end: 10vw;
                            .title{
                                color: #C4FFF9;
                                text-transform: none;
                            }
                            .subtitle{
                                color: #46AAA0;
                                margin-bottom: .8em;
                            }
                        }
                    }
                }
                .dosage-table-container{
                    margin-right: 1.2em;
                    margin-left: 1.2em;
                    .pagination-container{
                        position:absolute;
                        bottom: 8em;
                        padding-inline-start: 25vw !important;
                    }
                    .table-drop-down-view-container{
                        bottom: 8.5% !important;
                        right: 5% !important;
                    }
                    .table-Container{
                        border-radius: 2px !important;
                    }
                }
                .activate-button-container{
                    align-self: center;
                    position: absolute;
                    bottom: 5em;
                }
            }
        }
        .empty-programs-container{
            display:flex;
            flex:4;
            flex-direction:column;
            justify-content:center;
            align-items:center;
            height: 94%;
            border: 1px solid #46AAA040;
            border-radius: 5px;
            margin-inline-start:1%;
            margin-inline-end:2%;
            margin-top: 2%;
            button{
                margin-bottom:2em;
            }
            .modal-body-container{
                display:flex;
                align-items:center;
                justify-content:center;
                flex-direction:column;
                .create-report-image{
                    display: flex;
                    width:50%;
                    flex-wrap: wrap;
                    justify-content: center;
                }
                .input-container{
                    width:50%;
                }
            }
        }
        .empty-programs-header{
            @extend .title;
            @media(min-height: 1000px) { font-size: 1.4em !important; }
            padding-top: 1%;
            margin-bottom: 0.5%;
        }
        .empty-programs-subheader{
            @extend .subtitle;
            @media(min-height: 1000px) { font-size: 1.1em !important; }
            text-align:center;
            letter-spacing: 0px;
            max-width:22em;
            word-wrap:break-word;
            margin-bottom: 1.5%;
        }
        .modal-body-container{
            display:flex;
            align-items:center;
            justify-content:center;
            flex-direction:column;
            .archive-program-image{
                display: flex;
                width:40%;
                flex-wrap: wrap;
                justify-content: center;
                margin-top: 5%
            }
            .archive-program-header{
                @extend .title;
                @media(min-height: 1000px) { font-size: 1.4em !important; }
                padding-top: 1%;
                margin-bottom: 0.5%;
                margin-top: 3%
            }
            .archive-program-subheader{
                @extend .subtitle;
                @media(min-height: 1000px) { font-size: 1.1em !important; }
                text-align:center;
                letter-spacing: 0px;
                max-width:22em;
                word-wrap:break-word;
                margin-bottom: 10%;
            }
        }
    }
}

[dir=rtl] .CropPhase-Container{
    .TimeLine .Container .Crop-Image-View {
        width: 310px !important;
        height: 275px;
    }
}

@media (max-width:600px){
    .CropPhase-Container .TimeLine {
        flex-direction: column !important;
    }
    .CropPhase-Container .TimeLine .Description .Content-Seperator {
        display: none !important;
    }
    .CropPhase-Container .TimeLine .Description {
        margin-inline-start: 1em;
    }
    .CropPhase-Container .TimeLine .Description .Crop-Content .crop-added-details-container {
        margin-inline-end: 8% !important;
    }
}

@media (min-width:600px) and (max-width:1024px) {
    .List-Container .Seperator, .Footer{
        width: 25px !important;  
    }
    .Scroll-View .content{
        width: 22px !important;  
    }
    .CropPhase-Container .TimeLine .Container .Line .Footer .Seperator .content {
        width: 25px !important;  
    }
}

@media (min-width:1024px) and (max-width:1400px) {
    .CropPhase-Container .TimeLine .Container .Line .Header {
        width: 30px !important;  
    }
    .CropPhase-Container .TimeLine .Container .Line .Seperator-Wrapper .Seperator .content {
        width: 30px !important;  
    }
    .CropPhase-Container .TimeLine .Container .Line .Footer .Seperator .content {
        width: 30px !important;  
    }
}

@media (min-width:1600px){
    .Description{
        .Content-Seperator{
            height: 22em; 
        }
    }
    .CropPhase-Container .Diseases-Container .Diseases-Content .Body {
        align-items: center;
        display: flex;
        flex: 1 1;
        margin-inline: 20px;
        // margin-top: -101px;
    }
    .Carousel-Image{
       // width:500px !important;
        height: 422px !important;
    }
    .CropPhase-Container .TimeLine .Container [dir=rtl].Line {
        display: flex;
        // margin-top: -9%;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        // bottom: -40px;
        // position: absolute;

    }

    .CropPhase-Container .TimeLine .Container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        flex: 0.8 1;
        // margin-top: -37px;
    }
}

@media (min-width: 3000px){
    .CropPhase-Container .TimeLine .Container .Line .Header {
      width: 50px !important;  
    }

    .CropPhase-Container .TimeLine .Container .Line .Seperator-Wrapper .Seperator .content {
        width: 50px !important;  
 
    }
    .CropPhase-Container .TimeLine .Container .Line .Footer .Seperator .content {
        width: 50px !important;  
    }
    .Description{
        .Content-Seperator{
            height: 24em; 
        }
    }
    .CropPhase-Container .TimeLine .Container .Line {
        left: -30px !important;
    } 

    .CropPhase-Container .TimeLine .Container {
        flex: 0.5 !important
    }
}
.etc-gdd-container{
    gap:10px;
    padding: 1.25rem;
    .dropdown-list-container{
        display: flex;
        .search-drop-down-list{ 
            margin-bottom: 0;
            .value-label{
                font-size: 13px;
                box-shadow: -3px -3px 6px #045850, 3px 4px 8px #022b27 !important;
            }
        }
    }
    .exclamation-icon{
        margin-bottom: 10px;
        #graph-widget-card-header-subtitle{
            text-transform: none !important;
        }
    }
    .date-duration-container{
        margin-inline-start: 1.4em;
    }
    .calendar-button {
        background: #003a35 0% 0% no-repeat padding-box;
        box-shadow: -3px -3px 6px #045850, 3px 4px 8px #022b27 !important;
        border-radius: 5px;
        outline: none !important;
        color: #73E4CC;
        display: flex;
        align-items: center;
        gap: 12px;
        height: 2.5em;
        padding: 0 15px;
        font-size: 13px;
        @media (max-width: 768px) {
            padding: 8px;
           .date {
            display: none;
           }
        }
        &:hover {
            background: #73E4CC 0% 0% no-repeat padding-box;
            border: 2px solid #C4FFF9;
            color: #165F59;
            box-shadow: -3px -3px 6px #00544c, 3px 4px 8px #002421;
            border-radius: 5px;
        }
    }

    .calendar-holder {
        position: relative;
        .triangle {
            position: absolute;
            width: 0;
            height: 0;
            top: 0;
            left: 50%;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-bottom: 12px solid $light-container-background;
            z-index: 1000 !important;

            @media (max-width: 768px) {
                left: 15%;
            }
        }

        .calendar {
            position: absolute;
            top: 12px;
            z-index: 100 !important;
            @media (max-width: 768px) {
                right: -50%;
            }

            .date-picker-container {
                width: 20em;
                background-color: $light-container-background !important;
            }

            .nice-dates-week-header_day {
                flex: 1;
                max-width: calc(100% / 7);
                white-space: nowrap;
                text-align: center;
            }              
        }
    }
}
.define-crop-container{
    padding: 10px;
    .tabs-container {
        .tab {
            // position: absolute;
            // inset-inline-end: 0;
            width: auto;
            padding-inline: 10px;
            padding-block: 5px;
            text-align: center;
            background: #147e74 0% 0% no-repeat padding-box;
            // box-shadow: -3px -3px 6px lighten(#00000029, 5%), 3px 4px 8px darken(#00000029, 4.3%) !important;
            // transform: rotate(-90deg) translateY(19px);
            // border-radius: 5px 5px 0px 0px;
            // color: #73E4CC;
            text-transform: none;
        }
    
        .tab:hover,
        .selected {
            background: #73E4CC 0% 0% no-repeat padding-box;
            border: 2px solid #C4FFF9;
            border-radius: 5px 5px 0px 0px;
            color: #165F59;
        }
    
        .crop-settings-tab {
            position: fixed;
            margin-inline-end: -2em;
            top: 25%;
            z-index: 10;
            [dir="rtl"] & {
                margin-inline-end: -2em !important;
                @media (max-width: 768px) {
                    margin-inline-end: -5% !important;
                }
            }
            @media (max-width: 768px) {
                margin-inline-end: -3%;
            }
        }
    }
    .farm-header{
        top:5px;
        display: block; 
        border: none;
    }
    .panel-inputs-list-container{
        height: 85vh;
        overflow-y: scroll;
        .input-container{
            max-width: 90%;
            padding-inline-start: 1.5rem;
            padding-block-start: 1rem;
            form .input-group .form-control:focus + span,
            input:-webkit-autofill + span,
            input:-webkit-autofill:hover + span,
            input:-webkit-autofill:focus + span,
            input:-webkit-autofill:active + span {
                padding-inline-start: 0;
            }
            .warning-card{
                margin-bottom: .9em;
                padding: 5px 10px;
                line-height: 18px;
                gap: 10px;
                #Path_4182{
                    stroke: white;
                }
                .warning-text {
                    font-size: 14px;
                }
            }
            .dropdown-lists-container{
                display: flex;
                flex-direction: row;
                .second-search-dropdown{
                    margin-inline-end: 8%;
                }
            }
            .new-crop-label{
                color : $primary-txt;
                text-transform: uppercase;
                margin-bottom: .5em;
            }
            .crop-type{
                margin-bottom: .5em;
                color: #73E4CC;
            }
            .add-new-crop-container{
                margin-top: 10px;
            }
            .add-new-crop {
                color: $primary;
                padding-block-start: 10px;
                &:hover {
                    border-bottom: 2px solid $primary;
                    cursor: pointer;
                }
            }
            .drop-down-selector-container{
                margin-top: 10px;
                width: fit-content;
                padding: 1rem;
                min-width: 10rem;
                color: $primary-txt;
                background: $sidebar;
                z-index: 3;
                .choices-list{
                  color: $primary-txt;
                  min-width: 10rem;
                  width: fit-content;
                  max-width: 99%;
               }
               .choice-on-focus{
                  text-transform: uppercase;
                }
                &:hover{
                    background: $primary;
                    border: 2px solid $primary-txt;
                    color: #0A645C;
                }
            }
            .search-drop-down-list {
                .search-drop-down-list .virtual-scroller.form-multi-select-options{
                    height: 230px !important;
                }
                .value-label {
                background-color: #165F59;
                color: #C4FFF9;
                box-shadow: -3px -3px 6px #c4fff91f, 3px 4px 8px #00202457;
                &:hover {
                    background: #73E4CC 0% 0% no-repeat padding-box;
                    border: 2px solid #C4FFF9;
                    color: #165F59;
                    box-shadow: -3px -3px 6px #c4fff91f, 3px 4px 8px #00202457;
                    border-radius: 5px;
                }
            }}
            .define-kc-paragraph{
                font-size: 13px;
                margin-bottom: 10px;
                p{
                    margin-bottom: 0;
                }
                .primary {
                    color: $primary-txt;
                }
                svg{
                    margin-inline-end: .5em;
                }
                .more {
                    text-decoration: underline;
                    cursor: pointer;
                    color: #73E4CC;
                }
            }
            .table-title{
                background-color: #11766e;
            }
            .table-view-container{
                background-color: #02312C;
                .add-new-crop {
                    margin-inline-start: 1em;
                }
            }
            .table-Container{
                border-radius: 0px;
                padding-bottom: .5em;
            }
            .new-action{
                margin-inline: 2.5px;
            }
            .input-error-message{
                margin-top: -20px;
                margin-bottom: 5px;
            }
        }
    }
    .proceed-btn__container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        padding-inline: 2rem;
        .proceed-button{
            padding-inline: 1em;
        }
        .delete-button{
            background-color: #FF5B3333;
            border: 1px solid #FF5B3380;
            color: #FF5B33;
            &:hover{
                background-color: #FF5B3360;
                border: 1px solid #FF5B33;
            }
        }
    }
    .readings-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        .input-container{
            display: flex;
            align-items: center;
            gap: 15px;
            width: 45%;
            padding: 0;
            .input-group {
                span {
                    text-transform: none !important;
                }
            }
            .info-icon {
                path:hover {
                    fill:  $primary,
                }
            }
        }

        .input-description {
            font-size: 13px;
            .primary {
                color: $primary-txt;
            }

            .close-description {
                text-decoration: underline;
                cursor: pointer;
                color: #73E4CC;
            }

        }
    }
    .modal-body-container {
        .delete-icon {
            margin-bottom: 15px;
        }

        .delete-header {
            color: #C4FFF9
        }

        .delete-subheader {
            width: 250px;
            text-align: center;
            margin-bottom: 15px;
        }
    }
}

.tippy-box:has(.crop-settings-tooltip) {
    .tippy-content {
        background-color: #0a524b;
        color: $primary-txt;
        opacity: 0.95;
        max-width: unset;
        @extend .regular-font;
    }

    .tippy-arrow {
        color: #0A524B;;
    }
}