.weekly-schedule{
    height: 37em;
.rbc-timeslot-group, .rbc-header{
    border-bottom: none !important;
}

.rbc-time-gutter{
    border-right:  1px solid $primary-txt-opacity;
    transform: translateY(-0.4em);
}

[dir='rtl'] .rbc-time-gutter{
    border-right: none !important;
    border-left:  1px solid $primary-txt-opacity !important;
}
.rbc-calendar {
  position: relative;
  background-color: $container-background;
  color:$secondary-txt
}

.rbc-time-header-gutter{
    border-top: none !important;
    border-left: none !important;
}

.rbc-time-view {
    border: none !important;
}

.rbc-time-header.rbc-overflowing {
    border-right: none !important;
}

.rbc-day-slot .rbc-time-slot {
    border-top: 0.01px solid $primary-txt !important;
    opacity: 0.1;
}

.rbc-time-content {
    overflow-x: hidden !important;
    margin-inline-start:1px;
    border-top: none !important;
}

.rbc-row-content, .rbc-allday-cell, .rbc-event-label {
    display: none !important;
}

.rbc-current-time-indicator{
    background-color: $primary-txt !important;
}

.rbc-header + .rbc-header , .rbc-time-content > * + * > *{
    border-left: none !important;
}

.rbc-time-header-content {
    border-left: 1px solid $primary-txt-opacity !important;
}
[dir='rtl'] .rbc-time-header-content {
    border-right: 1px solid $primary-txt-opacity !important;
    border-left: none !important;
}

.rbc-events-container {
    margin-right: 0 !important;
}

.rbc-time-header-cell{
    margin-bottom:1.5em;
    border-bottom: 0.01px solid $primary-txt-opacity !important;
}

.rbc-time-view{
    padding-inline-end: 3em !important;
}

.rbc-events-container{
    align-items: center;
}

.rbc-event{
    position:relative;
    padding: 0 !important;
    border-radius: 5px;
}

.rbc-event-content:hover{
 background-color: $events-hover-background;
 border: 2px solid $events-hover-border;
 border-radius: 3px;
}

.rbc-event-content{
    width:100%;
    border-radius: 5px;
}

.rbc-today {
    background-color: transparent !important;
}

.popover-header{
    background-Color:$tooltip-header !important
}

.popover-body{
    background: $tooltip-body 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
}

.toolbar-container{
    display:flex;
    justify-content: flex-end;
    position:absolute;
    right:1em;
    top:1em;
    .toolbar-button{
        background-color:transparent;
        border:none;
        outline:none;
        .navigation-icon{
            display:flex;
            flex-wrap:wrap;
            width:1em;
            height:1em
        }
    }
}

[dir='rtl'] .toolbar-container{
    left:1em;
}

.week-header-container{
    height:100%;
    margin-bottom:1em;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    .date{
        border-radius:50%;
        width:2em;
        height:2em;
        display:flex;
        justify-content:center;
        align-items:center
    }
}

.tooltip-content-container{
    display:flex;
    flex-direction:column;
    .data-row{
        display:flex;
        flex-direction:row;
        margin-bottom:0.3em;
        .data-key{
            color:$cta-txt;
            font-size: 0.9em;
            font-weight:650
        }
        .data-value{
            color:$secondary-txt;
            text-transform: capitalize;
        }
    }
    .next-event-key{
        color:$cta-txt;
        font-size: 0.9em;
        font-weight:650;
        margin-bottom:0.3em
    }

    .next-event-value{
        color:$secondary-txt;
        letter-spacing: 5px;
        font-size:2em;
        font-weight:800;
        text-shadow: 4px 9px $secondary-txt-opacity
    }
    .plot-name{
        color:$cta-txt;
        align-self:flex-end;
        text-transform:capitalize
    }
}


.tooltip-header-container{
    display:flex;
    flex-direction:row;
    .tooltip-header-title{
        color:$primary-txt;
        font-size:1em;
        overflow: hidden;
    }
    .tooltip-header-status{
        color:$primary;
        font-size:1em;
        text-transform:capitalize
    }    
}

.event-container{
    display:flex;
    flex-direction:column;
    height:100%;
    border-radius:5px;
    position:relative;
    .event-left-border{
        height:100%;
        width:0.3em;
        position:absolute
    }
    .event-title{
        margin-top:0.2em;
        color:$primary-txt;
        margin-inline-start:1em;
        font-size:1em
    }
    .event-details-container{
        display:flex;
        flex-direction:row
    }
    .event-status{
        position:absolute;
        bottom:0.5em;
        left:1em;
        font-size:0.9em
    }
    .event-plot{
        position:absolute;
        right:0.5em;
        bottom:0.5em;
        font-size:0.9em
    }
}

@media (max-width: 878px){
    .toolbar-container{
        top:-3.5em;
        right:0
    }

}
}