:root {
    --text-color: $danger-txt;
    --background-color: #A83737CC;
    --background-image: url('https://i.ibb.co/dBkdsmt/130254470-181410456733445-990943832008689849-n.png')
}

[dir='rtl'] .tabs-container {
    .tab {
        transform: rotate(90deg) translateY(19px);
    }

    .farm-tab {
        top: 30%
    }

    .user-tab {
        top: 43%;
        width: 90px;
        transform: rotate(90deg) translateY(34px);
    }
}

.tabs-container {
    .tab {
        position: absolute;
        inset-inline-end: 0;
        width: 60px;
        text-align: center;
        background: #03403A 0% 0% no-repeat padding-box;
        box-shadow: -3px -3px 6px lighten(#00000029, 5%), 3px 4px 8px darken(#00000029, 4.3%) !important;
        transform: rotate(-90deg) translateY(19px);
        border-radius: 5px 5px 0px 0px;
        color: #73E4CC;
        text-transform: capitalize;
    }

    .tab:hover,
    .selected {
        background: #73E4CC 0% 0% no-repeat padding-box;
        border: 2px solid #C4FFF9;
        border-radius: 5px 5px 0px 0px;
        color: #165F59;
    }

    .farm-tab {
        top: 30%
    }

    .user-tab {
        top: 40%
    }
}

.user-farms-wrapper {
    position: relative;
    height: 100vh;

    .modal-body-container {

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .delete-header {
            text-transform: uppercase;
            color: #C4FFF9;
            margin-top: 1em;
            margin-bottom: 0.3em;
        }

        .delete-subheader {
            color: #46AAA0;
            margin-bottom: 1.5em;
            text-align: center;
            width: 50%;
        }

        .delete-icon {
            svg {
                width: 6rem;
                height: 6rem;
            }
        }
    }
}


.fade {
    padding-top: 0 !important;
}

body {
    margin: 0;
    height: 100%;
    background-color: $main_layer;
}

.container-logo {
    svg {
        width: 8.5em;
        height: 8.5em;
        // margin-inline-start: 4.3em;
        margin-top: -7em;
        // margin-top: 1.4em;

    }
}

[dir='rtl'] .container-logo {
    width: 11.4em;
}

.content-container {
    display: flex;
    height: 100%;
    width: 100%;
}

.map-container {
    display: flex;
    flex: 3;
    margin-inline-start: 5.7em;
    height: 70vh;
    border-bottom-left-radius: 1%;
    border-top-left-radius: 1%;
    margin-top: 8.5em;
    position: relative;

}

#map-card-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 1%;
    overflow: hidden;
}
.list-container {
    display: flex;
    background-color: $main_layer;
    flex: 1;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    margin-inline-end: 5.7em;
    box-shadow: -1px 0 25px rgba(0, 18, 18, 0.78);
    .buttons-wrapper {
        display: flex;
        width: 100%;
        margin-top: 1.5rem;


        .end-buttons {
            display: flex;

            .switch-lang {
                margin-top: 0;
            }
        }

        &:has(.change-org) {
            justify-content: space-between;
        }
        .end-buttons {
            display: flex;
            margin-inline-start: auto;
            .user-farms-avatar-dropdown{
                .avatar-dropdown-component.c-header-nav-items:has(#avatar-button) .dropdown-menu.show {
                    width: 13.6rem !important;
                }
                .avatar-dropdown-component.c-header-nav-items:has(#avatar-button) .dropdown-menu::before{
                    left: 38% !important;
                }
            }
        }

        .logout-tooltip,
        .add-tooltip {
            visibility: hidden;
            background-color: #003A35CC;
            border-radius: 3px;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 3em;
            height: 30px;
            width: 100px;
            inset-inline-start: -1em;
            color: #73E4CC
        }

        .add-wrapper,
        .logout-wrapper {
            position: relative;
            margin-inline-start: 1.4em;

            &:hover {
                .logout-tooltip,
                .add-tooltip {
                    visibility: visible;
                }
            }
            &:hover::before {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                top: 2.7em;
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
                border-bottom: 7px solid #003A35CC;
                inset-inline-start: 1em;
            }
        }

        .change-org {
            @extend .logout-button;
            margin-inline-start: 1.4em;

            svg {
                width: 0.6em !important;
                display: block;
                margin-inline: auto !important;
            }

            &:hover svg path {
                stroke: #15675F;
            }
        }
    }

    .buttons-wrapper.change-org-enabled {
        justify-content: space-between;
    }
    ol {
        height: 70vh;
        overflow: scroll;

        ::-webkit-scrollbar-thumb {
            background: #256F68;
            border-radius: 10px;
            height: 5vh !important;
            width: 2px !important;
            cursor: pointer !important;
        }

        ::-webkit-scrollbar-track {
            border-radius: 10px;
            background: transparent;

        }

    }
}

.logout-button {
    svg {
        width: 1.1em;
        display: inline-block;
        // margin-inline-start: 0.1em;
    }

    min-width: 2.5em;
    min-height: 2.5em;
    align-self: flex-end;
    margin-inline-end: 1.4em;
    // margin-top: 1.8em;
    background-color: transparent;
    background-size: 24px 24px !important;
    @extend .neuo;

    &:hover {
        svg {
            line {
                stroke: $main_layer;
            }

            path {
                fill: $main_layer;
            }
        }

        background-color:#73E4CC;
    }

    &:focus {
        outline: none;
    }
}

.buttons-wrapper {

    .add-new-farm-button {
        @extend .logout-button;

        svg {
            width: 1em;
            display: inline-block;
            // margin-inline: auto;
        }

        &:hover {
            svg {
                justify-content: center;

                path {
                    stroke: #15675F;
                }
            }
        }
    }
}

.list-container h3 {
    @extend .heading;
    margin-bottom: -1vh;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (2800 - 320)));
}

.list-container p {
    display: block;
    @extend .link;
    padding: 0 3vw;
    margin-block-end: 2em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

#user-farm-subtitle {
    width: 25em;
    text-transform: unset;
    margin-top: -1em;
    margin-bottom: 3em;
    font-family: nunito_regular;
    padding: 0 2em;
}

.list-container li {
    list-style-type: none
}

.list-container ol {
    padding-inline-start: 0;
    width: 70%;
}

.farm-selected-card,
.farm-card {
    background-repeat: no-repeat;
    width: 100%;
    height: 20vh;
    min-height: 8em;
    position: relative;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .delete-farm {
        z-index: 500;
        outline: none !important;
        position: absolute;
        inset-inline-start: 10px;
        top: 10px;
        background-color: #C4FFF9C4;
        border-radius: 5px;
        border: 1px solid #C4FFF9;
        padding-block: 3px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            path {
                fill: #003a35;
            }

            rect {
                fill: #C4FFF9C4;
            }
        }

        &:hover {
            background-color: #10534D;

            svg {
                path {
                    fill: #73e4cc;
                }

                rect {
                    fill: #10534D;
                }
            }
        }
    }
}

.layer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#view-status {
    display: none;
    z-index: 100;
    color: white;
    font-weight: 700;
    font-size: 1.4em;
    text-align: center;
    margin: auto;
}

.farm-card-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 2vh;
    border: 2px solid darken($secondary-txt, 0.8) !important;
    border-radius: 2%;
    align-items: center;
    width: 100%;
    min-height: 8em;

    li {
        background-size: cover;
    }
}


.farm-card:hover:after {
    opacity: 0.9;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    content: "";
    background: $primary;
}

.farm-card:hover #view-status {
    display: block;
}

.farm-apply-button {
    margin-block-end: 0.9em;
    margin-block-start: 0.9em;

    .proceed-button {
        height: 35px !important;
        padding-inline: 20px !important;
        width: 10em !important;
        margin-top: 0 !important;
    }
}

.issues-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 1.5em;
    height: 2.1em;
    font-size: calc(10px + (12 - 10) * ((100vw - 320px) / (2800 - 320)));
}

.issues-container span {
    font-weight: 600;
    padding-inline-end: 5px;
}

.issue-container-outline {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
    height: 2.1em;
    margin-inline-end: 1vw;
    margin-top: 1vh;
    background-color: rgba(196, 255, 249, 0.1);
    border: 1px solid #D9FFFB;
    margin: 3.3%;
    padding: 4% 0;
}

.issues-container-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 1.2em;
    height: 1.2em;
    margin-inline-start: 8px;
    margin-inline-end: 5px;
    background-color: transparent !important;
    border: 1px solid #fff;
}

.issues-container-icon-outline {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: .9em;
    height: .9em;
}

.issues-container-icon {
    .issues-container-icon-outline {
        border: none !important;
    }
}

#farm-pin {
    width: 20px;
    height: 40px;
}

#farm-name {
    @extend .title;
    text-shadow: 0px 3px 6px $text-shadow;
    font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (2800 - 320)));
    margin-block: 0;
}

#farm-area {
    letter-spacing: 0px;
    color: $primary-txt;
    text-shadow: 0px 3px 6px $text-shadow;
    font-weight: 400;
    margin-block-end: 0;
    font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (2800 - 320)))
}

#CTA button {
    label {
        margin: 0 !important;
        display: flex !important;
        height: 50% !important;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width:2000px) {
    .list-container {
        flex: 1.5;
    }

    .map-container {
        flex: 3;
    }
}

// Large desktop screens
@media (min-width:1300px) and (max-width:2000px) {
    .list-container {
        flex: 1.5;
    }

    .map-container {
        flex: 3;
    }

    #CTA button {
        width: 200px;
        height: 35px;

        label {
            margin: 0;
            display: inline;
        }
    }
}

@media (min-width:1920px) {
    .map-container {
        z-index: -1;
    }

    .list-container {
        box-shadow: -2px 0 30px rgba(1, 32, 32, 0.66);
        max-width: 30%;
    }

    .farm-card {
        height: 15em;
        width: 100%;
        background-position: center;
        margin-left: 0.1%;
    }

    .issue-container-outline+div {
        margin-bottom: 2%;
    }

    #farm-name {
        margin-bottom: 0 !important;
    }

}

// Small screen laptops
@media (min-width:850px) and (max-width:1300px) {
    .list-container {
        flex: 2;
    }

    .map-container {
        flex: 3;
    }

    .farm-apply-button button {
        width: 190px !important;
        height: 35px !important;
    }

}

// Ipads and tablets
@media(min-width:768px) and (max-width:850px) {
    .list-container {
        flex: 1.5;

    }

    .map-container {
        flex: 1;
    }

    .farm-apply-button button {
        width: 180px !important;
        height: 20px;
    }

}

@media (max-width: 768px) {
    #farm-pin {
        width: 1.6em;
        height: 2.7em;
        /* box-shadow: 0px 3px 6px #02353185; */
    }

    .content-container {
        justify-content: center;
    }

    .list-container {
        flex: 0.7;
        margin: 0;
    }

    .map-container {
        display: none;
    }

    .container-logo {
        display: none;
    }

    .farm-apply-button button {
        width: 190px !important;
        height: 30px;

        &:after {
            width: 25px;
            height: 25px;
        }
    }

}

#map-card-container-userFarm {
    position: absolute;
    height: 100%;
    width: 100%;
    margin-top: 2.5rem;
}

.farmDet {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: absolute;
    height: 100%;
}

@media (min-width:1920px) {
    .issue-container-outline {
        padding: 2% 0;
    }
}

@media (min-width:3800px) {
    .issue-container-outline+div {
        margin-block-start: 5rem;
    }
}

[dir='rtl'] #user-farm-subtitle {
    font-family: tajawal_medium;
    font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (2800 - 320)));
    margin-block-start: -8px;
}
[dir='rtl'] .list-container .buttons-wrapper{
    .end-buttons {
        .user-farms-avatar-dropdown{
            .avatar-dropdown-component.c-header-nav-items:has(#avatar-button) .dropdown-menu.show {
                width: 10.6rem !important;
            }
            .avatar-dropdown-component.c-header-nav-items:has(#avatar-button) .dropdown-menu::before{
                left: 48% !important;
            }
        }
    }
}
