@font-face{
    font-family: nunito_regular;
    src: url('../../assets/fonts/english/NunitoSans-Regular.ttf');
}

@font-face{
    font-family: nunito_semibold;
    src: url('../../assets/fonts/english/NunitoSans-SemiBold.ttf');
}

@font-face{
    font-family: nunito_bold;
    src: url('../../assets/fonts/english/NunitoSans-Bold.ttf');
}

@font-face{
    font-family: nunito_extraBold;
    src: url('../../assets/fonts/english/NunitoSans-ExtraBold.ttf');
}

//////////////////////////////////////////////

@font-face{
    font-family: tajawal_regular;
    src: url('../../assets/fonts/arabic/Tajawal-Regular.ttf');
}

@font-face{
    font-family: tajawal_medium;
    src: url('../../assets/fonts/arabic/Tajawal-Medium.ttf');
}

@font-face{
    font-family: tajawal_extraBold;
    src: url('../../assets/fonts/arabic/Tajawal-ExtraBold.ttf');
}