// .LegendPanelPosition-irriwatch{
//     position: absolute;
//     inset-inline-start: 44.5rem;
//     top: 45rem;
//     z-index: 10;
//     @media(max-width: 1200px ){
//         inset-inline-start: 1rem;
//         top: 50rem;
//     }
//     @media(max-width: 800px ){
//     inset-inline-start: 1rem;
//     top: 35rem;
//     }
// }

.LegendPanelPosition-irriwatch{
    margin-bottom: 10px;
}

.legend-panel-irriwatch {
    background: #03403A;
    display: flex;
    flex-direction: column;
    opacity: 1;
   // width: 15vw;
    //height: 10vh;
    margin-top: -11vh;
    padding: 10px 15px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font: normal normal normal 14px/21px;
    position: relative; /* For internal positioning of elements */
    width: 180px !important;
    @media(max-width: 1200px ){
        width: 85%;
        inset-inline-start: 1rem;
        top: -10rem;
    }
    .separator {
        height: 2px; /* Thickness of the line */
        background-color: #46aaa0; /* Color of the line */
        width: 100%; /* Full width inside the panel */
        position: absolute; /* Positioning inside the panel */
        top: 50%; /* Vertically centers the line */
        left: 0;
        transform: translateY(-50%); /* Centers perfectly */
    }
    .section1,
    .section2 {
        display: flex;
        align-items: center; /* Vertically align box and text */
        background: transparent;
        opacity: 1;
        // width: 190px;
        // height: 30px;
    }
    
    .section2 {
        margin-top: 22px;
    }
    
    .color-box {
        width: 16px;
        height: 16px;
        margin-right: 14px; /* Space between box and text */
        border: 1px solid #ffffff; /* Optional: Add a border around the box */
    }

    .irrigate {
        background-color: #7fb5fc; /* Color for irrigate area */

    }
    
    .un-irrigate {
        background-color: #949494; /* Color for un-irrigate area */
    }

  }

      [dir=rtl] .color-box{
        margin-left: 14px;
    }
    [dir=rtl] .LegendPanelPosition{
        inset-inline-start: 64rem;
    }
    [dir=rtl] .LegendPanelPosition-irriwatch {
        inset-inline-start: 46.5rem;
    }