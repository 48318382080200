.modalBackground{
    margin:0;
    padding: 0;
    min-width:100vw;
    min-height:100vh;
    position:fixed;
    top:0;
    left:0;
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $modal_bg;
    .modalContainer{
        width:40%;
        // height:50%;
        background-color: $main_layer;
        border-radius: 15px;
        padding-bottom: 25px;
        .closeButtonContainer{
            display:flex;
            // justify-content: flex-end;
            margin-top:1rem;
            margin-inline-end: 1rem;
            margin-inline-start: 1rem;
            .closeButton{
                width:1.5rem;
                height:1.5rem;
                border:0;
                background-image:  url('../../assets/icons/svg/constants/close_btn.svg');
                background-size: contain;
                background-color: transparent;
                background-repeat: no-repeat !important;
                outline: none !important;
            }
        }
    }

}



@media (max-width: 800px) 
{ 
    .modalContainer{
        width:70% !important
    }
}