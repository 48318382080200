.not-found-wrapper {
    min-height: 100vh;
    background: url("../../assets/icons/png/notFound.png") no-repeat;
    background-size: cover;
    .header {
        padding: 15px;
        display: flex;
        .logo-not-found{
            svg {
                width: 100px;
                height: 110px;
            }
        }
        .contact-us-wrapper{
            position: absolute;
            inset-inline-end: 4rem;
            top: 1rem;
            .contact-us{
                background: $secondary_layer;
                &:hover {
                    background-color: $primary-txt;
                }
            }
        }
    }

    .body {
        display: flex;
        flex-direction: column;
        height: 70vh;
        justify-content: center;
        align-items: center;

        .code,
        .title {
            color: #C4FFF9;
            text-transform: capitalize;
            font-weight: 600;
            font-size: 2em;
        }

        .code {
            font-size: 2.5em;
        }

        .subtitle {
            color: #53C4B9;
            letter-spacing: 0px;
            font-size: 1em;
            width: 300px;
            text-align: center;
        }

        button {
            margin-top: 25px;
        }
    }
}