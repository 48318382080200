
.Empty-Farms{
    background: url("../../assets/icons/svg/AssociatedFarms/NF_normalUser.svg") no-repeat fixed;
    // @media (max-width: 850px) {
    //     background: none;
    // }
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    max-width: 120% !important;
    padding: 0 !important; 
    justify-content: end;
    position: relative;
    .container-logo{
        margin-inline-start: 0em !important;
        margin-bottom: 2em !important;
        margin-top: 0em!important;
        display: block !important;
        margin-inline: auto !important;
        svg{
            margin-inline-start: 0;
            margin-bottom: 0;
            margin-top: 0;
        }
    }
    .logout-button{
        position: absolute;
        top: 0;
        inset-inline-end: 20px;
    }
    .switch-lang--wrapper{
        position: absolute;
        top: 0;
        inset-inline-end: 74px;
        display: flex;
        .user-farms-avatar-dropdown{
            padding-top: 24px;
            .avatar-dropdown-component.c-header-nav-items:has(#avatar-button) .dropdown-menu.show {
                width: 12rem !important;
            }
            .avatar-dropdown-component.c-header-nav-items:has(#avatar-button) .dropdown-menu::before{
                left: 43% !important;
            }
        }
    }
    .empty-content-container {
        display: flex;
        min-height: 100vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        .empty-bg-container{
            position: relative;
            .header{
                color: var(--info);
            }
            .paragraph{
                max-width: 20rem;
                margin-inline: auto;
            }
        }
    }
}

