.calendar-events-card{
    height: 46.2em;
    .title-underline{
        width:100%;
        border-color: $secondary-txt-light-opacity;
        height: 0.05em;
        margin: 0;
        margin-top:-0.3em
    }
    .card-body{
        display: flex;
        flex-direction: column;
        padding: 0 !important;
        margin-block: 0.5rem ;
        .Checkbox-Container  {
            margin-bottom: 1em;
            padding-inline-start: 1.25rem ;
            .Checkbox-View {
                background-color: $dark-button-background !important;
                box-shadow: 3px 3px 6px $light-checkbox-box-shadow !important;
            }
        }
        .Checkbox-Container:nth-child(1){
            .Checkbox-Title{
                color:$fertigation-background !important;
            }
        }
        .Checkbox-Container:nth-child(2){
            .Checkbox-Title{
                color:$irrigation-background !important;
            }
        }
        .Checkbox-Container:nth-child(3){
            .Checkbox-Title{
                color:$reminder-background !important;
            }
        }
        .line-separator{
            border-style: dashed;
            border-color: $secondary-txt-light-opacity;
            width: 80%;
            height: 0.5em;
            margin-top: 0.3em;
            margin-bottom: 0;
            }
        .programs-count-container{
                display: flex;
                justify-content: center;
                align-items: center;
                .type-count-container{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    padding-inline: 0.6em;
                    .programs-type{
                        width:100%;
                        color: $program-type;
                        font-size: 0.85em;
                        margin-block: 0 !important;
                        padding-top: 0.3em;
                    }
                    .programs-count{
                        font-weight: 800;
                        font-size: 2.5em;
                        margin:0 !important;
                    }
                    .active-label{
                        margin:0 !important;
                    }
                }
                .type-count-container:nth-child(1){
                    .programs-count{
                        color: $irrigation-background
                    }
                }
                .type-count-container:last-child{
                    .programs-count{
                        color:$fertigation-background
                    }
                }
                .container-separator{
                    height: 8em;
                    border-left: 1px dashed $line-separator;
                }
        }
        .date-picker-container{
            margin-inline: auto;
            width:85%;
            transform:scale(1,0.75);
        }
        // .date-picker-container{
        //     // display:flex;
        //     // flex-direction: row;
        //     // justify-content:center;
        //     // align-self: center;
        //     margin-top: 0.7em;
        //     margin-inline: auto;
        //     width:80% !important;
        //     transform:scale(1,0.9);
        //     .Selectable{
        //         width:100%;
        //         height:100%;
        //     }      
        //     .DayPicker-Months{
        //         height: 100%;
        //         width: 100%;
        //     }
        //     .DayPicker-wrapper {
        //        width: 100%;
        //        height: 100%;
        //        padding-bottom: 0 !important;
        //     }
        //     .DayPicker-Month {
        //       width: 100%;
        //     }
        //     .DayPicker-Body {
        //         width: 100%;
        //         // height: 100%;
        //     }
        //     .DayPicker-Weekdays {
        //         width: 100%;
                
        //     }
        //     .DayPicker-Caption {
        //         width: 100%;
        //         // height: 100%;
        //     }
        //     .DayPicker-Week {
        //         width: 100%;
        //     }
        //     .DayPicker-WeekdaysRow {
        //         width: 100%;
        //     }
        //     .DayPicker-Day {
        //         padding-right: 0 !important;
        //         padding-left: 0 !important;
        //         font-size: 75% !important;

        //     }
        //     .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
        //         font-size: 0.8vw !important;
        //         // width: 100%;
        //         // height: 100%;
        //         border-radius: 100%;
        //     }
        //     .Selectable .DayPicker-Day--start{
        //         border-radius: 100% !important;
        //     }
        //     .DayPicker-Day--today[aria-selected=false]::after{
        //         border-radius: 100% !important;
        //     }
        //     .DayPicker-NavBar span {
        //         left: inherit ;
        //     }
        //     .DayPicker-NavButton--next {
        //         margin:0 !important;
        //         right: 20%!important;
        //     }
        //     .DayPicker-NavButton--prev {
        //         margin:0 !important;
        //         left: 20% !important;
        //     }
        //     .DayPicker-NavBar {
        //         width: 100%;
        //         display: flex;
        //         justify-content: center;
        //     }
        //     .DayPicker-NavButton{
        //         right:inherit ;
        //     }
        //     .DayPicker-Caption > div {
        //         font-size: 0.9vw !important;
        //         // height: 2em;
        //         // display: flex;
        //         // justify-content: center;
        //         // align-items: center;
        //     }
            
        // }  
    }
}

@media (min-width: 1600px) and (max-width:1900px){
    .calendar-events-card{
        height: 50em !important
    }
}

@media (min-width: 1900px) and (max-width:3000px){
    .calendar-events-card{
        height: 54em !important
    }
}

@media (min-width: 2600px) and (max-width:3000px){
    .calendar-events-card{
        height: 58em !important
    }
}
@media (min-width:3000px){
    .calendar-events-card{
        height: 68em !important
    }
}