.btn.btn-primary {
  display: flex;
  align-items: center;
  width: 14em;
  height: 2.7em;
  border: none;
  border-radius: 30px;
  background-color: $primary;
  $width-cap: 83%;
  $hov: $primary;

  label {
    @extend .button;
    width: check($width-cap);
    cursor: pointer;
    margin: unset;
  }

  &::after {
    content: ' ';
    width: 15%;
    height: 100%;
    margin-left: 2%;
    border-radius: 30px;
    background: $button-after-bg url('../../assets/icons/svg/constants/CTA_Arrow.svg') no-repeat center;
    cursor: pointer;
  }

  &:hover {
    background-color: darken($hov, 25%);
    cursor: pointer;

    label {
      color: $primary-txt;
    }
  }

  &:disabled {
    background-color: $border;
    pointer-events: none;

    label {
      color: $sidebar;
    }
  }

  &:disabled:after {
    background: url('../../assets/icons/svg/constants/arrowDisabled.svg') no-repeat center;
  }
}

.CTA-AR:disabled:after {
  background: url('../../assets/icons/svg/constants/arrowDisabled-ar.svg') no-repeat center !important;
}

.CTA-AR::after {
  background: $button-after-bg url('../../assets/icons/svg/constants/CTA_Arrow-ar.svg') no-repeat center !important;
}

@media (min-width:310px) and (max-width:480px) {
  .btn.btn-primary {
    width: 12em;
    height: 2.5em;

    label {
      font-size: 0.8em !important;
    }
  }
}

@media (min-width:481px) and (max-width:599px) {
  .btn.btn-primary {
    width: 12.5em;
    height: 2.6em;

    label {
      font-size: 0.9em !important;
    }

    &::after {
      content: ' ';
      width: 15%;
      height: 100%;
    }
  }
}

@media (min-width:600px) and (max-width:768px) {
  .btn.btn-primary {
    width: 13em;
    height: 2.6em;

    label {
      font-size: 1em !important;
    }

    &::after {
      content: ' ';
      width: 15%;
      height: 100%;
    }
  }
}

@media (min-width:769px) and (max-width:1024px) {}

@media (min-width:1025px) and (max-width:1200px) {}

@media (min-width:1201px) {
  .btn.btn-primary {
    display: flex;
    align-items: center;
    width: 14em;
    height: 2.7em;
    border: none;
    border-radius: 30px;
    background-color: $primary;
  }
}