.message-container {
    display: flex;
    flex-direction: row;
    position: fixed;
    align-items: center;
    inset-inline-end: 20px;
    bottom: 20px;
    z-index: 2000;
    background: #D6FFE3 0% 0% no-repeat padding-box;
    border-radius: 10px;
    color: #165F59;
    width: 20%;
    height: 10%;

    @media (max-width:857px) {
        min-width: 40%;
        max-width: 85%;
        height: auto;
    }

    .message-icon {
        margin-inline-start: 3%;

        svg {
            width: 70%;
            height: 70%;
            display: flex;
            flex-wrap: wrap;
        }
    }

    .message-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 100%;

        @media (max-width:857px) {
            padding-block: 8px;
            padding-inline: 0px;
        }

        button {
            position: absolute;
            background-color: transparent;
            outline: none;
            border: none;
            display: flex;
            inset-inline-end: 2px;
            top: 0;
            width: 12%;
            height: 12%;
            min-width: 31px;
            min-height: 31px;
            flex-wrap: wrap;
        }

        .message-title {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 0.9em;
        }

        .message-subtitle {
            letter-spacing: 0px;
            font-size: 0.9em;
            font-weight: 400;
        }
    }
}

.failure-container {
    background: #A54747 0% 0% no-repeat padding-box !important;
    color: #FFC6C6 !important;
}