.dashboard-v2 {
  .upper-section-container {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;

    .left-side {
      display: flex;
      flex-direction: column;
      flex: 1 0 75%;

      @media (max-width: 600px) {
        width: 100%;
        padding-inline: 10px;
      }

      .forcast-and-farms-container {
        margin-top: 15px;
        display: flex;
        gap: 15px;
        flex-grow: 1;

        .forcast-section-container {
          display: flex;
          flex-direction: column;
          gap: 15px;
          flex-basis: 35%;
          
          #current-day-forecast-card {
            margin-bottom: 0;
            border-radius: 5px;

            .card-header {
              font-size: 14px !important;
              font-weight: 600 !important;

              .header-date {
                font-size: 10px !important;
                font-weight: 400 !important;
              }
            }

            .card-body {
              display: flex;
              flex-direction: column;
              justify-content: center;
              margin-top: 3px;

              #day-farm-name, #night-farm-name {
                font-size: 12px !important;
                padding-left: 0px;
              }
              #temperature {
                font-size: 30px !important;
            	  line-height: 24px;
              }
              #status {
                font-size: 12px !important;
                margin-top: 3px !important;
              }
              #location-container {
                margin-bottom: 5px;
              }

              #forecast-details-container {
                font-size: 12px !important;
                font-weight: 400 !important;
              }
            }
          }

          #forecast-card {
            margin-block: 0 !important;
            height: 100% !important;

            .card-header {
              font-size: 14px;
              font-weight: 600;
            }

            .card-body {
              padding-inline: 20px !important;

              #forecast-row {
                margin-inline-start: 0;
                margin-bottom: -5px !important;

                #day-temperature-container, #icon-container {
                  margin-inline: 0 !important;
                }
                #day-temperature-container {
                  p {
                    margin-left: 0 !important;
                  }
                  #day-temperature {
                    margin-inline-end: 0;
                  }
                  
                }
                    
              }
            }

            #day-label {
              flex: 1 1;
            }

            #day-label, #day-temperature-container #day-temperature {
              font-size: 14px !important;

              @media (max-width: 1200px) {
                font-size: 12px !important;
              }
            }
          }
        }

        @media (max-width: 1024px) {
          flex-direction: column;
        }
      }
    }
  }

  .map-cards-container {
    display: flex;
    gap: 5px;

    @media (min-width: 601px) and (max-width: 1024px) {
      flex-wrap: wrap;
      .map-card:first-child {
        flex: 1 1 100%;
      }
    }

    .map-card {
      position: relative;
      border-radius: 4px;
      flex: 1;
      color: $primary-txt;

      .map-div {
        height: 480px;
      }

      .mapboxgl-map {
        border-radius: 4px;
      }

      .expandable-container {
        z-index: 1;
      }

      .map-link {
        position: absolute;
        bottom: 20px;
        left: 20px;
        background-color: #03403ACC;
        color: $primary !important;
        box-shadow: 3px 3px 6px #001A17A2;
        border-radius: 5px;
        [dir="rtl"] & {
          left: unset;
          right: 20px;
        }

        &:hover {
          background: #73E4CCCC;
          border: 2px solid #C4FFF9;
          color: $main_layer !important;
        }
      }

      .desc-container {
        padding: 16px;
        width: 100%;
        height: auto;

        .title-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          @media (min-width: 1025px) and (max-width: 1600px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;

            .date {
              order: -1;
            }
          }
        }

        .date {
          margin-bottom: 0;
          font-size: 12px;
        }

        .disclaimer {
          display: flex;
          gap: 8px;
          font-family: nunito_regular;
          color: #11675F;
          margin-top: 8px;

          [dir="rtl"] & {
            font-family: tajawal_regular;
          }
        }

        .chip {
          font-family: nunito_semibold;
          border-radius: 24px;
          padding: 6px 12px;
          font-size: 12px;
          line-height: 22px;
          background-color: $primary-txt;
          color: $main_layer;
          [dir="rtl"] & {
            font-family: tajawal_medium;
          }
        }

        .desc {
          font-family: nunito_regular;
          font-size: 14px;
          margin-top: 8px;
          padding-inline: 4px;
          line-height: 20px;
          [dir="rtl"] & {
            font-family: tajawal_regular;
          }

          &.open {
            overflow-y: visible;
            max-height: 300px;
            overflow-y: scroll;
          }
          &.closed {
            overflow-y: hidden;
            height: 62px;
          }
        }
      }

      &.light {
        color: $main_layer;

        .chip {
          background-color: $secondary-txt;
          color: #f6fffe;
        }

        #issues-translucent-container {
          background-color: #c4fff9de !important;
        }
      }
    }

    @media (max-width: 600px) {
      flex-direction: column;
      gap: 9px;
    }
  }

  .farms-section {
    flex-basis: 75%;
    padding: 23px 28px 17px 28px;
    background-color: $container-background;
    border-radius: 5px;
    display: flex;
    flex-direction: column;

    @media (max-width: 1200px) {
      flex-basis: 65%;
    }

    ::-webkit-scrollbar {
      height: 7px !important;
    }
  
     /* Track */
     ::-webkit-scrollbar-track {
      border-radius: 10px;
      background: transparent;
    }
  
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #256f68;
      border-radius: 10px;
      width: 2px !important;
      cursor: pointer !important;
    }
  
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #348c83;
    }
  
    ::-webkit-scrollbar {
      display: inline-block !important;
    }

    .farms-section-title {
      color: $primary-txt;
      font-size: 15px;
    }

    .farms-subtitle {
      font-size: 13px;
    }

    .proceed-button {
      margin-top: 0;
      padding: 9px 15px;
      height: fit-content;
      text-wrap: nowrap;
    }

    .empty-data-state-container {
      padding: 0;
      margin-top: 24px;

      .empty-state-subtitle {
        margin-bottom: 22px;
      }
    }

    .scrollable-container {
      display: flex;
      // width: 43vw;
      gap: 25px;
      overflow-x: scroll;
      cursor: grab;

      .scrollable-item {
        flex: 0 0 45%;
        margin-bottom: 10px;

        @media (max-width: 1200px) { 
          flex: 0 0 55%;
        }

        @media (max-width: 600px) { 
          flex: 0 0 100%;
        }
    
      }
    }

    .farm-card-container {
      margin-bottom: 0;
      margin-top: 25px;
      cursor: pointer;
    }

    .farm-card {
      height: 180px;

      &:hover:after {
        opacity: 1;
      }

      .issue-container-outline {
        margin: 15px;
        padding: 12px 0;
      }

      .issues-container span {
        padding-inline-end: 15px;
      }

      #view-status {
        color: #1c5e57;
        font-family: nunito_extraBold;
        text-transform: uppercase;
        font-size: 20px;
        [dir="rtl"] & {
          font-family: tajawal_extraBold;
        }
      }
    }

    .empty-data-state-container {
      background-color: unset;
    }
  }

  .news-section-container {
    background-color: #e2fffc;
    border-radius: 10px;
    padding: 30px 15px 23px 15px;
    flex: 1 0 25%;
    color: $main_layer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 600px) { 
      margin-top: 16px;
      flex: 0 100%;
    }

    .news-title {
      font-size: 16px;
      font-family: nunito_bold;
      padding-left: 10px;

      [dir="rtl"] & {
        font-family: tajawal_extraBold;
      }
    }

    .expand-btn {
      border: none;
      background-color: transparent;
      &:focus {
        outline: none;
      }
      svg {
        width: 19px;
        height: 19px;
      }
    }

    ::-webkit-scrollbar {
      height: 4px !important;
      width: 4px;
    }
  
     /* Track */
     ::-webkit-scrollbar-track {
      border-radius: 10px;
      background: transparent;
      margin-left: 10px;
    }
  
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #6c9b97a3;;
      border-radius: 10px;
      width: 4px !important;
      cursor: pointer !important;
    }
  
    ::-webkit-scrollbar {
      display: inline-block !important;
    }


    .scrollable-notifications {
      max-height: 550px;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      gap: 18px;
      padding-inline-end: 10px;
      margin-block: 20px;

      &.pro {
        max-height: 1000px;
        @media  (max-width: 600px) {
          max-height: 450px;
        }  
      }
    }

    .up-to-date {
      text-align: center;
      height: 90%;
      padding: 33px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #D9F5F2;
      margin-block: 15px;

      .up-to-date-title {
        color: #254945;
        font-size: 18px;
        font-family: nunito_bold;
        margin-top: 20px;
  
        [dir="rtl"] & {
          font-family: tajawal_extraBold;
        }
      }

      .up-to-date-subtitle {
        color: $secondary-txt;
        font-size: 16px;
      }
    }
  }

  .expandable-card {
    padding: 15px;
    border-radius: 10px;
    background-color: #c0eae6;
    color: $container-background;
    font-size: 16px;
    line-height: 20px;

    &.fold {
      .expandable-card-content {
        overflow: hidden;
      }
    }
    &.expaned {
      border: 4px double #0AD2AA;
    }

    .title-container {
      display: flex;
      flex-direction: column;
      gap: 2px;

      @media (min-width: 601px) and (max-width: 1200px) {
        .expandable-card-date {
          font-size: 12px;
        }
      }  
    }

    .expandable-card-title {
      font-family: nunito_bold;
      text-transform: capitalize;
      font-size: 12px;

      [dir="rtl"] & {
        font-family: tajawal_extraBold;
      }
    }

    .expandable-card-date {
      color: #195c55;
      font-size: 12px;
      text-transform: lowercase;
    }

    .expandable-card-content {
      margin-top: 10px;
      font-size: 13px;

      .more {
        text-transform: lowercase;
        color: #1d9085;
        font-family: nunito_bold;
        [dir="rtl"] & {
          font-family: tajawal_extraBold;
        }
      }
    }
  }

  .notifications-panel {
    .Panel {
      background-color: #114a45;
      color: $primary-txt;
      .Panel-Header {
        margin-block: 38px;
      
        height: auto;
        [dir="rtl"] & {
          margin-right: 30px;
        }
        [dir="ltr"] & {
          margin-left: 30px;
        }
      }

      .scrollable-notifications {
        padding-inline: 30px;
        max-height: 83vh;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-bottom: 30px;
      }
    }
  }

  .clickable {
    cursor: pointer;
  }

  .large-upgrade-card {
    background-color: #036359;
    border-radius: 5px;
    border: 5px solid #73E4CC;
    text-align: center;
    padding: 20px;
    color: $primary-txt;
    box-shadow: 0 0 20px $main_layer;

    .proceed-button {
      height: auto;
      padding: 6px 12px;
    }
  }

  .map-desc-banner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  .map-desc-banner {
    background: #246e6799;
    border: 1px solid $secondary-txt;
    border-radius: 5px;
    padding: 10px 15px;
    color: $primary-txt;
    margin-bottom: 15px;
    font-size: 13px;
    width: 100%;

    @media (max-width: 600px) {
      padding: 10px;
      margin-bottom: 10px;
      font-size: 12px;
    }
    .regular {
      font-family: nunito_regular;
      [dir="rtl"] & {
        font-family: tajawal_regular;
      }
    }
    .bold {
      font-family: nunito_bold;
      [dir="rtl"] & {
        font-family: tajawal_extraBold;
      }
    }
  }

  .megaphone-btn {
    border: none;
    background-color: transparent;
    box-shadow: -2px -2px 5px #0b837780;
    padding: 10px;
  }
}

[dir="rtl"] {
  #current-day-forecast-card {
    #day-farm-name, #night-farm-name, #status{
      font-family: tajawal_medium;
    }
  }
 
}
