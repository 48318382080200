.LinkExpired_Wrapper {
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 5rem 1rem 1rem;
  flex-wrap: wrap;
  background: $main_layer
    url("../../assets/icons/svg/constants/resetBackground.svg") no-repeat fixed;
  background-position-x: center;
  background-position-y: center;
  background-size: 100%;
  .contact-us-wrapper{
    justify-content: start;
    display: flex;
    width: 100%;
    position: relative;
    inset-inline-start: 1rem;
    top: -8rem;
    .contact-us{
        background: $secondary_layer;
        &:hover {
            background-color: $primary-txt;
        }
    }
  }
  .logo {
    justify-content: center;
    display: flex;
    margin-bottom: 40px;
    svg {
      width: 220px;
      height: 220px;
    }
  }
  .btn.btn-primary {
    background: var(--cta-primary-normal-state-sub-txt-on-config-bg) 0% 0%
      no-repeat padding-box;
    background: #73e4cc 0% 0% no-repeat padding-box;
    border-radius: 5px;
    width: 16rem ;
    label {
      width: 83%;
      cursor: pointer;
      margin: unset;
      font-size: 13px !important;
    }
    .CTA-AR:disabled:after {
      background: url("../../assets/icons/svg/proceed.svg") no-repeat center !important;
    }
    .CTA-AR::after {
      background: $button-after-bg url("../../assets/icons/svg/proceed.svg")
        no-repeat center !important;
    }
  }

  .btn.btn-primary::after {
    content: " ";
    width: 15%;
    height: 100%;
    margin-left: 2%;
    border-radius: 30px;
    background: 'url(/static/media/CTA_Arrow.97625f1a.svg)' no-repeat center;
    cursor: pointer;
  }
}
