.Toastify__toast-theme--colored.Toastify__toast{
    font-family: nunito_semibold;
    font-size: 13px !important;
    .Toastify__toast-icon {
        margin-inline-end: 10px;
        width: 20px !important;
        flex-shrink: 0;
        display: flex;
    }
    &--error{
        color: #FFC6C6 !important;
        background: #a83736ed !important;
        // .Toastify__toast-icon {
        //     margin-top: -12%;
        // }
        .Toastify__close-button {
            color: #e58c8c !important;
            background-color: #823333 !important;
            width: 14.5% !important;
            height: 14.5% !important;
            border-radius: 50% !important;
            opacity: 1 !important;
        }
    }
    &--warning {
        color: #9d651b !important;
        background: #f0bd6feb !important;
        // .Toastify__toast-icon {
        //     margin-top: -8%;
        // }
        .Toastify__close-button {
            color: #fdd291 !important;
            background-color: #462a0440!important;
            width: 17% !important;
            height: 17% !important;
            border-radius: 50% !important;
            opacity: 1 !important;
        }
    }
    &--success {
        color: #165f59 !important;
        background: #73E4CCdb !important;
        // .Toastify__toast-icon {
        //     margin-top: -3%;
        // }
        .Toastify__close-button {
            color: #c8f5f1 !important;
            background-color: #03403a4a !important;
            width: 10% !important;
            height: 10% !important;
            border-radius: 50% !important;
            opacity: 1 !important;      
        }
        .Toastify__progress-bar{
            background-color: #93fff4 !important;
        }
    }
    .Toastify__close-button {
        svg {
            height: 14px !important;
            width: 12px !important;
            //margin: 0 25% !important;
            margin-top: -3px;
            margin-left: 1.5px;
        }
    }
    .Toastify__toast-body > div:last-child {
        margin-block-start: 0.5em;
    }
}
