.update-manual-meter-panel {
    .Panel {
        padding-inline: 30px;
        padding-block: 15px;

        .update-manual-meter-title {
            color: #C4FFF9;
            letter-spacing: 0.18px;
            text-transform: uppercase;
            //font-size: 1em
            font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
        }

        .meter-depths {
            display: flex;
            //align-items: center;
            align-items: flex-end;
            span {
                margin-inline-end: 5px;
            }
            .meterDepth-txt{
                font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
            }
        }

        .panel-subtitle {
            margin-top: -20px;
            margin-bottom: 25px;
            //font-size: 0.9em
            font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));

        }

        .meter-map-selection-view {
            height: 55vh !important;

            .old {
                .label,.plot-name {
                    //color: #012a25;
                    color: #46aaa0;
                    font-weight: bold;
                    font-family: 'nunito_semiBold';
                    //font-size: 1em;
                    font-size: calc(12px + (12 - 12) * ((100vw - 320px) / (2800 - 320)));
                }
            }
            .error {
                color: #F48585;
                margin-top: -20px;
                font-size: 0.9em;
            }
        }

        .proceed-button {
            width: 200px;
            height: 30px;
            margin-top: auto;
            margin-inline: auto;
            margin-bottom: 20px;
        }
    }
}