.sensor-details-content-container {
    display: flex;
    flex-direction: row;
    height: 100% !important;
    .background-card {
        display: flex;
        flex-direction: column;
        width: 100% !important;
        padding: 0 !important;

        .distribution-card-body {
            max-height: 35em;
            overflow-y: scroll
        }
    }

    .distribution-card {
        display: flex;
        flex-wrap: wrap;
        width: 45%;
        margin-inline-end: 1em;
        .analysis-msg{
            word-break: break-word;
        }
        .sensor-number{
            font-family : nunito_extraBold;
        }
        .information-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

            .information-subcontainer {
                display: flex;
                flex-direction: column;
                // height: 4em;
                justify-content: flex-end;
                align-items: center;
                margin-inline-end: 1em;

                .information-key {
                    text-transform: uppercase;
                    font-size: 0.9em;
                    font-weight: 500;
                }

                .plots {
                    margin-top: 0.3em;
                }

                .total {
                    font-size: 0.7em;
                }

                .count {
                    color: $primary-txt;
                    font-size: 2.5em;
                    font-weight: 700;
                    margin-bottom: -0.3em;
                }

                .plot-icon {
                    width: 1.5em;
                    height: 1.5em
                }

                .sensor-set-icon {
                    width: 1.8em;
                    height: 1.8em
                }

                .sensors-icon {
                    width: 2em;
                    height: 2em
                }
            }

            .container-separator {
                margin-inline-end: 1em;
                margin-inline-start: 0;
                height: 5em;
                border-left: 1px solid $line-separator
            }
        }

        .heatmap-container {
            overflow: scroll;
            display: flex;
            flex-direction: column;
            justify-content: center
        }

        .capsule-outline {
            width: 23%;
            margin-inline-start: 33%;
            // margin-top:0.7em;
            margin-bottom: 10%;
            border-radius: 80px;
            min-height: 32em;
            border: 1px solid $capsule-outline;
            display: flex;
            flex-direction: column;
            align-items: center;

            .depth-container:first-child {
                margin-top: 45%;
            }

            .depth-container:last-child {
                padding-bottom: 45%;
            }

            .depth-container {
                position: relative;
                width: 97%;

                .thresholds-container {
                    position: absolute;
                    width: 120%;
                    top: 0.8em;
                    right: 100%;
                    display: flex;

                    .thresholds-values {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        font-size: 0.8em;

                        .key,
                        .value {
                            margin: 0;
                        }

                        .key {
                            margin-bottom: 0.1em;
                        }

                        .value {
                            margin-bottom: 2.7em;
                        }
                    }

                    .thresholds-border {
                        border-right: 1px solid $secondary-txt;
                        height: 6em;
                        width: 1em;
                        opacity: 0.6;
                        position: relative;

                        &::before {
                            position: absolute;
                            content: "";
                            width: 0px;
                            height: 0px;
                            border-top: 5px solid transparent;
                            border-right: 10px solid $secondary-txt;
                            border-bottom: 5px solid transparent;
                            // border-left: 7px solid transparent;
                            top: 40%;
                            right: -1px;
                        }
                    }

                }

                .depth-status-container {
                    background-color: $depth-status-background;
                    width: 100%;
                    height: 7em;
                    border: 1px dashed $depth-status-border;
                    display: flex;
                    // flex-direction: column;
                    text-align: center;
                    position: relative;

                    .depth-reading {
                        position: absolute;
                        top: 45%;
                        margin: 0;
                        width: 100%;
                        font-weight: 600;
                    }

                    .depth-reading-container {
                        width: 100%;
                        display: flex;
                        align-self: flex-end;
                    }
                }

                .marker {
                    margin: 0;
                    width: 150%;
                    background-color: $primary;
                    height: 2px;
                    margin-bottom: 2em;
                    align-self: flex-start;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    .marker-symbol {
                        width: 0.75em;
                        height: 0.75em;
                        border-radius: 0.375em;
                        background-color: $primary
                    }
                }

                .depth-details-container {
                    position: absolute;
                    width: 110%;
                    top: 5em;
                    left: 160%;

                    .text-details,
                    .depth-name {
                        margin: 0;
                        font-size: 80%;
                        margin-bottom: 0.3em;
                    }

                    .depth-name {
                        background-color: $primary;
                        color: $main_layer;
                        border-radius: 2px;
                        display: flex;
                        justify-content: center;
                        text-align: center;
                        font-weight: 600;
                    }
                }
            }

        }
        .analysis-card--sensor-set{
            width: 400px;
            @media (max-width:1200px) {
                width: 270px;
            }
            @media (max-width:800px) {
                width: 400px;
                max-width: 90%;
            }
        }
    }

    .current-readings-card {
        display: flex;
        width: 55%;
        &__card-header-icon{
            .card-header-with-icon__tooltip__wrapper{
                @media (max-width:1020px) {
                    inset-block-start : 0.8rem !important;
                } 
            }
        }
        .background-card {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        .rate-slider {
            margin-top: 2.5em;
            align-self: center;
        }

        .empty-state-graph {
            svg {
                height: 15em;
            }
        }
    }
}
@media (min-width:1100px){
    [dir="ltr"] .current-readings-card{
        .card-header-with-icon__tooltip__wrapper__text__triangle{
            inset-block-start: -19% !important;
        }
    }
}
.sensor-details-buttons-container {
    padding-inline-start: 1.25rem;

    button {
        background-color: transparent;
        outline: none !important;
        border: none;
        color: $secondary-txt;
        margin-inline-end: 1.5em;
        margin-bottom: 10px;
    }

    button:hover {
        color: $primary
    }
}
[dir=rtl] .sensor-details-content-container .distribution-card .sensor-number{
    font-family : tajawal_extraBold;
}

[dir=rtl] .thresholds-container {
    left: 80%;
    right: inherit !important;

    .thresholds-values {
        margin-inline-end: 0.5em;
    }
}

[dir=rtl] .depth-details-container {
    right: 160% !important;
    left: inherit !important
}

[dir=rtl] .thresholds-border {
    &::before {
        position: absolute;
        content: "";
        width: 0px;
        height: 0px;
        border-top: 5px solid transparent;
        border-left: 10px solid $secondary-txt;
        border-bottom: 5px solid transparent;
        border-right: 0 !important;
        top: 45% !important;
        right: inherit !important;
        left: 1em !important
    }
}


@media (max-width: 878px) {
    .sensor-details-content-container {
        flex-direction: column !important;

        .distribution-card {
            width: 100% !important
        }

        .current-readings-card {
            width: 100% !important
        }

        .background-card {
            margin-bottom: 1em !important;
            min-height: 40em;
        }
    }
}

@media (min-width:878px) and (max-width:1350px) {
    .sensor-details-content-container .distribution-card {
        .count {
            font-size: 1.5em !important;
        }
    }
}

@media (min-width: 3000px) {

    .capsule-outline {
        // width:18% !important;
        border-radius: 200px !important;
    }

    .thresholds-container {
        right: 40% !important;
    }

    [dir=rtl] .thresholds-container {
        left: 40%;
    }
}