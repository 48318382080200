.multiple-input-form{
    width:98%;
    .multiple-input-group{
        .multiple-input-prepend{
            display:inline-block !important;
        }
    }
    .prepend-input-group-text{
        display:flex;
        flex-direction:row;
        .tag{
            color:#73E4CC;
            position:relative;
            display:flex;
            flex-direction:row;
            border-radius:83px;
            align-items:center;
            min-width:150px;
            max-width:150px;
            height:2.5em;
            background-color:#03403A;
            box-shadow:-1px -1px 4px #73E4CC34;
            margin-inline-end:5px;
            .value{
                display: flex;
                justify-content: center;
                // text-align:start;
                width:90%;
                overflow:hidden;
                text-overflow:ellipsis
            }
            .delete-button{
                display: flex;
                justify-content: center;
                align-items: center;
                color:#73E4CC;
                height:100%;
                width:20%;
                display:flex;
                align-items:center;
                background-color:transparent;
                border:none;
                outline:none;
                position:absolute;
                top:0;
                right:2px;
                border-left:1px solid #46AAA03E;
                padding-inline: 0 !important;
                z-index: 6000;
            }
        }

    }
    .input-group-prepend {
        top: -13px !important;
        width: 100%;
        overflow-x: scroll;
    }
}