
#night-farm-name{
    color: #74C7CD;

}
#day-farm-name{
    color: #AD813C;
    
}

#day-farm-name,#night-farm-name{
    margin-top: -0.2rem;
    margin-inline-start: 5px;
    font-size:0.9em;
    text-transform: lowercase !important;
    font-family: nunito_semibold;
    padding-left: 5px;
    @media (min-width:1200px) {
        font-size: 0.7em;
    }
}

#current-day-forecast-card{
    // height: 220px !important;
    //background-size: cover !important;
    // border-radius: 8px;
    // background-size: contain;
    // background-repeat: round;
    background-size: 100%;
    background-position-y: center;
    background-repeat: no-repeat;
    display: flex;
    width: 100%;
    .card-header{
       padding-bottom: 5px !important;
        @media (min-width:1200px) {
            font-size: calc(10px + (12 - 10) * ((100vw - 320px) / (2800 - 320)));
        }
        @media (min-width:1600px) {
            font-size: calc(10px + (12 - 10) * ((100vw - 320px) / (2800 - 320)));
         }
    }
    .card-body{
        width: 100%;
    }
    .header-date{
        margin-inline-start:auto;
        text-transform:capitalize;
        font-size:0.8em;
        font-weight:700,
    }

    &.gradiend-bg {
        background: transparent
        linear-gradient(
          122deg,
          #ff8888 0%,
          #eec07c 67%,
          #ffbc5f 86%,
          #facd85 100%
        )
        0% 0% no-repeat padding-box;
        border: 1px solid #C4FFF966;
 
        #forcast-data,
        #forecast-details-container,
        #day-farm-name,
        .header-date, .card-header {
          color: #254945 !important;
        }

        #location-pin {
          path {
            fill: #254945;
          }
        }
        .day-line-separator {
            border-color: #254945;
            opacity: 0.2;
        }
        #humidity, #rain-chance {
            border-color:  rgba(3,64,58, 0.45);
        }

        &.night {
            background: transparent linear-gradient(121deg, #11616E 0%, #09666D 34%, #104E47 68%, #02312C 100%) 0% 0% no-repeat padding-box;

            #forcast-data-night,
            #forecast-details-container-night,
            #night-farm-name,
            .header-date, .card-header {
                color: $primary-txt !important;
            }
    
            #location-pin {
              path {
                fill: $primary-txt;
              }
            }

            .night-line-separato {
                border-color: #254945;
                opacity: 0.2;
            }
            #humidity, #rain-chance {
                border-color:  rgba(196, 255, 249, 0.45);
            }
        }
    }
}
#forecast-card{
    .card-body {
        padding-top: 0 !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        @media (min-width:1200px) {
            padding-left: 1.4rem !important;
        }
        @media (min-width:1400px) {
            padding-left: 1rem !important;
        }
        @media (max-width:800px) {
            font-size: 0.7em !important;
        }
    }
    // height: 580px !important;
    background: transparent $forcast_bg 0% 0% no-repeat padding-box !important;
    margin-top:-.8em !important;
    height: 19.8em !important;
    // border-radius: 8px;
    // opacity: 1;
    ol{
        padding-inline-start: 0px;
    }
    // .card-body{
    //     //padding-top: 5% !important;
    // }
    .card-header {
        padding: 16px 20px 0px 20px !important;
    }
    header{
         
         //font-size: 0.7em;
         font-size: calc(10px + (12 - 10) * ((100vw - 320px) / (2800 - 320)));
         @media (min-width:1200px) {
            padding: 0.8em 0.7em !important;
         }
         @media (min-width:1600px) {
            //font-size: 0.9em;
            font-size: calc(10px + (12 - 10) * ((100vw - 320px) / (2800 - 320)));
         }
    }

    .weekly-forecast-link {
        color: $secondary-txt;
        font-size: 14px;
        &:hover {
            color: #73E4CC;;
            text-decoration: none;
        }
    } 
}
// #forcast-data{
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     // color:#AD813C;
//     // margin-inline-start: 5%;
// }
// #forcast-data-night{
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     // margin-inline-start: 5%;
//     // color:#7DD3D9;
// }
#forcast-data, #forcast-data-night{
    display: flex;
    flex-direction: row;
    justify-content: center;
    color:#AD813C;
    gap: 25px;
    &-night{
        color: #74C7CD;
    }
}
#temperature{
    font-size: 2.2em;
    font-weight: 700;
    margin-bottom: 0;
    @media (min-width:1200px) {
        font-size: 1.6em;
    }
    //color:#ad813c;
    //margin-top:-.3em;
}
#status{
    font-size: 0.9em;
    text-transform: lowercase;
    margin-top: -0.8em !important;
    margin-bottom: 0.3em !important;
    font-family: nunito_semibold;
    //color: #AD813C;
    @media (min-width:1200px) {
        font-size: 0.7em;
    }
}
#weather-image{
    margin-top: 0;
    // margin-inline-start: 2.8em;
    // margin-inline-end: -0.9em;
    width: 3.8em;
    // width: 3.5rem;
    // height: 3.5rem;
    // margin-inline-start: 1.7em;
    // margin-inline-end: -3em;
    margin-inline-start: 0.7em;
    margin-inline-end: -2em;
    @media (min-width:1200px) {
      margin-inline-start: -1.1em;
      margin-inline-end: 0;
    }
    @media (min-width:1200px) {
        margin-inline-start: 2em;
    }
}
#forecast-details-container, #forecast-details-container-night{
    display: flex;
    flex-direction: row;
    color: #AD813C;
    font-size:.9em;
    text-align: center;
    margin-top: 0.5em;
    &-night{
        color: #74C7CD;
    }

    .weather-info {
        display: flex;
        align-items: center;
        gap: 5px;
        justify-content: center;
        @media (min-width:1024px) and (max-width: 1400px){ 
            flex-direction: column;
        }
    }
}


#humidity{
    flex: 1 1;
    font-size: .9em;
    border-inline-end: 2px solid #ad813c33;
    //padding-inline-end: .8em;
    padding-inline-end: 0.5em;
    p{
        margin:0;
    }
}
#rain-chance{
    // border-inline-end: 2px solid $forcast-color;
    // flex:1.4;
    // padding-inline-start:.5em;
    // padding-inline-end: .5em;
    // font-size: .9em;
    border-inline-end: 2px solid #ad813c33;
    flex: 1 1;
    padding-inline-start: 0.5em;
    padding-inline-end: 0.3em;
    font-size: 0.9em;
    p{
        margin:0;
    }
}
#forecast-details-container-night > {
    #humidity, #rain-chance{
        border-inline-end-color: #74C7CD33;
    }
}
#daily-temperature{
    flex: 1 1;
    padding-inline-start: 0.8em;
    font-size: 0.9em;
    p{
        margin:0;
    }
    #high{
        text-decoration: underline;
        text-underline-offset: 0.2rem;
        //text-decoration-thickness: 0.1rem;
        text-decoration-thickness: 0.1em;
    }
}
.day-line-separator{
    border-bottom: 1.5px solid #ad813c2b;
}
.night-line-separator{
    border-bottom: 1.5px solid #74C7CD2B;
}
.day-line-separator,.night-line-separator{
    margin-inline: auto;
    width: 80%;
    margin-bottom: 0.1em;
    margin-top: 0.2em;
}
#forecast-card-container{
    display:flex;
    flex-direction: column;
    margin-top: -0.7em;
    p{
        margin:0;
        margin-block-start: 0;
        margin-block-end: 0;
    }
}
#forecast-row{
    list-style:none!important;
    display: flex;
    flex-direction: row;
    color:$primary-txt;
    justify-content: space-around;
    margin-bottom: -1.2em;
    margin-inline-start: -0.3em;
}
#day-label{
    flex: 1.2;
    font-size: 0.9em;
    text-align: start;
    padding-top: 0.3em;
}
#forecast-icon{
    width: 2em;
    height: 1.4em;
}
#forecast-icon-container {
    position: relative;
    display: table;
    height: 100%;
    flex: 1;
    margin-top:3px;
    &:after{
        content: '';
        display: table;
        margin: 0 auto;
        width: 2px;
        background-color:  $primary-txt;
        height: 1.69em;
        opacity: 0.2;
    }
   
}
#forecast-row:last-child{
    #forecast-icon-container:after{
        height: 0.7em;
    }
}
#forecast-row{
    &:first-child{
    //margin-top: 0.8em;
    margin-top: 0.3em;
    #forecast-icon-container {
        display: table;
        height: 100%;
        //margin-top: -1em;
        margin-top: -12px;
        &:before{
            content: '';
            display: table;
            margin: 0 auto;
            width: 2px;
            background-color:$primary-txt;
            height: 0.7em;
            opacity: 0.2;
        }  
        }
    }
    &:last-child{
        #forecast-icon-container:after{
            height: 1em;
        }
    }
} 

#day-temperature-container{
        flex: 1;
        margin-inline-start: 0;
        //margin-inline-end: 0;
        margin-inline-end: -0.2em;
        text-align: end;

    // color:$primary;
    p{
        margin-bottom: 0;
        font-size: .9em;
    }
    #day-temperature{
        font-size:0.9em;
        color:$primary;
        margin-top: 5px;
    }
}
#location-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    //margin-top: 0.3em;
    //margin-left: 2.1em;
    margin-top: 0.7em;
    margin-bottom: -10px;
    gap: 5px;
    @media (min-width:1200px) {
        margin-bottom: -7px;
    }
    p{
        margin-block-end: 0 !important ;
        align-self: flex-end;
    }
}
#location-pin{
    svg{
        width: .5em;
        height: .8em;
    //    #pin{
    //     fill:$secondary-txt;
    //    }
    }
}
#location-name{
    color:#AD813C;
    margin-top: -0.2rem;
    margin-inline-start: 5px;
    font-size: 1em;
    text-transform: lowercase;
}
#forcast-column{
    padding-inline-end: 1em !important;
}
#day-summary{
    font-weight: 500;
    text-transform: uppercase;
}
@media (min-width:991px) and (max-width: 1250px){
    // #day-label{
    //     flex: 2.5;
    // }
    #forecast-icon{
        width: 1.5em;
        height: 1em;  
    }
    #temperature{
        font-size: 1.7em;
        font-weight: 700;
        margin-bottom: 0;
        //margin-top: -0.1em;
    }
    #status{
        font-size: 0.78em;
        margin-top: -7.5px;
        letter-spacing: 0.6px;
        }
    // #forecast-icon-container {
    //     flex: 0.7;
    // } 
    #weather-image{
        // padding-top: 2px;
        padding-top:-2em !important;
        padding-inline-start: -2em !important;
        width: 2.5rem;
        height: 2.5rem;
        
    } 
    #humidity{
        font-size: 0.8rem;
        padding-inline-end: 0.5em;
        line-height: normal;
    }
    #rain-chance{
        padding-inline-start:0.5em !important;
        padding-inline-end: 0.5em !important;
        font-size: 0.8rem;
        line-height: normal;
    }
    #daily-temperature{
        font-size: 0.8rem;
        padding-inline-start:0.5em !important;
        line-height: normal;
    } 
    #day-label{
        font-size: 0.6rem;

    }
    #day-temperature-container{
        margin-inline-start: 2em;

        p{
            font-size: 0.6rem;
            margin-left: -2em !important;
        }
        #day-temperature{
            font-size:0.6rem;
        }
    }
    #day-farm-name,#night-farm-name{
        font-size: 0.6rem;
    }
}

@media (min-width:767px) and (max-width: 990px){
    #location-name{
        font-size: 0.7em !important;
    }
    #temperature{
        font-size: 1em !important;
        font-weight: 550;
        margin-bottom: 0;
        margin-inline-start: -1em;
    }
    #status{
        font-size: 0.8rem;
        margin-inline-start: -1em;
    }
    #weather-image{
        // padding-top: 2px;
        // margin-inline-start: 1em;
        width: 2rem;
        height: 2rem;
        margin-top: -.5em;
    } 
    #humidity{
        font-size: 0.6rem;
        padding-inline-end: 0.1rem;
        margin-inline-start: -1em;
    }
    #rain-chance{
        padding-inline-start:0.1rem;
        padding-inline-end: 0.1rem;
        font-size: 0.6rem;
    }
    #daily-temperature{
        font-size: 0.6rem;
       // padding-inline-start:0.1rem;
       // padding-inline-end: 0.1rem;
    } 
    #forecast-icon{
        width: 1.7em;
        height: 1em;
    }
    #forecast-details-container{
        font-size: .7em;
    }
    #forecast-details-container-night{
        font-size: .7em;
    }
    #day-label{
        font-size: .7em;
    }  
    #day-temperature-container{
        margin-inline-start: .2rem;
        p{
            font-size: 0.5rem;
        }
        #day-temperature{
            font-size:0.5rem;
        }
    }
}

@media (min-width:575px) and (max-width: 766px){
    #temperature{
        font-size: 0.7em;
        font-weight: 550;
        margin-bottom: 0;
        margin-inline-start: -1em;
    }
    #status{
        font-size: 0.7rem;
        margin-inline-start: -1em;
    }
    #weather-image{
        // padding-top: 2px;
        // margin-inline-start: 1em;
        width: 2rem;
        height: 2rem;
        margin-top: -.5em;
    } 
    #humidity{
        font-size: 0.6rem;
        padding-inline-end: 0.03rem;
        margin-inline-start: -1em;
    }
    #rain-chance{
        padding-inline-start:0.03rem;
        padding-inline-end: 0.03rem;
        font-size: 0.6rem;
    }
    #daily-temperature{
        font-size: 0.6rem;
        padding-inline-start:0.03rem;
        padding-inline-end: 0.03rem;
    } 
    #forecast-icon{
        width: 1.7em;
        height: 1em;
    }
    #forecast-details-container{
        font-size: .7em;
    }
    #forecast-details-container-night{
        font-size: .7em;
    }
    #day-label{
        font-size: .7em;
    }  
    #day-temperature-container{
        margin-inline-start: .2rem;
        p{
            font-size: 0.5rem;
        }
        #day-temperature{
            font-size:0.5rem;
        }
    }
}

// @media (max-width: 575px){
//     #temperature{
//         font-size: 1em;
//         font-weight: 500;
//         margin-bottom: 0;
//     }
//     // #status{
//     //     font-size: 18px;
//     // }
//     #weather-image{
//         padding-top: 8px;
//         margin-inline-start: 15px;
//         // width: 75px;
//         // height: 75px;
//     }
//     #forecast-details-container{
//         font-size: .9em;
//     }
//     #forecast-details-container-night{
//         font-size: .9em;
//     }
//     #day-label{
//         font-size:1em;
//         align-self: flex-start;
//     }
//     #forecast-icon{
//         width: 2em;
//         height: 1.3;
//     }
//     #day-temperature-container{
//         margin-inline-start: 50px;
//         p{
//             font-size:.9em;
//         }       
//     }
//     #day-summary{
//         font-size: 1em;
//     }
// }
// #forcast-column{
//     padding-inline-end: 1em !important;
// }
// @media  (max-width: 600px){
//     #forcast-column{
//         padding-inline-end: 1em !important;
//         padding-inline-start: 1em !important;
//     }
//     #current-day-forecast-card{
//         background-position: 50% 100%;
//     }
// }

#icon-container {
    //margin-inline-start: -1em;
    margin-inline-start: 0;
    margin-inline-end: 1em;
}

@media (min-width:320px){
}
@media (min-width:480px){
}
@media (min-width:576px) and (max-width:599px){
    #day-temperature-container,#day-label {
        display: none !important;
    }
    #icon-container {
        margin: 0 50%;
    }
    #current-day-forecast-card {
        background-size: cover;
    }
    #location-container{
        display: none;
    }
    #weather-image {
        width: 1.4rem;
        height: 2rem;
        // margin-top: 0.2em;
        // margin-inline-start: 0.7em;
        margin-top: 0.1em;
        margin-inline-start: 0.2em;
        margin-inline-end: -1em;
    }
    #status {
        font-size: 0.7rem;
        margin-inline-start: -8em;
        margin-top: 0 !important;
    }
    #forcast-data, #forcast-data-night {
        margin-top: 1em;
    }
    #humidity {
        font-size: 0.6rem;
        padding-inline-end: 0;
        margin-inline-start: -9px;
    }
    #rain-chance {
        padding-inline-start: 6px;
        padding-inline-end: 8px;
        font-size: 0.6rem;
    }
    #daily-temperature {
        font-size: 0.5rem;
        padding-inline-start: 4px;
        padding-inline-end: 10px;
    }

}
@media (min-width:600px) and (max-width:767px){
    #current-day-forecast-card .card-header{
        font-size: 0.6em !important;
    }
    #day-temperature-container {
        display: none !important;
    }
    #day-farm-name, #night-farm-name {
        font-size: 0.7em;
    }
    #forecast-row {
        margin-inline-start: -0.3em;
    }
    #icon-container {
        margin-inline-start: 0;
        margin-inline-end: -0.5em;
    }
    #current-day-forecast-card {
        background-size: cover;
    }
    #humidity, #rain-chance, #daily-temperature {
        font-size: 0.8em;
    }
    // #location-container {
    //     display: none;
    // }
    #location-container {
        margin-top: -1em;
        margin-bottom: -2px;
        margin-inline-end: -2em;
    }
    #forecast-card-container {
        margin-top: 1.5em;
    }
    #forcast-data, #forcast-data-night {
        margin-top: -1em;
        margin-bottom: 1em;
    }
    #status {
        margin-top: -0.5em !important;
        margin-bottom: -1em !important;
    }
    #weather-image {
        margin-top: 0.5em;
        margin-inline-start: 0.7em;
        margin-inline-end: -1em;
    }
}
@media (min-width:768px) and (max-width:899px){
    #current-day-forecast-card .card-header{
        font-size: 0.6em !important;
    }
    #forecast-row {
        margin-inline-start: -0.8em;
    }
    #day-temperature-container {
        margin-inline-start: 0.9rem;
        margin-inline-end: -1.8em;
    }
    #icon-container {
        margin-inline-start: -1em;
        margin-inline-end: -1em;
    }
    #current-day-forecast-card {
        background-size: cover;
    }
    #day-farm-name, #night-farm-name {
        font-size: 0.8em;
    }
    #location-container {
        margin-bottom: -5px;
    }
    #temperature {
        margin-bottom: 5px !important;
    }
}
@media (min-width:900px) and (max-width:1023px){
    #current-day-forecast-card .card-header{
        font-size: 0.6em !important;
    }
    #forecast-row {
        margin-inline-start: -1.1em;
    }
    #day-temperature-container {
            margin-inline-end: -1em;
            margin-inline-start: -2.7rem;
    }
    #icon-container {
        margin-inline-start: -1.7em;
        margin-inline-end: 2.2em;
    }
    #day-label {
        font-size: 0.5rem;
    }
    #day-temperature {
        font-size: 0.5rem !important;
    }
    #day-farm-name, #night-farm-name {
        font-size: 0.7em;
    }
    #current-day-forecast-card {
        background-size: cover;
    }
    #location-container {
        //margin-top: 0.8em;
        margin-top: 0.3em;
        margin-bottom: -2px;
    }
    #temperature{
        font-size: 1.2em !important;
        @media (min-width:1200px) {
            font-size: 1.6em !important;
        }
    }
    #status {
        font-size: 0.7em !important;
    }
    #humidity, #rain-chance, #daily-temperature {
        font-size: 0.8em;
    }
    #humidity{
        padding-inline-end: 0.3em;
    }
    #weather-image {
        margin-inline-start: -1em;
        margin-inline-end: 0em;
        width: 2rem;
        height: 2.5rem;
        margin-top: 1.5em;
    }
}
@media (min-width:1024px) and (max-width:1199px){
    #current-day-forecast-card .card-header{
        font-size: 0.7em;
    }
    #forecast-row {
        //margin-inline-start: -0.8em;
        margin-inline-start: -1em;
    }
    #day-label {
        font-size: 0.5rem;
    }
    #icon-container {
        //margin-inline-start: -1em;
        margin-inline-start: -2.5em;
        margin-inline-end: 0;
    }
    #day-temperature-container {
        margin-inline-start: -21px !important;
        margin-inline-end: -15px;
        #day-temperature {
            font-size: 0.5rem;
        }
    }
    #current-day-forecast-card {
        background-size: cover;
    }
    #humidity, #rain-chance, #daily-temperature {
        font-size: 0.7em;
    }
    #weather-image {
        // margin-inline-start: 0;
        // margin-inline-end: -1em;
        margin-inline-start: -0.6em;
        margin-inline-end: -1em;
        width: 2rem;
        height: 2.5rem;
        margin-top: 1.7em;
    }
    
}
@media (min-width:1200px) and (max-width: 1399px){
    #current-day-forecast-card .card-header{
        font-size: 0.7em;
    }
    #day-label{
        font-size: 0.7em;
    }
    #day-temperature-container #day-temperature {
        font-size: 0.6em;
        margin-inline-end: -1em;
    }
    #icon-container{
        // margin-inline-start: 0em;
        // margin-inline-end: 0.5em;
        margin-inline-start: -3em;
        margin-inline-end: -3em;
    }
    // #weather-image{
    //     margin-inline-start: 0.7em;
    //     margin-inline-end: -2em;
    // }
    #forecast-details-container, #forecast-details-container-night, #forecast-details-container-day {
        font-size: 0.8em;
        font-weight: 600;
    }
    #forecast-row {
        margin-inline-start: -1em;
    }
    #humidity {
        font-size: 0.9em;
        padding-inline-end: 0.2em;
    }
    #rain-chance {
        padding-inline-start: 0 !important;
        padding-inline-end: 0;
        font-size: 0.9em;
    }
    #daily-temperature{
        font-size: 0.9em;
    }
    #current-day-forecast-card {
        background-size: cover;
    }
    #weather-image {
        margin-top: 1.9em;
        margin-inline-start: -1.1em;
        margin-inline-end: 0;
    }
}
@media (min-width:1400px) and (max-width:1679px){
    #day-label{
        font-size: 0.8em;
    }
    #day-temperature-container #day-temperature {
        font-size: 0.7em;
    }
    #icon-container{
        margin-inline-start: 0em;
        margin-inline-end: 0.5em;
    }
    #weather-image{
        margin-inline-start: 0.2em;
        margin-inline-end: -1em;
        margin-top: 1em;
        width: 3.1em;
    }
    #forecast-details-container, #forecast-details-container-night, #forecast-details-container-day {
        font-size: 0.7em;
        font-weight: 600;
    }
    #day-temperature-container {
        margin-inline-start: -5px;
        margin-inline-end: -9px;
    }    
}