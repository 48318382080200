.three-months-picker-wrapper {
  @extend .quarter-picker-wrapper;
  .quarter-picker-wrapper {
    width: 175px;
    .quarter-row {
      display: grid;
      grid-template-columns: repeat(3, 35%);
      &:hover {
        background-color: transparent !important;
      }
      .hovered {
        background: #73e4cc4d !important;
        color: #73e4cc !important;
      }
      .selected-month {
        color: #165f59 !important;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background: #73e4cc 0% 0% no-repeat padding-box !important;
          border-radius: 20px;
          top: 0;
          inset-inline-start: -10px;
          z-index: -1;
        }
      }
      .cell {
        background-color: transparent;
        outline: none;
        border: none;
        &:not(.selected-month):not(.hovered) {
          color: #73e4cc !important;
        }
        &:hover{
          cursor: pointer;
        }
        &.disabled-month{
          opacity: 0.25;
          cursor: auto;
        }
      }
    }
    .proceed-container {
      form {
        width: 25%;
        margin-inline-end: 20px;
      }
      .proceed-button {
        width: 38%;
        font-size: 1em;
      }
    }
    .threshold-disclaimer{
      margin-inline-start: 20px;
      margin-top: -15px;
    }
  }
}

[dir=rtl] .three-months-picker-wrapper {
  .quarter-picker-wrapper {
    width: 200px !important;
  }
  .navigation-bar .next {
    inset-inline-end: 35% !important;
}
 .quarter-row {
  grid-template-columns: 1fr 2fr 1fr;
  .cell{
    text-align: center;
  }
}
form .form-control + span {
  padding-inline-start: 0 !important;
}
}
