form .input-group {
  position: relative;
  width: 100%;
  margin: 30px 0;

  .input-length {
    color: #46AAA0;
    display: flex;
    justify-content: end;
    margin-top: 6px;
    margin-inline-start: auto;
  }

  .form-control {
    position: relative;
    border: none;
    outline: none;
    width: 100%;
    color: $primary-txt !important;
    font-size: 14px;
    padding: 8px 0;
    box-sizing: border-box;
    border-radius: 0 !important;
    border-bottom: 2px solid #c4fff92e;
    background: transparent;
    z-index: 2;
    filter: none !important;

    &::placeholder {
      color: transparent !important;
    }

    &:focus {
      border-color: $primary;
      caret-color: $primary;
      box-shadow: none !important;

      &::placeholder {
        opacity: -1 !important;
      }
    }

    &:-webkit-autofill {
      transition: background-color 5000s ease-in-out 0s !important;
      -webkit-text-fill-color: $primary-txt !important;
    }

    &:-webkit-credentials-auto-fill-button:hover {
      -webkit-box-shadow: 5px 5px $primary inset !important;
      box-shadow: 5px 5px $primary inset !important;
    }
  }

  span {
    display: inline-block;
    position: absolute;
    [dir="ltr"] & {
      left: 0;
    }
    [dir="rtl"] & {
      right: 0;
    }
    color: $primary-txt;
    font-size: 1em;
    z-index: 1;
    transition: 0.5s;
  }
}

form .input-group.input-with-icon .form-control + span {
  bottom: 16%;
  [dir="ltr"] & {
    left: 10%;
  }
  [dir="rtl"] & {
    right: 10%;
  }
}

form .input-group .form-control:focus + span {
  color: $primary;
  bottom: 80%;
  [dir="ltr"] & {
    left: 0;
  }
  [dir="rtl"] & {
    right: 0;
  }
  //font-size: 0.75em;
  font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
}

.input-group-prepend {
  position: absolute;
  top: 12%;
  left: 0;

  .input-group-text {
    background-color: none !important;
    border-color: none !important;
    border: none !important;
    background: none !important;
    color: $primary-txt !important;
  }
}

.input-error-message {
  color: $error-message;
  padding-top: 0.2em !important;
  font-size: 0.95em !important;
  text-align: start;
}

[dir="rtl"] .input-group-prepend {
  left: inherit !important;
  right: 0;
}

form .input-group .form-control:focus ~ .input-group-prepend {
  display: none;
}

form .input-group .form-control:not(:placeholder-shown) ~ .input-group-prepend {
  display: none;
}

.input-container {
  background: none !important;
  background-color: none !important;
}

.explanation-message {
  .input-error-message {
    color: $primary;
  }
  .input-group .form-control {
    color: $primary-txt !important;
    border-bottom-color: $border-primary !important;
  }
  .input-group span {
    color: $primary-txt !important;
  }
}

form .input-group {
  position: relative !important;
  width: 100% !important;
  margin: 30px 0;

  .form-control {
    position: relative !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    outline: none !important;
    width: 100% !important;
    color: $primary-txt;
    //font-size: 14px!important;
    font-size: calc(12px + (15 - 12) * ((100vw - 320px) / (2800 - 320)));
    padding: 8px 0 !important;
    box-sizing: border-box !important;
    border-radius: 0 !important;
    border-bottom: 2px solid;
    border-bottom-color: #c4fff92e;
    background: transparent !important;
    background-color: transparent !important;
    z-index: 2;

    &::placeholder {
      color: transparent !important;
    }

    &:focus {
      border-bottom-color: $primary;
      caret-color: $primary !important;
      box-shadow: none !important;

      &::placeholder {
        opacity: -1 !important;
      }
    }
  }

  span {
    display: inline-block !important;
    position: absolute !important;
    //font-size: 14px!important;
    font-size: calc(12px + (15 - 12) * ((100vw - 320px) / (2800 - 320)));
    z-index: 1 !important;
    transition: 0.5s !important;
  }
}

.input-suffix {
  position: absolute;
  inset-inline-end: 0;
  top: 21%;
  display: none;
}
.input-counter{
  color: #46AAA0;
  margin-top: 3.5em;
  font-size: 12px;    
  margin-inline-start: 95%;
}
form .input-group .form-control+span {
  bottom: 16% !important;
}

form .input-group .form-control+span.show-length-input {
  bottom: 51% !important;
}

form .input-group .form-control+span {
  margin-bottom: 2px;
}

form .input-group .form-control .has-icon {
  inset-inline-start: 30px !important;
}

form .input-group .form-control:focus + span {
  color: $primary;
  bottom: 80% !important;
  //@extend .link;
}

form .input-group .form-control:focus+span.show-length-input {
  bottom: 90% !important;
}

form .input-group .form-control:focus+div.input-suffix {
  display: block;
}

.padding-bottom-20 {
  padding-bottom: 20;
}

.error-color {
  color: #f48585 !important;
}

.focus-color {
  color: #73e4cc;
}

.default-color {
  color: #c4fff9;
}

form .input-group .form-control:not(:placeholder-shown) ~ .input-suffix{
  display: block;
}

form .input-group .form-control:not(:placeholder-shown)~.input-suffix {
  display: block;
}

.default-input-focus-padding-inline {
  //padding-inline-start: 0.8em;
  @media (max-width: 600px) {
    padding-inline-start: 1.5em;
  }
}

[dir="rtl"] .default-input-focus-padding-inline {
  @media (min-width: 950px) and (max-width: 1100px) {
    padding-inline-start: 1.2em;
  }
}

[dir="ltr"]form .input-group .form-control:focus + span,
input:-webkit-autofill + span,
input:-webkit-autofill:hover + span,
input:-webkit-autofill:focus + span,
input:-webkit-autofill:active + span {
  left: 0 !important;
  font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
  text-transform: lowercase;
}

[dir="rtl"]form .input-group .form-control:focus + span,
input:-webkit-autofill + span,
input:-webkit-autofill:hover + span,
input:-webkit-autofill:focus + span,
input:-webkit-autofill:active + span {
  right: 0 !important;
  font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
  text-transform: lowercase;
}

.input-group-prepend {
  position: absolute !important;
  top: 12%;

  .input-group-text {
    background-color: none !important;
    border-color: none !important;
    border: none !important;
    background: none !important;
    color: $primary-txt ;
    padding: 0 !important;
  }
}

.input-group-prepend {
  inset-inline-start: 0 !important;
}

form .input-group .form-control:focus ~ .input-group-prepend,
input:-webkit-autofill ~ .input-group-prepend,
input:-webkit-autofill:hover ~ .input-group-prepend,
input:-webkit-autofill:focus ~ .input-group-prepend,
input:-webkit-autofill:active ~ .input-group-prepend {
  display: none;
}

form .input-group .form-control:not(:placeholder-shown) ~ .input-group-prepend {
  display: none;
}

form .input-group .form-control:not(:placeholder-shown) ~ span {
  color: $primary;
  bottom: 80% !important;
  //font-size: 12px !important;
  font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
}

form .input-group .form-control:not(:placeholder-shown)~span.show-length-input {
  bottom: 90% !important;
}

form .input-group .form-control:not(:placeholder-shown)~span {
  inset-inline-start: 0 !important;
  text-transform: lowercase;
}

[dir="rtl"] form .input-group span {
  font-weight: 400;
}