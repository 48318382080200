.empty-manual-meters-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -40px;

    .empty-manual-meters-icon {
        width: 25%;
        height: 25%
    }

    .empty-manual-meters-title {
        color: #C4FFF9;
        text-transform: uppercase;
        margin-top: 10px;
    }

    .empty-manual-meters-subtitle {
        margin-top: 5px;
        color: #46AAA0;
        max-width:450px;
        flex-wrap: wrap;
        text-align: center;
    }

}