.no-meters-screen-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -50px;

    .no-meters-icon {
        svg {
            width: 340px;
            height: 280px;
        }
    }
    .no-meters-title {
        color: #C4FFF9;
        text-transform: uppercase;
        margin-top: 10px;
    }

    .no-meters-subtitle {
        margin-top: 5px;
        color: #46AAA0;
        max-width:400px;
        flex-wrap: wrap;
        text-align: center;
    }
   
}