.DayInfo_Container,.Modal_View_Day_Info {

  &.DayInfo_Container{
  background: #03403af2 0% 0% no-repeat padding-box;
  // border-radius: 5px;
  padding-top: 20px;
  width: 17rem;
  padding-inline: 10px;
  opacity: 1;
  position: absolute;
  top: 11rem;
  inset-inline-end: -3px;
  // overflow-y: auto;
  bottom: 0;
  z-index: 100;}

  .Loading-height {
    height: 100% !important;
  }
  .toggler-button-wrapper {
    position: absolute;
    inset-inline-end: 100%;
    top: 20%;
  }
  .report-paragraph {
    padding-inline: 15px;
    padding-top: 10px;
    color: #c4fff9;
    text-align: start;
    font-size: 12px;
    @extend .regular-font;
    .openai-disclaimer {
      margin-top: 20px;
      color: #73e4cc;
      display: flex;
      .disclaimer-icon {
        margin-inline-end: 5px;
        padding-inline-start: 1px;
      }
    }
  }

  .null-info {
    margin: 10px 0 10px 5px;
    .null-info-span {
      color: white;
    }
  }

  .average-container {
    margin-inline: 20px;
    .average-reading-wrapper {
      display: flex;
      flex-direction: column;
      // align-items: flex-start;
      margin-bottom: 10px;
      @extend .regular-font;
    }
    .DayInfo_Values {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 15px;

      .Value_Reading {
        margin-inline-end: 20px;
        margin-block: 0 !important;
      }
    }

    .average-title,
    .average-summary {
      text-align: center;
      color: #46aaa0;
      margin-block: 0;
      @extend .regular-font;
    }
    .average-title {
      margin-bottom: 10px;
      text-align: start;
    }

    .average-scale-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      .scale-range {
        border: 1px solid #c4fff973;
        border-radius: 2px;
        width: 80%;
        margin-inline: 5px;
        height: 15px;
        position: relative;

        .scale-marker {
          position: absolute;
          background-color: #ecfffd;
          width: 2px;
          height: 18px;
          bottom: 0;

          &:not(.min-marker):not(.max-marker)::before {
            content: "";
            position: absolute;
            height: 8px;
            width: 8px;
            border-radius: 50%;
            background-color: #ecfffd;
            top: -5px;
            transform: translateX(-30%);
          }
          &.min-marker,
          &.max-marker {
            height: 14px;
          }
          &.min-marker::after {
            content: "min";
            position: absolute;
            top: 20px;
            transform: translateX(-30%);
          }
          &.max-marker::after {
            content: "max";
            position: absolute;
            top: 20px;
            transform: translateX(-30%);
          }
        }
      }
    }

    .Value_Difference--dec,
    .Value_Difference--inc {
      margin-block: 0 !important;
    }
  }

  .summary-container {
    position: relative;
    display: flex;
    flex-direction: row;

    .title {
      font-weight: 500 !important;
      //font-size: 0.9em;
      margin-left: 5px;
      font-size: calc(12px + (12 - 12) * ((100vw - 320px) / (2800 - 320)));
      margin-bottom: 8px;
      @extend .regular-font;
    }

    .expand-modal-icon{
      background-color: transparent;
      border: none;
      outline: none;
      margin-inline-start: auto;
      svg{
        width: 20px;
        height: 20px;
      }
    }
    .subtitle {
      .cloudiness-title {
        margin-inline-start: 5px;
      }

      .cloudiness-reading {
        color: #c4fff9;
      }
    }

    .count-wrapper {
      text-align: center;
      margin-top: 10px;

      .count {
        font-size: 1.3em;
        color: #c4fff9;
      }
    }

    .minimize-button {
      position: absolute;
      inset-inline-end: 5px;

      svg {
        width: 1.7em;
        height: 1.7em;
      }
    }
  }

  .body-wrapper {
    height: 85%;
    overflow-y: scroll;
    overflow-x: clip;
  }
  .line-seperator {
    width: 80%;
    border-bottom: 1px solid #46aaa080;
    opacity: 0.5;
    margin-inline: auto;
    margin-bottom: 10px;
  }

  .DayInfoValues_Container {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-inline: 20px;
    // height: 72%;
    // overflow: scroll;
    padding-block-start: 10px;
    margin-top: 20px;

    .DayInfoValues_Wrapper {
      display: flex;
      flex: 1;
      justify-content: space-around;
      width: inherit;
    }
  }

  .DayInfo_Values {
    text-align: center;
    @extend .regular-font;

    .Value_Reading {
      color: $primary-txt;
      font-weight: 500;
      font-size: 1.3rem;
    }

    .Value_Title {
      color: #46aaa0;
      font-size: 0.7rem;
      margin-top: -1rem;
      margin-bottom: 0;
    }

    .Value_Difference--dec {
      color: #ff5b33;
      font-size: 0.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      @extend .semibold-font;

      .minus {
        width: 7px;
        height: 2px;
        background: #ff5b33;
        margin-inline-end: 2px;
      }

      &::before {
        content: "";
        display: inline-block;
        $border: 7px;
        border-left: $border solid transparent;
        border-right: $border solid transparent;
        border-top: 8px solid #ff5b33;
        margin-inline-end: 0.4rem;
      }
    }

    .Value_Difference--inc {
      color: #7bc792;
      font-size: 0.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      @extend .semibold-font;

      .plus {
        font-size: 0.9rem;
        color: #7bc792;
        margin-inline-end: 2px;
      }

      &::before {
        content: "";
        display: inline-block;
        $border: 7px;
        border-left: $border solid transparent;
        border-right: $border solid transparent;
        border-bottom: 8px solid #7bc792;
        margin-inline-end: 0.4rem;
      }
    }

    .Value_Difference--zero {
      color: #7bc792;
      font-size: 0.8rem;
      // margin-top: -1.4rem;
      margin-block: 0 !important;
      .segment {
        display: inline-block;
        width: 5px;
        height: 1px;
        background: #7bc792;
      }
    }
  }
}

.Modal_View_Day_Info{
  .closeButtonContainer{
    display: none !important
  }
  .modalBackground .modalContainer {
    padding-block: 15px !important;
    padding-inline: 20px !important;
    height: 90vh !important;
}
.satellite-weather-forecast-list.modal-weather-cards-list
{
  position: relative !important;
  margin-inline: 0;
  gap: 5px;
  flex-wrap: wrap;

  .weather-card-wrapper {
  height: inherit;
  flex-direction: column;
  padding-block: 5px;
  padding-inline: 10px;
  flex: 0 0 19%;
  min-width: 19%;
  margin-inline-end: 0;
  @extend .semibold-font;

  
  @media (max-width: 1024px) {
    flex: 0 0 22%;
    min-width: 22%;
  }
  @media (max-width: 600px) {
    flex: 0 0 30%;
    min-width: 30%;
  }

  .weather-icon {
    margin-inline-start: 0 !important;
  }
  .weather-card-reading {
    margin-inline-start: 0 !important; 
    padding-inline-end: 0 !important;
  }
  .sensor-type{
    text-align: center;
  }
}}
}
[dir="rtl"] {
  .DayInfo_Container,.Modal_View_Day_Info  {
    .summary-container {
      .title {
        @extend .title-ar;
        font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (2800 - 320)));
      }

      .subtitle .cloudiness {
        &-title,
        &-reading {
          font-size: calc(13px + (12 - 12) * ((100vw - 320px) / (2800 - 320)));
        }
      }
    }

    .DayInfoValues_Container
      .DayInfoValues_Wrapper
      .DayInfo_Values
      .Value_Title {
      font-size: calc(13px + (12 - 12) * ((100vw - 320px) / (2800 - 320)));
    }

    .average-container .average-scale-wrapper .scale-range {
      .scale-marker:not(.max-marker):not(.min-marker)::before {
        content: "";
        position: absolute;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background-color: #ecfffd;
        top: -5px;
        transform: translateX(35%);
      }
      .scale-marker.min-marker::after {
        content: "أدنى";
        transform: translateX(30%);
      }
      .scale-marker.max-marker::after {
        content: "أقصى";
        transform: translateX(30%);
      }
    }
    .expand-modal-icon{
      transform: rotate(90deg)
    }
  }
}
