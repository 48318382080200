

.Camera-Container
{
  height:100%;
  width: 100%;
  display: flex;
  flex: 1;
 position: relative;
  flex-direction: column;
  // background-color: blue;
  // background-color: blue;
}

.Camera-Feed
{
  display: flex;
  flex:6
}

.Camera-Options
{
  display: flex;
  flex:0.2;
  flex-direction: row;
  width:100%;
  height: 100%;
  background-color: $slider_bg;
  align-items: center;
  justify-content: space-between;
}

[dir=ltr].Analog-Container
{
  position: absolute;
  right:0;
  left: 1;
  top: 1;
  bottom: 4.2em;
  // position: relative;

  // background-color:yellow;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

  }

  [dir=rtl].Analog-Container
{
  position: absolute;
  right:1;
  left: 0;
  top: 1;
  bottom: 4.2em;
  // position: relative;

  // background-color:yellow;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

  }

.Controller-Image{
  &:focus{
    outline: none !important;
  }
  width: 120px;
}

 [dir=ltr] .Controller-Stick{
  &:focus{
    outline: none !important;
  }
  position: absolute;
  top: 1.5rem;
  bottom: 0;
  left: 1.7rem;
  right: 0;
}

[dir=rtl] .Controller-Stick{
  &:focus{
    outline: none !important;
  }
  position: absolute;
  top: 1.5rem;
  bottom: 0;
  left: 0;
  right: 1.5rem;
}

[dir=ltr] .Toggle-Container{
  position: absolute;
  right:0.5em;
  left: 1;
  top:  1em;
  bottom: 0;
}

[dir=rtl] .Toggle-Container{
  position: absolute;
  right:1;
  left: 0.5em;
  top:  1em;
  bottom: 0;
}

.Camera-Info{
  position: absolute;
  right:0;
  left: 1em;
  top:  0;
  bottom: 0;
}
.Option-List-Container{

display: flex;
flex: 0.2;
// padding-top: 3%;
margin-top: 1% !important;
padding: unset;
margin-inline-start: 1%;
}

.Option-List{
  display: flex;
  flex-direction: row;
  padding-left: 2%;
  justify-content: space-evenly;
  align-self: flex-start;

 
&.Item
{
  display: flex;
  margin-inline-start: 1%;

  font-size: 11px;
  flex-direction: column;
  align-items: center;
  justify-content: center;



  &:hover{
    background-color:$primary ;
    cursor: pointer;
    color:$sidebar-dark;
  
  }
 

//  .Camera-Stream{
//    display: flex;
//    flex-wrap: wrap;

//   background-color: #022F2A;
//  }

}
} 

.Camera-List-View{
  display: flex;
  flex:1;
  flex-direction: column;
  width: 25%;
 margin-top: 2%;
  height: 80%;
  background-color: $empty-state-graph;
  border:2px solid $border !important
}

.Camera-List-Content{
  color:$primary-txt;
  font-size: .7em;
  margin-top: 2%;
  padding-inline-start: 2%;
}

.Camera-List-Chanels
{
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

#canvas{
  width:100%;
  height:70vh ;
  // background-color: white;
}
// #canvas{
//   width:1104px;
//   height:483px;
//   // background-color: white;
// }
.Video-Options{
  padding-inline-end: 2em;
}


[dir=ltr] .Zoom-Level{
  position: absolute;
  right:1em;
  left:1;
  top:  5em;
  bottom: 0;
  
}

[dir=rtl] .Zoom-Level{
  position: absolute;
  right:1;
  left:1em;
  top:  5em;
  bottom: 0;
  
}

.no-canvas{
  width: 100%;
  height: 70vh;
}

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

.image-list{
  width: 20px;
  height: 20px;
}



 @media (min-width:310px) and (max-width:480px)
  {
    .Camera-List-Content{
      p{
        font-size: 7px;
      }
    }
    .image-list{
    svg{
      width: 16px;
      height: 23px;
    }
  }
  .Camera-List-Chanels{
    svg{
      width: 30px;
      height: 30px;
    }
  }
  .Video-Options{

    svg{
      width:50px; //or any css units
      height:50px; //or any css units
    }
  
  }
  .Option-List{
    display: flex;
    flex-direction: row;
   width: 100%;
    justify-content: space-evenly;
    align-self: flex-start;
  
   
  &.Item
  {
    display: flex;
    margin-inline-start: 1%;
  
    font-size: 7px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  //  .Camera-Stream{
  //    display: flex;
  //    flex-wrap: wrap;
  
  //   background-color: #022F2A;
  //  }
  
  }
  } 
  .c-switch {
    display: inline-block !important;
    width: 29px !important;
    height: 26px !important;
}
}


@media (min-width:481px) and (max-width:768px) {
  .image-list{

  svg{
    width:20px; //or any css units
    height:20px; //or any css units
  }

}
.Camera-List-Chanels{
  svg{
    width: 30px;
    height: 30px;
  }
}
.Video-Options{

  svg{
    width:50px; //or any css units
    height:50px; //or any css units
  }

}
.Option-List{
  display: flex;
  flex-direction: row;
 width: 100%;
  justify-content: space-evenly;
  align-self: flex-start;

 
&.Item
{
  display: flex;
  margin-inline-start: 1%;

  font-size: 9px;
  flex-direction: column;
  align-items: center;
  justify-content: center;





}
} 
.Camera-List-Content{
  p{
    font-size: 6.5px;
  }
}
.c-switch {
  display: inline-block;
  width: 29px;
  height: 26px;
}
}

@media (min-width:769px) and (max-width:1024px) {
  .Camera-List-Content{
    p{
      font-size: 7px;
    }
  }
  .image-list{

  svg{
    width:10px; //or any css units
    height:10px; //or any css units
  }
}
.Camera-List-Chanels{
  svg{
    width: 30px;
    height: 30px;
  }
}
.Video-Options{

  svg{
    width:50px; //or any css units
    height:50px; //or any css units
  }

}
.Option-List{
  display: flex;
  flex-direction: row;
 width: 100%;
  justify-content: space-evenly;
  align-self: flex-start;

 
&.Item
{
  display: flex;
  margin-inline-start: 1%;

  font-size: 7px;
  flex-direction: column;
  align-items: center;
  justify-content: center;


}
} 
.c-switch {
  display: inline-block !important;
  width: 29px !important;
  height: 26px!important;
}
}
  

@media (min-width:1025px) and (max-width:1200px) {
  .image-list{

  svg{
    width:20px; //or any css units
    height:20px; //or any css units
  }

}
.Camera-List-Chanels{
  svg{
    width: 30px;
    height: 30px;
  }
}
.Video-Options{

  svg{
    width:50px; //or any css units
    height:50px; //or any css units
  }

}
.Option-List{
  display: flex;
  flex-direction: row;
 width: 100%;
  justify-content: space-evenly;
  align-self: flex-start;

 
&.Item
{
  display: flex;
  margin-inline-start: 1%;

  font-size: 9px;
  flex-direction: column;
  align-items: center;
  justify-content: center;





}
} 
.Camera-List-Content{
  p{
    font-size: 9px;
  }
}
.c-switch {
  display: inline-block;
  width: 29px;
  height: 26px;
}
}


@media (min-width:1201px)
{
  .image-list{

  svg{
    width:20px; //or any css units
    height:20px; //or any css units
  }

}
.Camera-List-Chanels{
  svg{
    width: 30px;
    height: 30px;
  }
}
.Video-Options{

  svg{
    width:50px; //or any css units
    height:50px; //or any css units
  }

}
.Option-List{
  display: flex;
  flex-direction: row;
 width: 100%;
  justify-content: space-evenly;
  align-self: flex-start;

 
&.Item
{
  display: flex;
  margin-inline-start: 1%;

  font-size: 9px;
  flex-direction: column;
  align-items: center;
  justify-content: center;





}
} 
.Camera-List-Content{
  p{
    font-size: 9px;
  }
}
.c-switch {
  display: inline-block;
  width: 29px;
  height: 26px;
}
}