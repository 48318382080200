.Dashboard_Container {
  position: relative;
  .map-loader {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #02312ca6 0% 0% no-repeat padding-box;
    z-index: 1000;

    .Loading {
      height: 100% !important;
    }

    .loading-text {
      margin-inline-start: 8px;
      font-size: 1em;
      color: #73e4cc;
    }
  }
  .filter-buttons-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 10px;
    position: absolute;
    right: 400px;
    top: 8px;
    font-family: "nunito_semibold";
    [class*="Dropdown_dropdownToggle"] {
      font-size: 13px !important;
      background-color: #03403a;
      // box-shadow: -3px -3px 6px #045850, 3px 4px 8px #022b27 !important;
      color: #73e4cc !important;
      height: 2.85em;
      width: 12rem;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
    .dropdown-menu {
      width: 12rem;
    }
    [class*="Dropdown_dropdownToggle"]:hover {
      background: #73e4cc 0% 0% no-repeat padding-box;
      border: 2px solid #c4fff9 !important;
      border-radius: 5px !important;
      color: #0a4a45 !important;
    }
    [class*="Dropdown_dropdownItem"] {
      text-transform: none;
    }
    [class*="Dropdown_dropdownItem"]:hover {
      text-transform: none;
      background-color: #39958d;
    }

    [class*="Dropdown_selected"] {
      text-transform: none !important;
    }

    [class*="Dropdown_arrow"] svg {
      display: none;
    }
    .help-button-wrapper {
      margin-inline-start: 5px;
    }

    .index-selector:not(.selected):hover {
      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: 100%;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        border-bottom: 7px solid #003a35cc;
        inset-inline-start: 30%;
      }

      .index-tooltip {
        background-color: #003a35cc;
        border-radius: 3px;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 115%;
        height: 30px;
        min-width: 100px;
        white-space: nowrap;
        padding-inline: 5px;
        inset-inline-start: 10% !important;
        color: #73e4cc;
      }
    }

    .drop-down-selector-container {
      width: 7.9em;
      height: 3em !important;
      border-radius: 5px;
      background-color: #03403a;
      border: none;
      outline: none !important;
      color: #73e4cc;
      box-shadow: 3px 3px 6px #001a17a2 !important;
    }

    .drop-down-selector-container:hover {
      background-color: #73e4cc;
      border: 2px solid #c4fff9;
      .current-choice {
        color: #165f59 !important;
      }

      .current-choice {
        &:hover::before {
          content: url("../../assets/icons/svg/sidebar/active/vegetationIndexactive.svg");
          padding-top: 5px;
          padding-inline-end: 5px;
        }
      }
    }

    .index-selector {
      margin-inline-start: 5px;

      .triangle {
        top: 100%;
        left: 40%;
      }

      .choices-list {
        top: 120%;
        inset-inline-start: -112% !important;
        width: 15em !important;
        max-height: 250px;
        overflow-y: auto;
        z-index: 1000;
      }

      .drop-down-selector-container {
        min-width: 7em !important;
        padding-inline: 5px !important;
        width: inherit;

        .current-choice {
          &::before {
            content: url("../../assets/icons/svg/sidebar/inactive/vegetationIndexInactive.svg");
            padding-top: 5px;
            padding-inline-end: 5px;
          }
        }
      }
    }

    .index-selector.ele {
      .current-choice {
        &::before {
          content: url("../../assets/icons/svg/VegetationIndex/elevDef.svg");
          padding-top: 5px;
          padding-inline-end: 5px;
        }
      }
    }

    .index-selector.selected {
      .drop-down-selector-container {
        background-color: #73e4cc;
        border: 2px solid #c4fff9;
        .current-choice {
          color: #165f59 !important;
        }

        .current-choice::before {
          content: url("../../assets/icons/svg/sidebar/active/vegetationIndexactive.svg");
          padding-top: 5px;
          padding-inline-end: 5px;
        }
      }
    }

    .ele-selected,
    .ele:hover {
      .current-choice::before {
        content: url("../../assets/icons/svg/VegetationIndex/elevHov.svg") !important;
        padding-top: 5px;
        padding-inline-end: 5px;
      }
    }
  }
  .dashboard-second-row {
    border-radius: 10px;
    margin-left: -8px;
    margin-right: -10.5px;
    background-color: #02312c;
  }
  // @media (max-width: 768px) {
  //   .dashboard-second-row {
  //     margin-top: 70px;
  //   }
  // }
  .dashboard-intial-row {
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-bottom: -10px !important;
    background: $main_layer 0% 0% no-repeat padding-box !important;
  }
  .dashboard-small-row {
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-bottom: -12px !important;
  }
  @media (max-width: 600px) {
    .dashboard-intial-row {
      padding-left: 20px !important;
      padding-right: 20px !important;
      margin-bottom: -10px !important;
      background: $main_layer 0% 0% no-repeat padding-box !important;
    }
    .dashboard-small-row {
      padding-left: 10px !important;
      padding-right: 10px !important;
      margin-bottom: 1px !important;
    }
  }

  .section-margin2 {
    margin-bottom: 0.3rem !important;
    margin-top: 0 !important;
  }
  .section-margin3 {
    margin-bottom: -0.2rem !important;
    margin-top: 0 !important;
  }
  .dashboard-soil-water {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    padding-inline: 0px;
    width: 100%;
    .card {
      display: flex;
      flex: 1 40%;
      @media (max-width: 1024px) {
        flex: 1 100%;
      }
    }
    .heatmap-container {
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;
      width: 100%;
    }
  }
  .sensor-card {
    .card-body:has(.empty-state-graph) {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      align-self: center;
      width: 64%;
    }
  }
  .main-container {
    width: 100%;
  }
  .graphTabs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1.5rem;
    width: 100%;
    padding: 0 30px;
    position: relative;

    .title {
      color: #c4fff9;
      text-align: left;
      letter-spacing: 0px;
      text-transform: none; /* Ensures text is displayed as written */
      margin-right: 1rem;
      margin-top: 0.7rem;
    }
    .tabs {
      display: flex;
      justify-content: flex-start;
      background-color: #004e47;
      border-radius: 5px;
      padding: 0.2rem;
      width: auto;
      font-size: 13px;
    }

    .tab {
      color: #c4fff9;
      padding: 0.5rem 1rem;
      border-radius: 5px;
      text-align: center;
      font-weight: bold;
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s;
    }

    .tab:not(.active) {
      background-color: transparent;
      color: #90cfc5; /* Inactive text color */
    }

    .tab.active {
      background-color: #00675b; /* Active tab background color */
      color: #c4fff9; /* Active text color */
      border: 1px solid #c4fff9; /* Optional active border */
      text-transform: uppercase;
    }
    .tab:hover:not(.active) {
      color: #c4fff9; /* Hover text color for inactive tabs */
    }
    .calendar-button {
      background-color: #004e47;
      justify-content: right;
      box-shadow: 0 4px 6px rgba(96, 96, 96, 0.1);
      color: #c4fff9;
      border: none;
      border-radius: 5px;
      padding: 0.5rem 1rem;
      font-weight: bold;
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s;
      gap: 0.5rem; /* Space between the icon and the text */
      display: flex;
      align-items: right;
    }

    //   .calendar-button:hover {
    //     background-color: #00675B;
    //   }
    .calendar-icon {
      width: 1rem; /* Adjust the size as needed */
      height: 1rem;
    }
    .calendar-position {
      position: absolute;
      top: 2rem;
      right: 20rem;
      .Calendar-Wrapper {
        position: absolute;
        padding-top: 20px;
        z-index: 5;

        .date-picker-container {
          width: 15rem;
          background-color: $light-container-background !important;
        }

        .triangle {
          position: absolute;
          width: 0;
          height: 0;
          top: 0.5em;
          left: 12.5em;
          border-left: 12px solid transparent;
          border-right: 12px solid transparent;
          border-bottom: 12px solid #196a63;
          z-index: 1000 !important;
        }
      }
    }
  }
  .color-map-wrapper {
    .info-icon {
      inset-inline-start: 11px;
      bottom: 80px;
      width: 42px;
      height: 42px;
      border: none !important;
      outline: none !important;
      position: absolute;
      background-color: #03403a;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 25px;
      }

      &:hover {
        background-color: #73e4cc;

        svg {
          path {
            stroke: #03403a;
            stroke-width: 1;
          }
        }

        &::after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          top: 1.2em;
          border-bottom: 7px solid transparent;
          border-top: 7px solid transparent;
          border-right: 7px solid #003a35cc;
          inset-inline-start: 51px;
        }

        .info-tooltip {
          top: 5px;
          inset-inline-start: 57px;
          background-color: #003a35cc;
          border-radius: 3px;
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          height: 30px;
          width: 100px;
          color: #73e4cc;
        }
      }

      .tooltip-wrapper {
        position: absolute;
      }
    }

    .color-map-container {
      position: absolute;
      min-width: 8.5rem;
      background-color: #03403a;
      border-radius: 5px;
      color: #c4fff9;
      bottom: 3.5rem;
      inset-inline-start: 0.8rem;
      padding-inline-start: 1rem;
      padding-block-start: 8px;
      font-family: 'nunito_semibold';

      .color-map-title {
        text-transform: uppercase;
        padding-block: 5px;
        font-size: 0.9em;
        margin-bottom: 0;

        .subtitle {
          color: #46aaa0;
          display: block;
          margin-inline-end: 10px;
        }

        .minimize-button {
          position: absolute;
          top: 6px;
          inset-inline-end: 7px;

          svg {
            width: 1.7em;
            height: 1.7em;
          }
        }
      }

      ul {
        padding-inline-start: 0 !important;
        padding-block-start: 8px;
        padding-inline-end: 20px;
        // height: 12rem;
        overflow: scroll;

        .color-row {
          margin-block-end: 5px;
          display: flex;
          align-items: center;

          .value {
            min-width: 40px;
            margin: 0 10px;
          }

          .seperator {
            color: #46aaa0;
            margin-inline-start: 5px;
            margin-inline-end: 8px;
            font-family: nunito_extraBold;
          }

          .color {
            display: inline-block;
            width: 14px;
            height: 13px;
            border: 1px solid #03403a;
            border-radius: 3px;
            margin-inline-end: 8px;
          }
          .area {
            margin-inline-end: 8px;
          }
        }
      }
    }
  }

  .empty-data-state-container .subtitle {
    width: 100% !important;
  }
}
[dir="rtl"] .Dashboard_Container {
  .color-map-wrapper {
    .info-icon {
      &::after {
        content: "";
        border-top: 7px solid transparent;
        border-right: none !important;
        border-left: 7px solid #003a35cc;
      }
    }
  }
}
[dir="rtl"] .Dashboard_Container .graphTabs .calendar-button {
  left: unset;
  right: 45rem;
}
[dir="rtl"] .Dashboard_Container .graphTabs .calendar-position {
  left: 20rem;
  right: unset;
}
[dir="rtl"]
  .Dashboard_Container
  .graphTabs
  .calendar-position
  .Calendar-Wrapper
  .triangle {
  left: unset;
  right: 12.5rem;
}

[dir="rtl"] .Dashboard_Container {
  .dashboard-intial-row {
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-bottom: -10px !important;
    background: $main_layer 0% 0% no-repeat padding-box !important;
  }
  .dashboard-small-row {
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-bottom: -12px !important;
  }
  @media (max-width: 600px) {
    .dashboard-intial-row {
      padding-left: 20px !important;
      padding-right: 20px !important;
      margin-bottom: -10px !important;
      background: $main_layer 0% 0% no-repeat padding-box !important;
    }
    .dashboard-small-row {
      padding-left: 10px !important;
      padding-right: 10px !important;
      margin-bottom: 1px !important;
    }
  }
  .section-margin2 {
    margin-bottom: 0.3rem !important;
    margin-top: 0 !important;
  }
  .section-margin3 {
    margin-bottom: -0.2rem !important;
    margin-top: 0 !important;
  }
}
.dashboard-issues-scheduler {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  padding-inline: 5px;
  width: 100%;
  .scheduler {
    display: flex;
    flex: 1 20%;
    &__row {
      padding-inline: 1.25rem;
    }
  }
  .issues {
    display: flex;
    flex: 4 20%;
    &__row {
      margin-inline: 0.2%;
      padding-inline: 1%;
      display: flex;
      justify-content: space-between;
    }
    .issues-per-plot {
      display: flex;
      flex: 2 50%;
    }
    .number-of-issues {
      display: flex;
      flex: 1 20%;
      justify-content: center;
    }
    .number-of-issues__card-container {
      padding-inline: 0.6rem;
    }
    .number-of-issues__card-container--resolved {
      margin-top: 3.5rem;
    }
    .issues-small-cards-container {
      display: flex;
      flex-direction: column;
      background: #053f39 0% 0% no-repeat padding-box;
      border: 1px solid #46aaa0;
      border-radius: 10px;
      width: 22em;
      height: 27em;
      align-items: center;
      justify-content: center;
      align-self: baseline;
      @media (max-width: 1100px) {
        flex-direction: row;
        background: none;
        border: none;
        border-radius: 0;
        height: fit-content;
        margin-bottom: 50px;
        width: 100%;
        justify-content: space-around;
        align-items: flex-end;
      }
      .warning-danger-cards-container {
        margin-inline: 2%;
      }
      .resolved-card-container {
        margin-inline-end: 2%;
        position: relative;
      }
      .resolved-issues {
        display: flex;
        flex: 1;
        flex-direction: column;
        position: absolute;
        background-color: #0d4641b3;
        width: 12em;
        height: 145px;
        inset-inline-end: -10%;
        bottom: -3%;
        border-radius: 3px;
        padding-top: 0.6rem;
        padding-inline-start: 6.4%;
        .reading-date-container {
          margin-inline-start: 0.9em;
          margin-block-start: 0;
          justify-content: flex-start;
        }
        .resolved-issues-title {
          color: #c4fff9;
          margin-inline-start: 0.7em;
          margin-top: 0.2em;
          text-transform: uppercase;
          letter-spacing: 0px;
          font-weight: 600;
          font-size: 0.8em;
        }
        .past-date {
          background-color: transparent;
          border: none;
          border-radius: none;
        }
      }
      .card-with-icon-container {
        // width: -webkit-fill-available;
        margin-inline: 3%;
        padding-bottom: 0em;
        border-width: 2px;
        border-inline-start-width: 10px;
        margin-bottom: 1rem;
        .icon-container {
          width: 2.7em;
          height: 2.7em;
          inset-inline-start: -1.7em;
          top: 1.3em;
        }
      }
      .content-container {
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          font-family: "nunito_extraBold";
          font-size: 3.4em;
          margin-bottom: -1rem;
          margin-top: -0.3rem;
        }
      }
      .warning-danger-cards-container {
        display: flex;
        flex: 2;
        justify-content: space-evenly;
        margin-inline: 2%;
      }
      .resolved-card-container {
        display: flex;
        flex: 1;
        position: relative;
        padding-inline: 0.3rem;
      }
    }
    .cards-list {
      display: flex;
      width: 100%;
      height: 100%;
      flex: 1 1;
      flex-direction: row;
      padding-bottom: 2em;
      justify-content: space-around;
      @media (max-width: 500px) {
        flex-wrap: wrap;
        gap: 50px;
      }
      .card-with-icon-container {
        width: 22em;
        padding-bottom: 1em;
        height: 27em;
        background: #053f39 0% 0% no-repeat padding-box;
        border: 1px solid #46aaa0;
        border-radius: 10px;
        .icon-container {
          inset-inline-start: 9em;
        }
        .content-container {
          justify-content: center;
          display: flex;
          flex-wrap: wrap;
          border-radius: 5px;
          margin-top: 2.75em;
          overflow: scroll;
          height: 89%;
          align-content: baseline;
          .content-container-item {
            margin-bottom: 1rem;
            width: 90%;
            display: flex;
            padding-inline: 5%;
          }
          .name-section {
            display: flex;
            flex: 4 20%;
            color: #c4fff9;
          }
          .value-section {
            display: flex;
            flex: 1 20%;
          }
          .danger-issues {
            width: 48px;
            height: 22px;
            background: #a83737 0% 0% no-repeat padding-box;
            border: 1px solid #ff8787;
            border-radius: 15px;
            opacity: 1;
            color: #ffbcbc;
            text-align: center;
          }
          .warning-issues {
            width: 48px;
            height: 22px;
            opacity: 1;
            text-align: center;
            margin-inline-start: 7px;
            background: #fdc473 0% 0% no-repeat padding-box;
            border: 1px solid #a57531;
            border-radius: 15px;
            opacity: 1;
            color: #a57531;
          }
        }
        .issues-card-footer {
          background: #0d5049 0% 0% no-repeat padding-box;
          border-radius: 10px;
          border-start-start-radius: 0;
          border-start-end-radius: 0;
          opacity: 1;
          width: 100%;
          letter-spacing: 0.74px;
          color: #c4fff9;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: -6px;
        }
      }
    }
    .reading-date {
      margin-inline: auto;
    }
  }
}
.dashboard-issues-scheduler > div {
  margin-bottom: 0.5rem;
  width: 100%;
  border: 1px solid;
  border-radius: 0.25rem;
  background-color: #02312d;
  border-color: #02312d;
}
[dir="rtl"] .dashboard-issues-scheduler {
  .issues {
    .issues-small-cards-container {
      .content-container {
        .title {
          font-family: "tajawal_extraBold";
          font-size: 3.5em;
        }
      }
    }
  }
}
[dir="rtl"] {
  .dashboard-small-row {
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-bottom: -12px !important;
  }
  .dashboard-initial-row {
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-bottom: -12px !important;
  }
  .filter-buttons-container {
    left: 388px;
    right: unset;
  }
}

@media (max-width: 878px) {
  .dashboard-issues-scheduler {
    flex-direction: column !important;
    .scheduler {
      flex: 1 20%;
    }
    .issues {
      flex: 1 20%;
    }
  }
}
@media (max-width: 600px) {
  .dashboard-issues-scheduler {
    .issues {
      .issues-per-plot,
      .issues-per-sensor {
        width: 100%;
      }
      .number-of-issues {
        width: 100%;
      }
      .issues-small-cards-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 1rem;
        .warning-danger-cards-container {
          display: flex;
          flex: 1;
          justify-content: space-evenly;
          margin-inline: 2%;
        }
        .number-of-issues__card-container--resolved {
          .resolved-card-container {
            display: flex;
            flex: 1;
            margin-inline-end: 2%;
            position: relative;
            margin-inline-start: 1rem;
            .resolved-issues {
              width: 107%;
              height: 131px;
              right: -1%;
            }
          }
        }
      }
      &__row {
        margin-inline: 0.1%;
      }
    }
  }
}
