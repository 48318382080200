

.surveillance-container{
    display: flex;
    flex:1;
    flex-direction: column;
    height:100%;
    margin: 0%;
    padding: 0%;
    
}

.CameraFeeds{
    display: flex;
    flex:3.5;
    border: 2px solid $primary !important;
}

.Channels-view{
    height: 25vh;
    // display: flex;
    // flex:1
}

