.empty-state-graph-conrainer{
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        text-transform: none !important
    }

    .empty-state-title {
        @extend .title;
        margin-top: 17px;
        margin-bottom: 0;
    }
}
