.Timer-Container {
    display: flex;
    flex-direction: column;
    position: relative;

    .Timer-Content {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        position: absolute;
        background-color: #196A63;
        color: #73E4CC;
        font-size: 1rem;
        width: 10rem;
        height: 8rem;
        border-radius: 5px;
        top: 50px;
        z-index: 10;

        &::before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: -0.7em;
            left: 3em;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-bottom: 12px solid #196A63;
            z-index: 1000 !important;
        }
    }

    .calendar-button {
        height: 3em;
        color: #73E4CC;
        margin: 0;

        span {
            padding-inline-end: 3.5em;
        }
    }

    .calendar-button::before {
        content: url('../../assets/icons/svg/sidebar/inactive/scheduler.svg');
        left: 0.5em;
        top: 0.75em;
    }


    .Wrapper {
        display: flex;
        position: relative;
        flex-direction: column;

        .Increment {
            width: 0;
            height: 0;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-bottom: 12px solid #73E4CC;
            align-self: center;
            margin-bottom: -5px;
        }

        .Decrement {
            width: 0;
            height: 0;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-top: 12px solid #73E4CC;
            align-self: center;
            margin-top: 9px;
        }
    }

    .Time-Content {
        background: #003A352C 0% 0% no-repeat padding-box;
        border-radius: 3px;
        opacity: 1;
        justify-content: center;
        align-items: center;
        width: 4.5rem;
        height: 4rem;
        text-align: center;
        padding-top: 10px;
        margin-top: 14px;

        form {
            padding-inline: 5px;
            .input-group {
                margin: 5px 0;
            }
        }
    }

    .Minutes-Wrapper {
        background: #003A352C 0% 0% no-repeat padding-box;
        border-radius: 3px;
        opacity: 1;
        width: 4.5rem;
        height: 4rem;
        text-align: center;
        padding-top: 10px;
    }
}