// .Dashboard_Container{
//     .Farm-Button {
//         &:hover {
//             border-radius: 8px;
//             cursor: pointer;
//             background-color: #73E4CC;
//             p{
//                 color:#03403A
//             }
//         }
//         height: 40px;
//         background-color: #03403A;
//         flex: 1 1;
//         position: absolute;
//         top: 10em;        
//         // bottom: 1em;
//         left: 0.4rem;
//         right: 1;
//         transition: all 0.2s ease-out;
//         border-radius: 8px;
//     }
//     .Farm-Content{
//       &::before{
//           content: '';
//           position: absolute;
//           width: 10px;
//           height: 10px;
//           background-color:#03403A ;
//           top: 1em;
//           transform: translateX(-360%) translateY(-1px) rotate(40deg);
//          border-bottom: 2px solid #03403A;
//          border-left: 2px solid #03403A;
    
//         bottom: 0;
//         left: 2rem;    
    
//         right: 1;
//       }
//         p{
//             margin-top: 15px;;
//         }
//         background-color:#03403A;
//         border-radius:7px;
//         flex: 1;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         position: absolute;
//         top: 10em;
//         height: 30px;
//         width: 100px;
//         // bottom: 1em;
    
//         left: 4rem;    
//         right: 1;
//     }
// }
.fade{
    background: $main_layer 0% 0% no-repeat padding-box;
    border: 0 !important;
    padding-top: 2rem;
}
.dashboard-card-label{
    align-self: flex-start;
    letter-spacing: 0px;
    // color: $primary-txt;
    // text-transform: uppercase;
    opacity: 1;
    // font-weight: 600;
    // font-size: 1vw;
    @extend .title;
}
.img-top{
   display: inline;
}
.img-down{
    display:none
}
[dir='rtl'] .g2-tooltip-value{
    float: left !important;
    margin-right: 30px !important;
    margin-left: 0 !important;
}
.g2-tooltip{
    padding: 0% !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    opacity: 1 !important;
    .g2-tooltip-list{
        background: #eafffd 0% 0% no-repeat padding-box;
        //box-shadow: 0px 3px 6px #00000029;
        border-radius: 0 0 6px 6px;
        // opacity: 1;
        margin-top: 0% !important;
        margin-bottom: 0% !important;
        color: #46AAA0 !important;
        font-size:12px;
        font-family: nunito_semibold;
        .g2-tooltip-list-item{
            margin-top: 0% !important;
            //margin-bottom: 0% !important;
            margin-bottom: 0% !important;
            padding: 10px !important;
            span.g2-tooltip-name {
                margin-inline-start: 2px;
                color:#165F59;
            }
        }
        .g2-tooltip-marker{
            margin-right: 0% !important;
        }
    }
    .g2-tooltip-title{
        margin-top: 0% !important;
        margin-bottom: 0% !important;
        background: $secondary-txt 0% 0% no-repeat padding-box;
       // box-shadow: 0px -3px 6px #0000000D;
        border-radius: 6px 6px 0px 0px; 
        padding: 10px !important;
        color: $primary-txt !important;
        text-transform: uppercase;
        font-family: nunito_bold;
        font-size: 12.6px;
        letter-spacing: 0.2px;
    }
}
.valveGraphContent{
    height:95%
}